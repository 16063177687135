import axios from "axios"
import { IClassroomStudent, IClassroomStudentBody } from "../interfaces/ClassroomStudent"

class ClassroomStudentsService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(classroomId: string): Promise<IClassroomStudent[]> {
        return await axios.get(this.baseUrl + '/classroom/' + classroomId + '/students').then(response => response.data.data)
    }

    async get(classroomId: number, teacherId: number): Promise<IClassroomStudent> {
        return await axios.get(this.baseUrl + "/classroom/" + classroomId + '/student/' + teacherId).then(response => response.data.data)
    }

    async post(classroomId: number, body: IClassroomStudentBody): Promise<IClassroomStudent> {
        return await axios.post(this.baseUrl + '/classroom/' + classroomId + '/student', body).then(response => response.data.data)
    }

    async put(classroomId: number, teacherId: number, body: IClassroomStudentBody): Promise<IClassroomStudent> {
        return await axios.put(this.baseUrl + "/classroom/" + classroomId + '/student/' + teacherId, body).then(response => response.data.data)
    }

    async delete(classroomId: number, teacherId: number) {
        return await axios.delete(this.baseUrl + "/classroom/" + classroomId + '/student/' + teacherId).then(() => teacherId)
    }
}

const classroomStudentsService = new ClassroomStudentsService();

export default classroomStudentsService;