import axios from "axios"
import IFund from "../interfaces/Fund"

class FundService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IFund[]> {
        return await axios.get(this.baseUrl + "/course/funds").then(response => response.data.data)
    }

    async get(id: number): Promise<IFund> {
        return await axios.get(this.baseUrl + "/course/fund/" + id).then(response => response.data.data)
    }

    async post(body: IFund): Promise<IFund> {
        return await axios.post(this.baseUrl + '/course/fund', body).then(response => response.data.data)
    }

    async put(id: number, body: IFund): Promise<IFund> {
        return await axios.put(this.baseUrl + '/course/fund/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/course/fund/' + id).then(response => response.data.data)
    }
}

const fundService = new FundService();

export default fundService;