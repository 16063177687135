import axios from "axios"
import { ITeacherFile } from "../interfaces/TeacherFile"

class TeacherFileService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(teacherId: number): Promise<ITeacherFile[]> {
        return await axios.get<{ statusCode: number, data: ITeacherFile[] }>(this.baseUrl + '/teacher/' + teacherId + '/files').then(response => response.data.data)
    }

    async get(teacherId: number, id: number): Promise<ITeacherFile> {
        return await axios.get(this.baseUrl + '/teacher/' + teacherId + '/file/' + id).then(response => response.data.data)
    }

    async post(teacherId: number, body: { file_id: number }): Promise<ITeacherFile> {
        return await axios.post(this.baseUrl + '/teacher/' + teacherId + '/file', body).then(response => response.data.data)
    }

    async put(teacherId: number, id: number, body: { file_id: number }): Promise<ITeacherFile> {
        return await axios.put(this.baseUrl + '/teacher/' + teacherId + '/file/' + id, body).then(response => response.data.data)
    }

    async delete(teacherId: number, id: number) {
        return await axios.delete(this.baseUrl + '/teacher/' + teacherId + '/file/' + id).then(response => response.data.data)
    }
}

const teacherFileService = new TeacherFileService();

export default teacherFileService;