import { Pagination } from "react-bootstrap";
import './CustomPagination.css';

// paginating the list of objects based on the length of the shown elements and the current page's number
export function paginate(array: any[], page_size: number, page_number: number) {
    let paginated = array.slice((page_number - 1) * page_size, page_number * page_size);
    return paginated
}

// rendering all the tables' pagination (customized)
const CustomPagination = (props: {
    active: number,
    shownItems: number,
    sourceItems: any[],
    maxLength?: number,
    onPaginationChange: (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        active: number,
        shownItems: number
    ) => void
}) => {

    const { sourceItems, onPaginationChange, shownItems, active, maxLength } = props;
    const items: JSX.Element[] = []

    const renderItems = () => {
        let length = maxLength ? Math.ceil(maxLength / shownItems) + 1 : Math.ceil(sourceItems.length / shownItems) + 1
        for (let i = 1; i < length; i++) {
            items.push(
                <Pagination.Item onClick={(e) => { onPaginationChange(e, i, shownItems) }} key={i} active={active === i}>
                    {i}
                </Pagination.Item>,
            );
        }
        return items;
    }

    return <Pagination className="pagination h-100 mt-3 mb-0 mx-3 d-flex justify-content-center align-items-center">
        <Pagination.First onClick={(e) => { onPaginationChange(e, 1, shownItems) }} disabled={active === 1} />
        <Pagination.Prev onClick={(e) => { onPaginationChange(e, active - 1, shownItems); }} disabled={active === 1} />
        {
            renderItems()
        }
        <Pagination.Next onClick={(e) => { onPaginationChange(e, active + 1, shownItems); }} disabled={active === items.length} />
        <Pagination.Last onClick={(e) => { onPaginationChange(e, items.length, shownItems); }} disabled={active === items.length} />
    </Pagination>
}

export default CustomPagination;