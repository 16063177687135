import { FC } from "react";
import { IClassroomValuation, IClassroomValuationBody } from "../../../../interfaces/ClassroomValuation";
import * as Yup from 'yup';
import { ICustomFormProps } from "../../../../common/interfaces/CustomForm";
import { IModalProps } from "../../../../common/interfaces/CustomModal";
import classroomValuationsService from "../../../../services/ClassroomValuationsService";
import { IClassroomTeacher } from "../../../../interfaces/ClassroomTeacher";
import { IClassroomStudent } from "../../../../interfaces/ClassroomStudent";
import { setClassroomValuationsFields } from "../../../../config/formFields/ClassroomValuationsFields";
import CustomModal from "../../../../common/components/CustomModal/CustomModal";

interface IClassroomDetailsModalValuationProps {
    currentValuation: IClassroomValuation | undefined,
    recipient: number,
    teacherId?: number,
    studentId?: number,
    teachers: IClassroomTeacher[],
    students: IClassroomStudent[],
    show: boolean;
    close: (action?: string) => void;
    action: string;
    id: number | undefined,
    classroomId: number
}

const ClassroomDetailsModalValuation: FC<IClassroomDetailsModalValuationProps> = (props) => {

    const { id, teacherId, studentId, action, show, close, currentValuation, classroomId, students, teachers, recipient } = props;

    const mappedStudents = students.map(studente => { return { value: studente.id, label: studente.student.last_name + ' ' + (studente.student.middle_name || '') + studente.student.first_name } })
    const mappedTeachers = teachers.map(docente => { return { value: docente.id, label: docente.teacher.last_name + ' ' + (docente.teacher.middle_name || '') + docente.teacher.first_name } })

    const valuation = {
        has_teacher_id: teacherId || undefined,
        has_student_id: studentId || undefined,
        note: currentValuation ? currentValuation.note : undefined,
        vote: currentValuation ? currentValuation.vote : undefined,
        recipient: recipient
    }

    const validationSchema = Yup.object().shape({
        has_teacher_id: Yup.number().required("* Campo obbligatorio"),
        has_student_id: Yup.number().required("* Campo obbligatorio"),
        note: Yup.string().nullable(),
        vote: Yup.number().required("* Campo obbligatorio"),
        recipient: Yup.number().required("* Campo obbligatorio")
    })

    const handleSubmit = async (values: IClassroomValuationBody) => {
        if (action === "add") {
            classroomValuationsService.post(classroomId, values).then(res => close("add"))
        }
        if (action === "edit" && id) {
            classroomValuationsService.put(classroomId, id, values).then(res => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            classroomValuationsService.delete(classroomId, id).then(res => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: action === 'add' ? "Aggiungi una nuova valutazione" : "Modifica la valutazione corrente"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: recipient === 2 ? setClassroomValuationsFields([], mappedStudents) : setClassroomValuationsFields(mappedTeachers, []),
        initialValues: valuation,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default ClassroomDetailsModalValuation;