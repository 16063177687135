import React, { FC } from 'react';
import { Modal, Button } from "react-bootstrap";
import { ICustomFormProps, ICustomStepperFormProps } from '../../interfaces/CustomForm';
import { IModalProps } from '../../interfaces/CustomModal';
import { ICalendarEventStepperProps } from '../../../interfaces/CalendarEventStepper';
import CustomForm from '../CustomForm/CustomForm';
import CalendarEventStepper from '../../../components/Courses/RealCourses/RealCourseCalendar/CalendarEventStepper';
import DeleteCalendarEventModal from '../../../components/Courses/RealCourses/RealCourseCalendar/DeleteCalendarEventModal';

export interface ICustomModalProps {
    modalProps: IModalProps,
    formProps?: ICustomFormProps,
    stepperProps?: ICalendarEventStepperProps,
    stepperFormProps?: ICustomStepperFormProps,
}

const CustomModal: FC<ICustomModalProps> = (props) => {
    const { action, show, close, confirm, label, content } = props.modalProps
    const { formProps, stepperProps, stepperFormProps } = props

    return (
        <>
            {
                // modal with a stepper and eventually a form: calendar event modal
                stepperProps &&
                (
                    action !== 'delete' ?
                        <Modal show={show} size="lg" onHide={close} backdrop="static" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{label}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CalendarEventStepper {...stepperProps} {...stepperFormProps}></CalendarEventStepper>
                            </Modal.Body>
                        </Modal>
                        :
                        <DeleteCalendarEventModal {...props.modalProps} />
                )
            }
            {
                // modal with only form: all generic modals
                !stepperProps && formProps &&
                (
                    action === 'add' || action === 'edit' || action === 'refresh' || action === 'generate' ?
                        <Modal show={show} size="lg" onHide={close} backdrop="static" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{label}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CustomForm {...formProps}></CustomForm>
                            </Modal.Body>
                        </Modal>
                        :
                        (
                            action === 'delete' ?
                                <Modal show={show} onHide={close} backdrop="static" centered>
                                    <Modal.Header>
                                        <Modal.Title>Sei sicuro di voler eliminare?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Footer className="d-flex justify-content-between">
                                        <Button variant="secondary" onClick={close}>
                                            Chiudi
                                        </Button>
                                        <Button variant="primary" onClick={() => confirm()}>
                                            Conferma
                                        </Button>
                                    </Modal.Footer>
                                </Modal> : <></>
                        )
                )
            }
            {
                !stepperFormProps && !stepperProps && !formProps &&
                <Modal show={show} size="xl" onHide={close} backdrop="static" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{label}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {content}
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <Button variant="secondary" onClick={close}>
                            Chiudi
                        </Button>
                        <Button variant="primary" onClick={() => confirm()}>
                            Scarica il pdf
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}

export default CustomModal;
