import { FC, useEffect, useState } from 'react';
import { MUIDataTableMeta } from 'mui-datatables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IStudentStatus from '../../../interfaces/StudentStatus';
import studentStatusesService from '../../../services/StudentStatusesService';
import StudentStatusesModal from './StudentStatusesModal';
import StudentStatusesColumns from '../../../config/tableColumns/StudentStatusesColumns';

const StudentStatuses: FC<{}> = () => {
    const [studentStatuses, setStudentStatuses] = useState<IStudentStatus[]>([]);
    const [currentStudentStatus, setCurrentStudentStatus] = useState<IStudentStatus | undefined>(undefined)
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [currentId, setCurrentId] = useState<number | undefined>(undefined);
    const [action, setAction] = useState('');

    const getAllSources = () => studentStatusesService.getAll().then(res => setStudentStatuses(res))

    useEffect(() => {
        getAllSources()
    }, []);

    useEffect(() => {
        if (refresh === true) {
            getAllSources();
            setRefresh(false);
        }
    }, [refresh]);

    const onEditClick = (tableMeta: MUIDataTableMeta<IStudentStatus>) => {
        if (tableMeta.rowData[0]) {
            studentStatusesService.get(tableMeta.rowData[0])
                .then(
                    res => {
                        setAction('edit')
                        setCurrentId(tableMeta.rowData[0])
                        setCurrentStudentStatus(res)
                        setShowModal(true);
                    }
                )
        }
    }

    const onDeleteClick = (tableMeta: MUIDataTableMeta<IStudentStatus>) => {
        if (tableMeta.rowData[0]) {
            setAction('delete')
            setCurrentId(tableMeta.rowData[0])
            setShowModal(true);
        }
    }

    return (
        <div className="container-fluid">
            <StudentStatusesModal
                studentStatus={currentStudentStatus}
                id={currentId}
                show={showModal}
                close={(action?: string) => {
                    setShowModal(false);
                    setAction('')
                    setCurrentStudentStatus(undefined);
                    setCurrentId(undefined);
                    if (typeof action === 'string')
                        setRefresh(true);
                }}
                action={action}
            />
            <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="mb-0">Stati degli studenti</h4>
                <button className='btn btn-success rounded-pill' onClick={() => { setShowModal(true); setAction('add') }}><FontAwesomeIcon icon={faPlus} className="me-1" />Crea stato</button>
            </div>
            <StudentStatusesColumns studentStatuses={studentStatuses} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
        </div>
    )
}

export default StudentStatuses;