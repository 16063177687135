import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import IQualification from '../../interfaces/Qualification';

const QualificationsColumns = (props: {
  onEditClick: (tableMeta: MUIDataTableMeta<IQualification>) => void,
  onDeleteClick: (tableMeta: MUIDataTableMeta<IQualification>) => void,
  qualifications: IQualification[]
}) => {
  const { qualifications, onEditClick, onDeleteClick } = props

  const qualificationsColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false
      }
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "code",
      label: "Codice",
    },
    {
      name: "azioni",
      label: "Azioni",
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IQualification>, updateValue: any) => (
          <>
            <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
            <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
          </>
        )
      }
    },
  ]

  return (
    <CustomTable
      title={"Elenco di tutti i titoli di studio disponibili"}
      data={qualifications}
      columns={qualificationsColumns}
    />
  )
}

export default QualificationsColumns;