import * as Yup from "yup";
import CustomModal from "../../common/components/CustomModal/CustomModal";
import { getStudentsFields } from "../../config/formFields/StudentsFields";
import { IStudentForm, IGetStudent } from "../../interfaces/Student";
import studentsService from "../../services/StudentsService";
import { FC } from "react";
import IQualification from "../../interfaces/Qualification";
import ISource from "../../interfaces/Source";
import { IModalProps } from "../../common/interfaces/CustomModal";
import { ICustomFormProps } from "../../common/interfaces/CustomForm";
import IStudentStatus from "../../interfaces/StudentStatus";
import { IPeopleAvailable } from "../../interfaces/Person";

interface IStudentsModalProps {
    data: {
        student: IGetStudent | undefined,
        qualifications: IQualification[],
        sources: ISource[],
        availablePeople: IPeopleAvailable[],
        studentStatuses: IStudentStatus[]
    };
    show: boolean;
    close: (action?: string) => void;
    action: string;
    id: number | undefined
}

const StudentsModal: FC<IStudentsModalProps> = (props) => {
    const { id, action, show, close, data } = props;

    const qualifications = data.qualifications.map(qualification => { return { value: qualification.id || 0, label: qualification.name } });
    const sources = data.sources.map(source => { return { value: source.id || 0, label: source.name } });
    const availablePeople = data.availablePeople.map(person => { return { value: person.id, label: person.last_name + ' ' + (person.middle_name || '') + person.first_name } });
    const statuses = data.studentStatuses.map(status => { return { value: status.id || 0, label: status.name } });

    const studentForForm = {
        demetra_id: data.student?.demetra_id,
        nationality: data.student?.nationality,
        qualification_id: data.student?.qualification.id,
        source_id: data.student?.source.id,
        status_id: action === 'edit' ? data.student?.status.id : data.studentStatuses.find(status => status.code === 'ACT')?.id,
        note: data.student?.note,
        gender: data.student?.gender,
        tax_code: data.student?.tax_code
    };

    const validationSchema = Yup.object().shape({
        demetra_id: Yup.number().required("* Campo obbligatorio"),
        nationality: Yup.string().required("* Campo obbligatorio"),
        qualification_id: Yup.number().required("* Campo obbligatorio"),
        source_id: Yup.number().required("* Campo obbligatorio"),
        status_id: Yup.number().required("* Campo obbligatorio"),
        note: Yup.string().nullable(),
        gender: Yup.string().nullable(),
        tax_code: Yup.string().nullable().length(16, "Il valore deve essere composto da 16 caratteri")
    })

    const handleSubmit = async (values: IStudentForm) => {
        if (action === "add") {
            studentsService.post(values).then(() => close("add"))
        }
        if (action === "edit" && id) {
            studentsService.put(id, values).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            studentsService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: data.student ? 'Modifica i dati di "' + data.student.last_name + ' ' + (data.student.middle_name || '') + data.student.first_name + '"' : "Aggiungi un nuovo studente"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: getStudentsFields(availablePeople, qualifications, sources, statuses, action),
        initialValues: studentForForm,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default StudentsModal;
