import { MUIDataTableMeta } from "mui-datatables";
import { FC, useEffect, useState } from "react";
import { IStudent, IGetStudent } from "../../interfaces/Student";
import StudentsModal from "./StudentsModal";
import StudentsColumns from "../../config/tableColumns/StudentsColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import studentsService from "../../services/StudentsService";
import qualificationsService from "../../services/QualificationsService";
import sourcesService from "../../services/SourcesService";
import IQualification from "../../interfaces/Qualification";
import ISource from "../../interfaces/Source";
import studentStatusesService from "../../services/StudentStatusesService";
import IStudentStatus from "../../interfaces/StudentStatus";
import peopleService from "../../services/PeopleService";
import { IPeopleAvailable } from "../../interfaces/Person";

const Students: FC<{}> = () => {
  const [students, setStudents] = useState<IStudent[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentStudent, setCurrentStudent] = useState<IGetStudent | undefined>(undefined);
  const [qualifications, setQualifications] = useState<IQualification[]>([]);
  const [sources, setSources] = useState<ISource[]>([]);
  const [studentStatuses, setStudentStatuses] = useState<IStudentStatus[]>([]);
  const [availablePeople, setAvailablePeople] = useState<IPeopleAvailable[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const getAllStudents = () => studentsService.getAllForTable().then(res => setStudents(res))

  useEffect(() => {
    getAllStudents()
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getAllStudents();
      setRefresh(false);
    }
  }, [refresh]);

  const onAddClick = () => {
    peopleService.getAllAvailableStudents().then(
      res => {
        setAction('add');
        setAvailablePeople(res);
        setShowModal(true);
        sourcesService.getAll().then(
          res => {
            setSources(res)
          }
        )
        qualificationsService.getAll().then(
          res => {
            setQualifications(res)
          }
        )
        studentStatusesService.getAll().then(
          res => {
            setStudentStatuses(res)
          }
        )
      }
    )
  }

  const onDeleteClick = (tableMeta: MUIDataTableMeta<IStudent>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0])
      setShowModal(true);
    }
  }

  const onEditClick = (tableMeta: MUIDataTableMeta<IStudent>) => {
    if (tableMeta.rowData[0]) {
      studentsService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentId(tableMeta.rowData[0])
            setCurrentStudent(res)
            setShowModal(true);
            sourcesService.getAll().then(
              res => {
                setSources(res);
              }
            )
            qualificationsService.getAll().then(
              res => {
                setQualifications(res);
              }
            )
            studentStatusesService.getAll().then(
              res => {
                setStudentStatuses(res);
              }
            )
          }
        )
    }
  }

  return (
    <div className="container-fluid">
      <StudentsModal
        data={{ student: currentStudent, qualifications, sources, studentStatuses, availablePeople }}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction('');
          setCurrentStudent(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Studenti</h4>
        <button className='btn btn-success rounded-pill' onClick={onAddClick}><FontAwesomeIcon icon={faPlus} className="me-1" />Aggiungi studente</button>
      </div>
      <StudentsColumns
        students={students}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        reload={() => setRefresh(true)}
      />
    </div>
  );
}
export default Students;



