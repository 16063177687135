export const getStudentsFields = (
    people: { value: number, label: string }[],
    qualifications: { value: number, label: string }[],
    sources: { value: number, label: string }[],
    statuses: { value: number, label: string }[],
    action: string
) => {
    const fields = [
        {
            name: "status_id",
            label: "Stato dello studente",
            type: "select",
            options: statuses,
            placeholder: "Inserisci lo stato",
        },
        {
            name: "qualification_id",
            label: "Titolo di studio",
            type: "select",
            options: qualifications,
            placeholder: "Seleziona il titolo di studio",
        },
        {
            name: "source_id",
            label: "Sorgente",
            type: "select",
            options: sources,
            placeholder: "Seleziona la sorgente",
        },
        {
            name: "nationality",
            label: "Nazionalità",
            type: "text",
            placeholder: "Inserisci la nazionalità",
        },
        {
            name: "gender",
            label: "Genere",
            type: "select",
            options: [
                { value: "M", label: "M" },
                { value: "F", label: "F" }
            ],
            placeholder: "Inserisci il genere",
        },
        {
            name: "tax_code",
            label: "Codice fiscale",
            type: "text",
            placeholder: "Inserisci il codice fiscale",
        },
        {
            name: "note",
            label: "Note",
            type: "textarea",
            placeholder: "Inserisci le note",
        }
    ]

    if (action === 'add') {
        return [
            {
                name: "demetra_id",
                label: "Scegli dal personale disponibile",
                type: "select",
                placeholder: "Seleziona un utente",
                options: people,
            },
            ...fields
        ]
    } else {
        return fields
    }
}