import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { Field, Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import RealCourseGenerateCalendar from "../RealCourseModal/RealCourseGenerateCalendar";
import { IRealCourseBody } from "../../../../interfaces/RealCourse";
import { ICustomFormProps } from "../../../../common/interfaces/CustomForm";
import CustomForm from "../../../../common/components/CustomForm/CustomForm";
import * as Yup from 'yup';
import { ICalendarEventBody } from "../../../../interfaces/Calendar";
import { ICalendarEventStepperProps } from "../../../../interfaces/CalendarEventStepper";

const CalendarEventStepper: FC<ICalendarEventStepperProps> = (props) => {
    const { steps, isModal, close, type, formProps, stepperFormProps, eventAction } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    // real course stepper data
    const realCourseFormRef = useRef<FormikProps<any>>(null);
    const calendarFormRef = useRef<FormikProps<any>>(null);
    const [realCourseBody, setRealCourseBody] = useState<IRealCourseBody | undefined>(undefined)
    const [generateCalendarBody, setGenerateCalendarBody] = useState<{ hours: (string | undefined)[][] } | undefined>(undefined)
    const [isValid, setIsValid] = useState(false);
    const [trySubmit, setTrySubmit] = useState(false);
    const [tryFinalSubmit, setTryFinalSubmit] = useState(false);
    const [isFinalValid, setIsFinalValid] = useState(false);

    // event data
    const [selectedOption, setSelectedOption] = useState<'0' | '1' | '2' | ''>("")
    const morningRef = useRef<FormikProps<any>>(null);
    const afternoonRef = useRef<FormikProps<any>>(null);
    const [morningSubmit, setMorningSubmit] = useState(false);
    const [isMorningValid, setIsMorningValid] = useState(false);
    const [morningBody, setMorningBody] = useState<ICalendarEventBody & { date: Date, start_time: string, end_time: string } | undefined>(undefined)
    const [afternoonSubmit, setAfternoonSubmit] = useState(false);

    // calendar event form props for morning event
    const morningFormProps: ICustomFormProps = {
        fields: stepperFormProps?.stepperFields.morning || [],
        initialValues: stepperFormProps?.initialValues.morning || undefined,
        validationSchema: stepperFormProps?.validationSchemas.morning || Yup.object().shape({}),
        onSubmit: (values) => {
            if (selectedOption === '0')
                stepperFormProps?.handleOneSubmit({ morning: values, afternoon: undefined })
        },
        close: () => { }
    }

    // calendar event form props for afternoon event
    let afternoonFormProps: ICustomFormProps = {
        fields: stepperFormProps?.stepperFields.afternoon.map(field => {
            if ((field.name === 'date' && selectedOption === '1') || (field.name === 'date' && eventAction === 'edit'))
                return { ...field, disabled: false }
            else
                return field
        }) || [],
        initialValues: stepperFormProps?.initialValues.afternoon || undefined,
        validationSchema: stepperFormProps?.validationSchemas.afternoon || Yup.object().shape({}),
        onSubmit: (values) => {
            if (selectedOption === '1') {
                stepperFormProps?.handleOneSubmit({ morning: undefined, afternoon: values })
            }
        },
        close: () => { }
    }

    useEffect(() => {
        // if a certain calendar event is going to be edited, then select the menu option based on both morning and afternoon form initial values
        if (eventAction === 'edit') {
            if (stepperFormProps?.initialValues.morning && !stepperFormProps?.initialValues.afternoon) {
                setSelectedOption('0')
            }
            if (!stepperFormProps?.initialValues.morning && stepperFormProps?.initialValues.afternoon) {
                setSelectedOption('1')
            }
            if (stepperFormProps?.initialValues.morning && stepperFormProps?.initialValues.afternoon) {
                setSelectedOption('2')
            }
        }
    }, [eventAction, stepperFormProps])

    useEffect(() => {
        // initializing real course's and calendar events' form refs at the beginning
        if (morningRef) {
            morningRef.current?.setFieldTouched("name")
        }
        if (afternoonRef) {
            afternoonRef.current?.setFieldTouched("name")
        }
        if (realCourseFormRef) {
            realCourseFormRef.current?.setFieldTouched("name")
        }
        if (calendarFormRef) {
            calendarFormRef.current?.setFieldTouched("select_days")
        }
    })

    // checking if the real course's first step form is valid, if it is valid then go to the second step
    useEffect(() => {
        if (isValid) {
            handleNext()
            setIsValid(false)
        }
    }, [isValid])

    // checking if the calendar event's first step form is valid, if it is valid then go to the second step
    useEffect(() => {
        if (isMorningValid) {
            handleNext()
            setIsMorningValid(false)
        }
    }, [isMorningValid])

    useEffect(() => {
        // trying submitting the real course's first step form
        if (trySubmit) {
            const formRef = realCourseFormRef.current
            if (formRef !== null) {
                // submitting and checking the form validation
                formRef.submitForm().then(() => {
                    const isFormValid = formRef.isValid && Object.keys(formRef.touched).length > 0;
                    if (isFormValid) {
                        setRealCourseBody(formRef.values)
                        setIsValid(true)
                    } else {
                        setIsValid(false)
                    }
                })
            }
            setTrySubmit(false)
        }
    }, [trySubmit])

    useEffect(() => {
        // trying submitting the real course's final step form
        if (tryFinalSubmit) {
            const formRef = calendarFormRef.current
            if (formRef !== null) {
                formRef.submitForm().then(() => {
                    const isFormValid = formRef.isValid && Object.keys(formRef.touched).length > 0;
                    if (isFormValid && isFinalValid && formProps) {
                        formProps.onSubmit({ ...realCourseBody, ...generateCalendarBody })
                    }
                    setIsSubmitting(false);
                    setTryFinalSubmit(false)
                })
            }
        }
    }, [tryFinalSubmit, realCourseBody, generateCalendarBody, formProps, isFinalValid])


    useEffect(() => {
        // trying submitting the calendar event's first step form (morning)
        if (morningSubmit) {
            const formRef = morningRef.current
            const afterRef = afternoonRef.current
            if (formRef !== null) {
                if (formRef !== null) {
                    formRef.submitForm().then(async () => {
                        const isFormValid = formRef.isValid && Object.keys(formRef.touched).length > 0;
                        if (isFormValid) {
                            await setMorningBody(formRef.values)
                            if (afterRef && Object.keys(afterRef.values).length === 0) {
                                afterRef?.setFieldValue("name", formRef.values["name"])
                                afterRef?.setFieldValue("teacher_id", formRef.values["teacher_id"])
                                afterRef?.setFieldValue("description", formRef.values["description"])
                                afterRef?.setFieldValue("date", formRef.values["date"])
                                afterRef?.setFieldValue("note", formRef.values["note"])
                            }
                            setIsMorningValid(true)
                        } else {
                            setIsMorningValid(false)
                        }
                    })
                }
                setMorningSubmit(false)
            }
        }
    }, [morningSubmit])

    useEffect(() => {
        // trying submitting the calendar event's final step form (afternoon)
        if (afternoonSubmit) {
            const formRef = afternoonRef.current
            if (formRef !== null) {
                formRef.submitForm().then(() => {
                    const isFormValid = formRef.isValid && Object.keys(formRef.touched).length > 0;
                    if (isFormValid && stepperFormProps) {
                        stepperFormProps.handleAllSubmits({ morning: morningBody, afternoon: formRef.values })
                    }
                    setIsSubmitting(false);
                    setAfternoonSubmit(false)
                })
            }
        }
    }, [afternoonSubmit, morningBody, stepperFormProps])

    // handling the real course's final step submit to generate calendar events
    const handleCalendarSubmit = (hours: (string | undefined)[][]) => {
        setIsFinalValid(true)
        setGenerateCalendarBody({ hours })
    }

    // setting "activeStep" state based on stepper's next button and back button's click events
    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    // handling both the real course's and the calendar events' save button click events
    const handleStepper = () => {
        if (formProps) {
            if (activeStep !== steps.length - 1) {
                setTrySubmit(true);
            } else {
                setIsSubmitting(true);
                setTryFinalSubmit(true);
            }
        }

        if (stepperFormProps) {
            if (activeStep !== steps.length - 1) {
                setMorningSubmit(true);
            } else {
                setIsSubmitting(true);
                setAfternoonSubmit(true);
            }
        }
    }

    // selectig calendar events' option ()
    const onOptionSelected = (e: any) => {
        setSelectedOption(e.target.value)
    }

    const renderEventSteps = () => {
        if (selectedOption !== '')
            switch (selectedOption) {
                case '0':
                    return <>
                        <div className="p-2">
                            <h5 className="mb-3">Inserisci i dati del mattino</h5>
                            {morningFormProps && <CustomForm {...morningFormProps} formRef={morningRef} close={() => close()}></CustomForm>}
                        </div>
                    </>
                case '1':
                    return <>
                        <div className="p-2">
                            <h5 className="mb-3">Inserisci i dati del pomeriggio</h5>
                            {afternoonFormProps && <CustomForm {...afternoonFormProps} formRef={afternoonRef} close={() => close()}></CustomForm>}
                        </div>
                    </>
                default:
                    return <React.Fragment>
                        <Stepper activeStep={activeStep} className="pt-2">
                            {steps.map((label, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div className={activeStep === 0 ? 'd-block p-3' : 'd-none'}>
                            {
                                morningFormProps && <React.Fragment>
                                    <h5 className="mb-3">Inserisci i dati del mattino</h5>
                                    <CustomForm {...morningFormProps} isStep={true} formRef={morningRef}></CustomForm>
                                </React.Fragment>
                            }
                        </div>
                        <div className={activeStep === 1 ? 'd-block p-3' : 'd-none'}>
                            {
                                afternoonFormProps && <React.Fragment>
                                    <h5 className="mb-3">Inserisci i dati del pomeriggio</h5>
                                    <CustomForm {...afternoonFormProps} isStep={true} formRef={afternoonRef}></CustomForm>
                                </React.Fragment>
                            }
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: isModal ? 'space-between' : 'flex-end', pt: 2 }}>
                            {
                                isModal && <Button variant="secondary" onClick={() => close()}>
                                    Chiudi
                                </Button>
                            }
                            <div>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className="me-2"
                                >
                                    Indietro
                                </Button>
                                <Button onClick={() => handleStepper()}>
                                    {activeStep === steps.length - 1 ? 'Salva tutti i dati' : 'Avanti'}
                                </Button>
                            </div>
                        </Box>
                    </React.Fragment>
            }
    }

    const renderRealCourseSteps = () => {
        return <React.Fragment>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div className={activeStep === 0 ? 'd-block p-3' : 'd-none'}>
                {formProps && <CustomForm {...formProps} initialValues={formProps.initialValues} isStep={true} formRef={realCourseFormRef} close={() => close()}></CustomForm>}
            </div>
            <div className={activeStep === 1 ? 'd-block p-3' : 'd-none'}>
                <RealCourseGenerateCalendar formRef={calendarFormRef} onSubmit={handleCalendarSubmit}></RealCourseGenerateCalendar>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: isModal ? 'space-between' : 'flex-end', pt: 2 }}>
                {
                    isModal && <Button variant="secondary" onClick={() => close()}>
                        Chiudi
                    </Button>
                }
                <div>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className="me-2"
                    >
                        Indietro
                    </Button>
                    <Button onClick={() => handleStepper()} disabled={isSubmitting}>
                        {activeStep === steps.length - 1 ? 'Salva tutti i dati' : 'Avanti'}
                    </Button>
                </div>
            </Box>
        </React.Fragment>
    }


    return <Box>
        {
            type === 'realCourse' && renderRealCourseSteps()
        }
        {
            type === 'event' && stepperFormProps && <React.Fragment>
                {eventAction === 'add' && <Formik
                    initialValues={{
                        addOption: '',
                    }}
                    onSubmit={() => { }}
                >
                    {() => (
                        <Form className='form-group p-2' role="group" aria-labelledby="my-radio-group" onChange={(e) => onOptionSelected(e)}>
                            <h5>Seleziona una tra le seguenti opzioni</h5>
                            <div className="form-check">
                                <Field className="form-check-input" type="radio" name="selection" value="0" />
                                <label className="form-check-label">
                                    Solo mattino
                                </label>
                            </div>
                            <div className="form-check">
                                <Field className="form-check-input" type="radio" name="selection" value="1" />
                                <label className="form-check-label">
                                    Solo pomeriggio
                                </label>
                            </div>
                            <div className="form-check">
                                <Field className="form-check-input" type="radio" name="selection" value="2" />
                                <label className="form-check-label">
                                    Sia mattino che pomeriggio
                                </label>
                            </div>
                        </Form>
                    )}
                </Formik>}
                {
                    renderEventSteps()
                }
            </React.Fragment>
        }
    </Box>
}

export default CalendarEventStepper;