import { FC, useState } from "react";
import * as Yup from "yup";
import CustomModal from "../../../common/components/CustomModal/CustomModal";
import { ICustomFormProps } from "../../../common/interfaces/CustomForm";
import { IModalProps } from "../../../common/interfaces/CustomModal";
import { getStudentTeacherFileFields } from "../../../config/formFields/StudentTeacherFileFields";
import { IFile } from "../../../interfaces/File";
import { IStudentFile } from "../../../interfaces/StudentFile";
import fileService from "../../../services/FileService";
import studentFileService from "../../../services/StudentFileService";

interface IStudentFileModalProps {
    studentFile: IStudentFile | undefined,
    currentFile: IFile | undefined,
    studentId: number;
    show: boolean;
    close: (action?: string) => void;
    action: string;
    id: number | undefined;
}

const StudentFileModal: FC<IStudentFileModalProps> = (props) => {
    const { action, show, close, id, studentId, studentFile, currentFile } = props;
    const [file, setFile] = useState<File | undefined>(undefined)

    const validationSchema = action === 'add' ? Yup.object().shape({
        file: Yup.string().required("* Campo obbligatorio"),
        description: Yup.string().nullable()
    }) : Yup.object().shape({ description: Yup.string().nullable() })

    const handleSubmit = async (values: { file: File, description: string }) => {
        if (action === "edit" && currentFile) {
            fileService.put(currentFile.id, { file: { ...currentFile, name: currentFile.client_name }, description: values.description }).then(() => close('edit'))
        }

        if (action === "add" && file) {
            fileService.post({ file, description: values.description }).then(
                res => studentFileService.post(studentId, { file_id: res.id }).then(() => close('add'))
            )
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id && studentFile) {
            studentFileService.delete(studentId, id).then(() => {
                fileService.delete(studentFile.file.id).then(() => close('delete'))
            })
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: studentFile ? "Modifica il file" : "Aggiungi un nuovo allegato"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: getStudentTeacherFileFields(action),
        initialValues: currentFile ? { description: currentFile.description } : undefined,
        onSubmit: handleSubmit,
        validationSchema,
        setFile: (file: File | undefined) => setFile(file)
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default StudentFileModal;
