import { faInfoCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import { ITableClassroom } from '../../interfaces/Classroom';

const ClassroomsColumns = (props: {
    onEditClick: (tableMeta: MUIDataTableMeta<ITableClassroom>) => void,
    onDeleteClick: (tableMeta: MUIDataTableMeta<ITableClassroom>) => void,
    classrooms: ITableClassroom[]
}) => {

    const { onEditClick, onDeleteClick, classrooms } = props

    const navigate = useNavigate()

    const classroomColumns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "name",
            label: "Nome aula"
        },
        {
            name: "course_name",
            label: "Nome corso"
        },
        {
            name: "members",
            label: "Membri"
        },
        {
            name: "azioni",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ITableClassroom>, updateValue: any) => {
                    return <>
                        <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
                        <Button className='btn btn-danger rounded-circle me-1 shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
                        <Button className='btn btn-primary rounded-circle shadow-sm' onClick={() => navigate('/aule/' + tableMeta.rowData[0])}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </>
                }
            }
        },
    ]
    return (
        <CustomTable
            title={"Elenco di tutti le aule disponibili"}
            data={classrooms}
            columns={classroomColumns}
        />
    )
}

export default ClassroomsColumns;