import axios from "axios";
import { IFile } from "../interfaces/File";

class FileService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IFile[]> {
        return await axios.get<{ statusCode: number, data: IFile[] }>(this.baseUrl + '/files').then(response => response.data.data)
    }

    async get(fileId: number): Promise<IFile> {
        return await axios.get<{ statusCode: number, data: IFile }>(this.baseUrl + '/file/' + fileId).then(response => response.data.data)
    }

    async preview(fileId: number): Promise<void> {
        axios({
            method: 'GET',
            url: this.baseUrl + '/file/' + fileId + '/download',
            responseType: 'blob'
        }).then(
            response => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.target = '_blank'
                document.body.appendChild(link);
                link.click();
            }
        )
    }

    async download(fileId: number): Promise<void> {
        axios({
            method: 'GET',
            url: this.baseUrl + '/file/' + fileId + '/download',
            responseType: 'blob'
        }).then(
            response => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', response.headers["content-description"]); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        )
    };

    async post(body: { file: File, description?: string | null }): Promise<IFile> {
        const formData = new FormData();
        if (body.description)
            formData.append('description', body.description);
        formData.append('file', body.file, body.file.name);
        return await axios.post(this.baseUrl + '/file', formData).then(response => response.data.data)
    }

    async put(fileId: number, body: { file: IFile, description?: string | null }): Promise<IFile> {
        return await axios.put(this.baseUrl + '/file/' + fileId, { ...body.file, description: body.description }).then(response => response.data.data)
    }

    async delete(fileId: number): Promise<number> {
        return await axios.delete(this.baseUrl + '/file/' + fileId).then(() => fileId)
    }
}

const fileService = new FileService();

export default fileService;