import "./Login.css";
import React, { FC, useContext, useState } from "react";
import authService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../common/components/AuthHandler/AuthHandler";

export const Login: FC<{}> = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    authService.requestAuth(credentials)
      .then(
        res => {
          authService.login(res).then(
            res => {
              let authData = {
                ...res.data,
                expire: Date.now() + res.data.expire * 1000
              }
              authService.setAuth(authData)
              authContext.setAuth(authData)
              navigate('/dashboard')
            },
            err => authService.logout()
          )
        },
        err => authService.logout()
      )
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="h-100 d-flex align-items-center justify-content-center mx-2">
      <div className="max-width card col-12">
        <div className="card-body p-4">
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="text-center">
              <img className="mb-3" src="/assets/img/egeria-logo.png" alt="logo" height="64px" />
              <h6 className="text-center mb-3">Accedi al tuo account</h6>
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="d-flex justify-content-center mb-3">
              <button type="submit" className="btn btn-primary">
                Accedi
              </button>
            </div>
          </form>
          <div className='w-100 d-flex justify-content-center align-items-center mx-auto'>
            <span>Powered by</span>
            <a className='nav-link' href="https://www.cgmconsulting.it" target="blank">
              <img src="/assets/img/CGM-logo.png" alt="logo" height="40px" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
