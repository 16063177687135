import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MUIDataTableMeta } from 'mui-datatables';
import React, { FC, useEffect, useState } from 'react';
import ContractTypesColumns from '../../../config/tableColumns/ContractTypesColumns';
import TipologiaContratto from '../../../interfaces/ContractType';
import contractTypesService from '../../../services/ContractTypesService';
import ContractTypesModal from './ContractTypesModal';

const ContractTypes: FC<{}> = () => {
  const [contractTypes, setContractTypes] = useState<TipologiaContratto[]>([]);
  const [currentContractType, setCurrentContractType] = useState<TipologiaContratto | undefined>(undefined)
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const getAllContractTypes = () => contractTypesService.getAll().then(res => setContractTypes(res))

  useEffect(() => {
    getAllContractTypes()
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getAllContractTypes();
      setRefresh(false);
    }
  }, [refresh]);

  const onEditClick = (tableMeta: MUIDataTableMeta<TipologiaContratto>) => {
    if (tableMeta.rowData[0]) {
      contractTypesService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentId(tableMeta.rowData[0])
            setCurrentContractType(res)
            setShowModal(true);
          }
        )
    }
  }

  const onDeleteClick = (tableMeta: MUIDataTableMeta<TipologiaContratto>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0])
      setShowModal(true);
    }
  }

  return (
    <div className="container-fluid">
      <ContractTypesModal
        contractType={currentContractType}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction('')
          setCurrentContractType(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Tipologie contratti</h4>
        <button className='btn btn-success rounded-pill' onClick={() => { setShowModal(true); setAction('add') }}><FontAwesomeIcon icon={faPlus} className="me-1" />Crea tipologia contratto</button>
      </div>
      <ContractTypesColumns contractTypes={contractTypes} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
    </div>
  )
};

export default ContractTypes;
