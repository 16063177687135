import { MUIDataTableMeta } from "mui-datatables";
import { FC, useEffect, useState } from "react";
import { ITeacher, IGetTeacher } from "../../interfaces/Teacher";
import TeachersModal from "./TeachersModal";
import TeachersColumns from "../../config/tableColumns/TeachersColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import teachersService from "../../services/TeachersService";
import qualificationsService from "../../services/QualificationsService";
import peopleService from "../../services/PeopleService";
import { IPeopleAvailable } from "../../interfaces/Person";

const Teachers: FC<{}> = () => {
  const [teachers, setTeachers] = useState<ITeacher[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentTeacher, setCurrentTeacher] = useState<IGetTeacher | undefined>(undefined);
  const [qualifications, setQualifications] = useState<any[]>([]);
  const [availablePeople, setAvailablePeople] = useState<IPeopleAvailable[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const getAllTeachers = () => teachersService.getAllForTable().then(res => setTeachers(res))

  useEffect(() => {
    getAllTeachers()
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getAllTeachers();
      setRefresh(false);
    }
  }, [refresh]);

  const onAddClick = () => {
    peopleService.getAllAvailableTeachers().then(
      res => {
        setAction('add');
        setAvailablePeople(res);
        qualificationsService.getAll().then(
          res => {
            setQualifications(res)
            setShowModal(true);
          }
        )
      }
    )
  }

  const onDeleteClick = (tableMeta: MUIDataTableMeta<ITeacher>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0])
      setShowModal(true);
    }
  }

  const onEditClick = (tableMeta: MUIDataTableMeta<ITeacher>) => {
    if (tableMeta.rowData[0]) {
      teachersService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentId(tableMeta.rowData[0])
            setCurrentTeacher(res)
            qualificationsService.getAll().then(
              res => {
                setQualifications(res)
                setShowModal(true);
              }
            )
          }
        )
    }
  }

  return (
    <div className="container-fluid">
      <TeachersModal
        data={{ teacher: currentTeacher, qualifications, availablePeople }}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction('')
          setCurrentTeacher(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Docenti</h4>
        <button className='btn btn-success rounded-pill' onClick={onAddClick}><FontAwesomeIcon icon={faPlus} className="me-1" />Aggiungi docente</button>
      </div>
      <TeachersColumns
        teachers={teachers}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
    </div>
  );
}
export default Teachers;


