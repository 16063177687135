import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import ISource from '../../interfaces/Source';
import IStudentStatus from '../../interfaces/StudentStatus';

const StudentStatusesColumns = (props: {
    onEditClick: (tableMeta: MUIDataTableMeta<IStudentStatus>) => void,
    onDeleteClick: (tableMeta: MUIDataTableMeta<IStudentStatus>) => void,
    studentStatuses: IStudentStatus[]
}) => {
    const { studentStatuses, onEditClick, onDeleteClick } = props

    const studentStatusesColumns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "name",
            label: "Nome",
        },
        {
            name: "code",
            label: "Codice",
        },
        {
            name: "azioni",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ISource>, updateValue: any) => (
                    <>
                        <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
                        <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>
                )
            }
        },
    ]

    return (
        <CustomTable
            title={"Elenco di tutti gli stati degli studenti disponibili"}
            data={studentStatuses}
            columns={studentStatusesColumns}
        />
    )
}

export default StudentStatusesColumns;