import { faAdd, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rating, TableCell, TableRow } from "@mui/material";
import { MUIDataTableColumnDef, MUIDataTableMeta } from "mui-datatables";
import { FC } from "react";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import CustomTable from "../../common/components/CustomTable/CustomTable";
import DetailsTeacher from "../../components/Teachers/DetailsTeacher";
import DetailsStudent from "../../components/Students/DetailsStudent";
import { IClassroomStudent } from "../../interfaces/ClassroomStudent";
import { IClassroomTeacher } from "../../interfaces/ClassroomTeacher";
import { IStudent } from "../../interfaces/Student";
import { ITeacher } from "../../interfaces/Teacher";
import ClassroomValuations from "../../components/Classrooms/ClassroomDetails/ClassroomValuations/ClassroomValuations";
import { IListClassroomValuation } from "../../interfaces/ClassroomValuation";

export const ClassroomTeacherColumns: FC<{
    classroomId: string | undefined,
    data?: IClassroomTeacher[],
    tableData: ITeacher[],
    title: string,
    onEditClick?: (teacherId: number) => void,
    onAddClick?: (teacherId: number) => void,
    onDeleteClick?: (teacherId: number) => void
}> = (props) => {

    const { classroomId, data, tableData, onEditClick, onAddClick, onDeleteClick, title } = props

    let profilesColumns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "first_name",
            label: "Nome",
            options: { sort: true }
        },
        {
            name: "activity",
            label: "Attività"
        },
        {
            name: "hourly_fee",
            label: "C. orario (€)"
        },
        {
            name: "assessment_course",
            label: "Valutazione HR",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ITeacher>, updateValue: any) => <Rating className="ms-2" name="read-only" value={value} readOnly />
            }
        },
        {
            name: "VAT_number",
            label: "P. IVA"
        },
        {
            name: "qualification",
            label: "Qualifica"
        },
        {
            name: "actions",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ITeacher>, updateValue: any) => (
                    <div className='d-flex'>
                        {onAddClick && <Button className='btn btn-success rounded-circle shadow-sm' onClick={() => onAddClick(Number(tableMeta.rowData[0]))}><FontAwesomeIcon icon={faAdd} /></Button>}
                        {onEditClick && <Button className='btn btn-primary rounded-circle shadow-sm me-1' onClick={() => onEditClick(Number(tableMeta.rowData[0]))}><FontAwesomeIcon icon={faPen} /></Button>}
                        {onDeleteClick && <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(Number(tableMeta.rowData[0]))}><FontAwesomeIcon icon={faTrash} /></Button>}
                    </div>
                )
            }
        }]

    const renderProfileDetails = (rowData: string[], rowMeta: {
        dataIndex: number;
        rowIndex: number;
    }) => {
        const colSpan = rowData.length + 1;

        const teacherId = data ? data.find(classTeacher => classTeacher.id === Number(rowData[0]))?.teacher.id : undefined
        const teacher = data ? data.find(classTeacher => classTeacher.id === Number(rowData[0])) : undefined

        return <TableRow>
            <TableCell colSpan={colSpan}>
                <DetailsTeacher id={teacherId ? teacherId : Number(rowData[0])} />
                {
                    data && <>
                        <div className="col-12 p-3">
                            <Card className="shadow-sm">
                                <Card.Header><span className="fw-600">Informazioni aggiuntive</span></Card.Header>
                                <Card.Body className="d-flex flex-wrap">
                                    <Card.Text className="col-4">Tempi di pagamento: {teacher?.payment_times || '-'}</Card.Text>
                                    <Card.Text className="col-4">Tassa: {teacher?.fee || '-'} </Card.Text>
                                    <Card.Text className="col-4">Ritenuta d'acconto: {teacher?.withholding_tax ? 'Sì' : 'No'}</Card.Text>
                                    <Card.Text className="col-12">Note: {teacher?.note || '-'}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-12 p-3">
                            <ClassroomValuations
                                classroomId={classroomId}
                                profileId={teacher?.id || 0}
                                recipient={2}
                            />
                        </div>
                    </>
                }
            </TableCell>
        </TableRow>
    }

    return (
        <CustomTable
            title={title}
            data={tableData}
            columns={profilesColumns}
            options={{
                sortOrder: {
                    name: "first_name",
                    direction: "asc"
                },
                expandableRows: true,
                renderExpandableRow: renderProfileDetails
            }}
        />
    )

}

export const ClassroomStudentColumns: FC<{
    classroomId: string | undefined,
    data?: IClassroomStudent[],
    tableData: IStudent[],
    title: string,
    onEditClick?: (studentId: number) => void,
    onAddClick?: (studentId: number) => void,
    onDeleteClick?: (studentId: number) => void
}> = (props) => {

    const { classroomId, data, tableData, onEditClick, onAddClick, onDeleteClick, title } = props

    let studentsColumns: MUIDataTableColumnDef[] = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "first_name",
            label: "Nome",
            options: { sort: true }
        },
        {
            name: "status",
            label: "Stato",
        },
        {
            name: "qualification",
            label: "Qualifica"
        },
        {
            name: "contract_type",
            label: "Tipologia contratto"
        },
        {
            name: "source",
            label: "Sorgente"
        },
        {
            name: "actions",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IStudent>, updateValue: any) => (
                    <div className='d-flex'>
                        {onAddClick && <Button className='btn btn-success rounded-circle me-1 shadow-sm' onClick={() => onAddClick(Number(tableMeta.rowData[0]))}><FontAwesomeIcon icon={faAdd} /></Button>}
                        {onEditClick && <Button className='btn btn-primary rounded-circle shadow-sm me-1' onClick={() => onEditClick(Number(tableMeta.rowData[0]))}><FontAwesomeIcon icon={faPen} /></Button>}
                        {onDeleteClick && <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(Number(tableMeta.rowData[0]))}><FontAwesomeIcon icon={faTrash} /></Button>}
                    </div>
                )
            }
        }
    ]

    const renderProfileDetails = (rowData: string[], rowMeta: {
        dataIndex: number;
        rowIndex: number;
    }) => {
        const colSpan = rowData.length + 1;

        const studentId = data ? data.find(classTeacher => classTeacher.id === Number(rowData[0]))?.student.id : undefined
        const student = data ? data.find(classTeacher => classTeacher.id === Number(rowData[0])) : undefined

        return <TableRow>
            <TableCell colSpan={colSpan}>
                <DetailsStudent id={studentId ? studentId : Number(rowData[0])} />
                {
                    data && <>
                        <div className="col-12 p-3">
                            <Card className="shadow-sm">
                                <Card.Header><span className="fw-600">Informazioni aggiuntive</span></Card.Header>
                                <Card.Body>
                                    <Card.Text>Note: {student?.note || '-'}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-12 p-3">
                            <ClassroomValuations
                                classroomId={classroomId}
                                profileId={student?.id || 0}
                                recipient={1}
                            />
                        </div>
                    </>
                }
            </TableCell>
        </TableRow>
    }

    return (
        <CustomTable
            title={title}
            data={tableData}
            columns={studentsColumns}
            options={{
                sortOrder: {
                    name: "first_name",
                    direction: "asc"
                },
                expandableRows: true,
                renderExpandableRow: renderProfileDetails
            }}
        />
    )
}

export const ClassroomValuationColumns: FC<{
    listData: IListClassroomValuation[],
    onEditClick?: (valuation: IListClassroomValuation) => void,
    onDeleteClick?: (valuation: IListClassroomValuation) => void
}> = (props) => {

    const { listData, onEditClick, onDeleteClick } = props

    return (
        <ListGroup>
            {
                listData.map(
                    valuation => {
                        return <ListGroupItem key={valuation.id} className="d-flex align-items-center">
                            <div className="col-3">
                                {valuation.from}
                            </div>
                            <div className="col-3">
                                <Rating className="ms-2" name="read-only" value={valuation.vote} readOnly />
                            </div>
                            <div className="col-4">
                                {valuation.note || '-'}
                            </div>
                            <div className="col-2 d-flex justify-content-end">
                                {onEditClick && <Button className='btn btn-primary rounded-circle shadow-sm me-1' onClick={() => onEditClick(valuation)}><FontAwesomeIcon icon={faPen} /></Button>}
                                {onDeleteClick && <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(valuation)}><FontAwesomeIcon icon={faTrash} /></Button>}
                            </div>
                        </ListGroupItem>
                    }
                )
            }
            {listData.length === 0 && <p>Nessuna valutazione trovata</p>}
        </ListGroup>
    )

}