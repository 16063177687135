export const getStudentTeacherFileFields = (action: string) => {
    return action === 'add' ? [
        {
            name: 'file',
            type: 'file',
            placeholder: 'Carica il file',
            label: 'Allegato da aggiungere',
        },
        {
            name: 'description',
            type: 'text',
            placeholder: 'Inserisci la descrizione',
            label: 'Descrizione'
        },
    ] : [
        {
            name: 'description',
            type: 'text',
            placeholder: 'Inserisci la descrizione',
            label: 'Descrizione'
        }
    ]
}
