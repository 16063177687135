import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import IUser from '../../interfaces/User';

const UsersColumns = (props: {
    onEditClick: (tableMeta: MUIDataTableMeta<IUser>) => void,
    onDeleteClick: (tableMeta: MUIDataTableMeta<IUser>) => void,
    users: IUser[]
}) => {
    const { users, onEditClick, onDeleteClick } = props

    const usersColumns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "first_name",
            label: "Nome",
        },
        {
            name: "username",
            label: "Username",
        },
        {
            name: "email_address",
            label: "Email",
        },
        {
            name: "status",
            label: "Stato",
        },
        {
            name: "azioni",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IUser>, updateValue: any) => (
                    <>
                        <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
                        <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>
                )
            }
        },
    ]

    return (
        <CustomTable
            title={"Elenco di tutti gli utenti disponibili"}
            data={users}
            columns={usersColumns}
            options={{
                sortOrder: {
                    name: "first_name",
                    direction: "asc"
                }
            }}
        />
    )
}

export default UsersColumns;