import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MUIDataTableMeta } from 'mui-datatables';
import React, { FC, useEffect, useState } from 'react';
import UsersColumns from '../../config/tableColumns/UsersColumns';
import IUser from '../../interfaces/User';
import usersService from '../../services/UsersService';
import UsersModal from './UsersModal';

const Users: FC<{}> = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState("");

  const getAllUsers = () => usersService.getAll().then((res) => setUsers(res));

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getAllUsers();
      setRefresh(false);
    }
  }, [refresh]);

  const onEditClick = (tableMeta: MUIDataTableMeta<IUser>) => {
    if (tableMeta.rowData[0]) {
      usersService.get(tableMeta.rowData[0]).then(
        (res) => {
          setAction("edit");
          setCurrentId(tableMeta.rowData[0]);
          setCurrentUser(res);
          setShowModal(true);
        },
      );
    }
  };

  const onDeleteClick = (tableMeta: MUIDataTableMeta<IUser>) => {
    if (tableMeta.rowData[0]) {
      setAction("delete");
      setCurrentId(tableMeta.rowData[0]);
      setShowModal(true);
    }
  };

  return (
    <div className="container-fluid">
      <UsersModal
        user={currentUser}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction("");
          setCurrentUser(undefined);
          setCurrentId(undefined);
          if (typeof action === "string") setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Utenti</h4>
        <button
          className="btn btn-success rounded-pill"
          onClick={() => {
            setShowModal(true);
            setAction("add");
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="me-1" />
          Aggiungi utente
        </button>
      </div>
      <UsersColumns
        users={users}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
    </div>
  );
};

export default Users;
