import * as Yup from 'yup';
import CustomModal from "../../common/components/CustomModal/CustomModal";
import IUser from "../../interfaces/User";
import usersService from '../../services/UsersService';
import { usersFields } from '../../config/formFields/UsersFields';
import { FC } from 'react';
import { IModalProps } from '../../common/interfaces/CustomModal';
import { ICustomFormProps } from '../../common/interfaces/CustomForm';

interface IUsersModalProps {
    user: IUser | undefined,
    id: number | undefined,
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const UsersModal: FC<IUsersModalProps> = (props) => {
    const { user, id, show, close, action } = props

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("* Campo obbligatorio"),
        last_name: Yup.string().required("* Campo obbligatorio"),
        email_address: Yup.string().email("Email non valida").required("* Campo obbligatorio"),
        username: Yup.string().required("* Campo obbligatorio"),
        status: Yup.string().required("* Campo obbligatorio"),
    })

    const handleSubmit = async (values: IUser) => {
        if (action === "add") {
            usersService.post(values).then(() => close("add"))
        }
        if (action === "edit" && id) {
            usersService.put(id, values).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            usersService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: user ? 'Modifica i dati di "' + user.last_name + ' ' + user.first_name + '"' : "Aggiungi un nuovo utente"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: usersFields,
        initialValues: user,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>

}

export default UsersModal;