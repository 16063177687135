import React, { FC, useEffect, useState } from 'react';
import { MUIDataTableMeta } from 'mui-datatables';
import CourseTypesColumns from '../../../config/tableColumns/CoursesTypesColumns';
import CourseTypesModal from './CourseTypesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TipologiaCorso from '../../../interfaces/CourseType';
import courseTypesService from '../../../services/CourseTypesService';

const CourseTypes: FC<{}> = () => {
  const [courseTypes, setCourseTypes] = useState<TipologiaCorso[]>([]);
  const [currentCourseType, setCurrentCourseType] = useState<TipologiaCorso | undefined>(undefined)
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const getAllCourseTypes = () => courseTypesService.getAll().then(res => setCourseTypes(res))

  useEffect(() => {
    getAllCourseTypes()
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getAllCourseTypes();
      setRefresh(false);
    }
  }, [refresh]);

  const onEditClick = (tableMeta: MUIDataTableMeta<TipologiaCorso>) => {
    if (tableMeta.rowData[0]) {
      courseTypesService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentId(tableMeta.rowData[0])
            setCurrentCourseType(res)
            setShowModal(true);
          }
        )
    }
  }

  const onDeleteClick = (tableMeta: MUIDataTableMeta<TipologiaCorso>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0])
      setShowModal(true);
    }
  }

  return (
    <div className="container-fluid">
      <CourseTypesModal
        courseType={currentCourseType}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction('')
          setCurrentCourseType(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Tipologie corsi</h4>
        <button className='btn btn-success rounded-pill' onClick={() => { setShowModal(true); setAction('add') }}><FontAwesomeIcon icon={faPlus} className="me-1" />Crea tipologia</button>
      </div>
      <CourseTypesColumns courseTypes={courseTypes} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
    </div>
  )
}

export default CourseTypes;