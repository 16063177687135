import axios from "axios"
import { IEmplyomentAgency } from "../interfaces/EmploymentAgency"

class EmploymentAgenciesService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IEmplyomentAgency[]> {
        return await axios.get(this.baseUrl + "/employment-agencies").then(response => response.data.data)
    }

    async get(id: number): Promise<IEmplyomentAgency> {
        return await axios.get(this.baseUrl + "/employment-agency/" + id).then(response => response.data.data)
    }

    async post(body: IEmplyomentAgency): Promise<IEmplyomentAgency> {
        return await axios.post(this.baseUrl + '/employment-agency', body).then(response => response.data.data)
    }

    async put(id: number, body: IEmplyomentAgency): Promise<IEmplyomentAgency> {
        return await axios.put(this.baseUrl + '/employment-agency/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/employment-agency/' + id).then(response => response.data.data)
    }
}

const employmentAgenciesService = new EmploymentAgenciesService();

export default employmentAgenciesService;