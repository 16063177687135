import * as Yup from "yup";
import CustomModal from "../../../../common/components/CustomModal/CustomModal";
import { FC } from "react";
import { IModalProps } from "../../../../common/interfaces/CustomModal";
import { ICustomFormProps } from "../../../../common/interfaces/CustomForm";
import { IClassroomStudent, IClassroomStudentBody } from "../../../../interfaces/ClassroomStudent";
import classroomStudentsService from "../../../../services/ClassroomStudentsService";
import { classroomStudentsFields } from "../../../../config/formFields/ClassroomStudentsFields";

interface IClassroomDetailsModalStudentProps {
    currentStudent: IClassroomStudent | undefined
    show: boolean;
    close: (action?: string) => void;
    action: string;
    id: number | undefined,
    classroomId: number
}

const ClassroomDetailsModalStudent: FC<IClassroomDetailsModalStudentProps> = (props) => {

    const { id, action, show, close, currentStudent, classroomId } = props;

    const studentProfile = {
        student_id: action === "add" ? id : currentStudent?.student.id,
        note: currentStudent ? currentStudent.note : undefined,
    }

    const validationSchema = Yup.object().shape({
        student_id: Yup.number().required("* Campo obbligatorio"),
        note: Yup.string().nullable()
    })

    const handleSubmit = async (values: IClassroomStudentBody) => {
        if (action === "add") {
            classroomStudentsService.post(classroomId, values).then(() => close("add-student"))
        }
        if (action === "edit" && id) {
            classroomStudentsService.put(classroomId, id, values).then(() => close("edit-student"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            classroomStudentsService.delete(classroomId, id).then(() => close("delete-student"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: action === 'add' ? "Inserisci informazioni aggiuntive" : "Modifica informazioni aggiuntive"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: classroomStudentsFields,
        initialValues: studentProfile,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default ClassroomDetailsModalStudent;
