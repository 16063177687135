import axios from "axios";
import { IStudentInternal, IStudentInternalBody } from "../interfaces/StudentInternal";

class StudentInternalService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IStudentInternal[]> {
        return await axios.get<any>(this.baseUrl + '/student/internals').then(response => response.data.data)
    }

    async get(studentId: number, id: number): Promise<IStudentInternal> {
        return await axios.get(this.baseUrl + '/student/' + studentId + '/internal/' + id).then(response => response.data.data)
    }

    async post(studentId: number, body: IStudentInternalBody): Promise<IStudentInternal> {
        return await axios.post(this.baseUrl + '/student/' + studentId + '/internal', body).then(response => response.data.data)
    }

    async put(studentId: number, id: number, body: IStudentInternalBody): Promise<IStudentInternal> {
        return await axios.put(this.baseUrl + '/student/' + studentId + '/internal/' + id, body).then(response => response.data.data)
    }

    async delete(studentId: number, id: number) {
        return await axios.delete(this.baseUrl + '/student/' + studentId + '/internal/' + id).then(response => response.data.data)
    }
}

const studentInternalService = new StudentInternalService();

export default studentInternalService;