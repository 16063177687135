import axios from "axios";
import ICourseType from "../interfaces/CourseType";

class CourseTypesService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<ICourseType[]> {
        return await axios.get(this.baseUrl + "/course/types").then(response => response.data.data)
    }

    async get(id: number): Promise<ICourseType> {
        return await axios.get(this.baseUrl + "/course/type/" + id).then(response => response.data.data)
    }

    async post(body: ICourseType): Promise<ICourseType> {
        return await axios.post(this.baseUrl + '/course/type', body).then(response => response.data.data)
    }

    async put(id: number, body: ICourseType): Promise<ICourseType> {
        return await axios.put(this.baseUrl + '/course/type/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/course/type/' + id).then(response => response.data.data)
    }
}

const courseTypesService = new CourseTypesService();

export default courseTypesService;