import axios, { AxiosResponse } from "axios";
import { ICalendarEvent, ICalendarEventBody } from "../interfaces/Calendar";

class CalendarService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getCalendarEventsByRealCourse(realCourseId: number): Promise<ICalendarEvent[]> {
        return await axios.get<{ statusCode: number, data: ICalendarEvent[] }>(this.baseUrl + '/course/real/' + realCourseId + '/calendar').then(response => response.data.data)
    }

    async generateCalendarEvents(realCourseId: number, body: { start_date: string, hours: string[][] }): Promise<any> {
        return await axios.post(this.baseUrl + '/course/real/' + realCourseId + '/calendar/generate', body).then(response => response.data.data)
    }

    async moveCalendar(realCourseId: number, body: { start_date: string, hours: string[][] }): Promise<any> {
        return await axios.put(this.baseUrl + '/course/real/' + realCourseId + '/calendar/move', body).then(response => response.data.data)
    }

    async refreshCalendar(realCourseId: number, body: { old_course_real_id: number }): Promise<any> {
        return await axios.post(this.baseUrl + '/course/real/' + realCourseId + '/calendar/renew', body).then(response => response.data.data)
    }

    async get(realCourseId: number, eventId: string): Promise<ICalendarEvent> {
        return await axios.get<{ statusCode: number, data: ICalendarEvent }>(this.baseUrl + '/course/real/' + realCourseId + '/calendar/' + eventId).then(response => response.data.data)
    }

    createLink(response: AxiosResponse<any, any>) {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', response.headers["content-description"]); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    async exportCsv(courseId: number) {
        axios({
            method: 'GET',
            url: this.baseUrl + '/course/real/' + courseId + '/calendar/csv',
            responseType: 'blob'
        }).then(
            response => this.createLink(response)
        )
    }
    async exportXls(courseId: number) {
        axios({
            method: 'GET',
            url: this.baseUrl + '/course/real/' + courseId + '/calendar/excel',
            responseType: 'blob'
        }).then(
            response => this.createLink(response)
        )
    }

    async post(realCourseId: number, body: ICalendarEventBody): Promise<ICalendarEvent> {
        return await axios.post(this.baseUrl + '/course/real/' + realCourseId + '/calendar', body).then(response => response.data.data)
    }

    async put(realCourseId: number, eventId: number, body: ICalendarEventBody): Promise<ICalendarEvent> {
        return await axios.put(this.baseUrl + '/course/real/' + realCourseId + '/calendar/' + eventId, body).then(response => response.data.data)
    }

    async delete(realCourseId: number, eventId: number): Promise<ICalendarEvent> {
        return await axios.delete(this.baseUrl + '/course/real/' + realCourseId + '/calendar/' + eventId).then(response => response.data.data)
    }
}

const calendarService = new CalendarService();

export default calendarService;