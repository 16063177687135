import { format } from "date-fns";
import { FC, useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import { IGetStudent } from "../../interfaces/Student";
import studentsService from "../../services/StudentsService";
import StudentFile from "./StudentFile/StudentFile";
import StudentInternal from "./StudentInternal/StudentInternal";

const DetailsStudent: FC<{ id: number, reload?: (reload: boolean) => void }> = (props) => {

    const [currentStudent, setCurrentStudent] = useState<IGetStudent | undefined>(undefined)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        studentsService.get(props.id).then(
            res => {
                setCurrentStudent(res)
            },
            error => setShowError(true)
        )
    }, [props.id])

    return currentStudent ? <div className="d-flex flex-column p-3">
        <div className="col-12 d-flex h-100">
            <div className="col-3">
                <Card className="shadow-sm h-100">
                    <Card.Header><span className="fw-600">Informazioni generali</span></Card.Header>
                    <Card.Body>
                        <Card.Title className="mb-3">{currentStudent.last_name + ' ' + (currentStudent.middle_name || '') + ' ' + currentStudent.first_name}</Card.Title>
                        <Card.Text>Stato: <span className="fw-600">{currentStudent.status.name}</span></Card.Text>
                        <Card.Text>Email principale: {currentStudent.email1 || '-'}</Card.Text>
                        <Card.Text>Email secondaria: {currentStudent.email2 || '-'} </Card.Text>
                        <Card.Text>N° di cellulare: {currentStudent.phone_cell || '-'}</Card.Text>
                        <Card.Text>N° di casa: {currentStudent.phone_home || '-'}</Card.Text>
                        <Card.Text>N° di lavoro: {currentStudent.phone_work || '-'}</Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-9 ps-3">
                <Card>
                    <Card.Header><span className="fw-600">Informazioni professionali</span></Card.Header>
                    <Card.Body className="d-flex flex-wrap">
                        <Card.Text className="col-4">Titolo di studio: {currentStudent.qualification.name || '-'}</Card.Text>
                        <Card.Text className="col-4">
                            <span className="d-flex align-items-center">
                                Skills: {
                                    currentStudent.skills?.length !== 0 ? currentStudent.skills?.map(
                                        (skill, index) => <Badge key={index + '-skill-' + skill} pill bg="secondary" className={index === 0 ? "ms-1" : "me-2"}>
                                            {skill}
                                        </Badge>
                                    ) : '-'
                                }
                            </span>
                        </Card.Text>
                        <Card.Text className="col-4">Partita IVA: {currentStudent.VAT_number || 'nessuna'}</Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Header><span className="fw-600">Informazioni personali</span></Card.Header>
                    <Card.Body className="d-flex flex-wrap">
                        <Card.Text className="col-4">Codice Fiscale: {currentStudent.tax_code || '-'}</Card.Text>
                        <Card.Text className="col-4">Data di nascita: {currentStudent.birth_date && currentStudent.birth_date.date ? format(new Date(currentStudent.birth_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                        <Card.Text className="col-4">Luogo di nascita: {currentStudent.birth_place || '-'}</Card.Text>
                        <Card.Text className="col-4">Nazionalità: {currentStudent.nationality || '-'}</Card.Text>
                        <Card.Text className="col-4">Genere: {currentStudent.gender === 'F' ? 'Femmina' : 'Maschio'}</Card.Text>
                        <Card.Text className="col-4">Città: {currentStudent.city || '-'}</Card.Text>
                        <Card.Text className="col-4">CAP: {currentStudent.zip || '-'}</Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Header><span className="fw-600">Note</span></Card.Header>
                    <Card.Body className="d-flex flex-wrap">
                        <Card.Text className="col-4">
                            {currentStudent.note || '-'}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
        <StudentInternal student={currentStudent} reload={props.reload} />
        <StudentFile studentId={currentStudent.id}></StudentFile>
    </div> : (showError ? <p className="mt-2">Nessun dato disponibile</p> : <></>)
}

export default DetailsStudent;