import { FC, useEffect, useState } from 'react';
import { MUIDataTableMeta } from 'mui-datatables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ISource from '../../../interfaces/Source';
import sourcesService from '../../../services/SourcesService';
import SourcesColumns from '../../../config/tableColumns/SourcesColumns';
import SourcesModal from './SourcesModal';

const Sources: FC<{}> = () => {
    const [sources, setSources] = useState<ISource[]>([]);
    const [currentSource, setCurrentSource] = useState<ISource | undefined>(undefined)
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [currentId, setCurrentId] = useState<number | undefined>(undefined);
    const [action, setAction] = useState('');

    const getAllSources = () => sourcesService.getAll().then(res => setSources(res))

    useEffect(() => {
        getAllSources()
    }, []);

    useEffect(() => {
        if (refresh === true) {
            getAllSources();
            setRefresh(false);
        }
    }, [refresh]);

    const onEditClick = (tableMeta: MUIDataTableMeta<ISource>) => {
        if (tableMeta.rowData[0]) {
            sourcesService.get(tableMeta.rowData[0])
                .then(
                    res => {
                        setAction('edit')
                        setCurrentId(tableMeta.rowData[0])
                        setCurrentSource(res)
                        setShowModal(true);
                    }
                )
        }
    }

    const onDeleteClick = (tableMeta: MUIDataTableMeta<ISource>) => {
        if (tableMeta.rowData[0]) {
            setAction('delete')
            setCurrentId(tableMeta.rowData[0])
            setShowModal(true);
        }
    }

    return (
        <div className="container-fluid">
            <SourcesModal
                source={currentSource}
                id={currentId}
                show={showModal}
                close={(action?: string) => {
                    setShowModal(false);
                    setAction('')
                    setCurrentSource(undefined);
                    setCurrentId(undefined);
                    if (typeof action === 'string')
                        setRefresh(true);
                }}
                action={action}
            />
            <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="mb-0">Sorgenti</h4>
                <button className='btn btn-success rounded-pill' onClick={() => { setShowModal(true); setAction('add') }}><FontAwesomeIcon icon={faPlus} className="me-1" />Crea sorgente</button>
            </div>
            <SourcesColumns sources={sources} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
        </div>
    )
}

export default Sources;