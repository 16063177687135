import React, { FC, useEffect, useState } from 'react';
import { MUIDataTableMeta } from 'mui-datatables';
import IFund from '../../../interfaces/Fund'
import FundsColumns from '../../../config/tableColumns/FundsColumns';
import FundsModal from './FundsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import fundsService from '../../../services/FundsService';
import { useSearchParams } from 'react-router-dom';

const Funds: FC<{}> = () => {

  const [searchParams] = useSearchParams()
  const [filter, setFilter] = useState("All");

  const [funds, setFunds] = useState<IFund[]>([]);
  const [currentFund, setCurrentFund] = useState<IFund | undefined>(undefined)
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const getAllFunds = () => fundsService.getAll().then(res => setFunds(res))

  useEffect(() => {
    getAllFunds()
  }, []);

  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams);
    setFilter(Object.values(currentParams)[0]);
  }, [searchParams]);

  useEffect(() => {
    if (refresh === true) {
      getAllFunds();
      setRefresh(false);
    }
  }, [refresh]);

  const onEditClick = (tableMeta: MUIDataTableMeta<IFund>) => {
    if (tableMeta.rowData[0]) {
      fundsService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentId(tableMeta.rowData[0])
            setCurrentFund(res)
            setShowModal(true);
          }
        )
    }
  }

  const onDeleteClick = (tableMeta: MUIDataTableMeta<IFund>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0])
      setShowModal(true);
    }
  }

  return (
    <div className="container-fluid">
      <FundsModal
        fund={currentFund}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction('')
          setCurrentFund(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Fondi formativi</h4>
        <button className='btn btn-success rounded-pill' onClick={() => { setShowModal(true); setAction('add') }}><FontAwesomeIcon icon={faPlus} className="me-1" />Crea fondo</button>
      </div>
      <FundsColumns searchParam={filter} funds={funds} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
    </div>
  )
}

export default Funds;