import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MUIDataTableMeta } from 'mui-datatables';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import RealCoursesColumns from '../../../config/tableColumns/RealCoursesColumns';
import { IRealCourse, ITableRealCourse } from '../../../interfaces/RealCourse';
import genericCoursesService from '../../../services/GenericCoursesService';
import realCoursesService from '../../../services/RealCoursesService';
import teachersService from '../../../services/TeachersService';
import fundsService from '../../../services/FundsService';
import platformsService from '../../../services/PlatformsService';
import courseTypesService from '../../../services/CourseTypesService';
import RealCoursesModal from './RealCourseModal/RealCoursesModal';
import { useSearchParams } from 'react-router-dom';

interface IRealCoursesProps {
  period?: 'past' | 'now' | 'future',
  dashboardRealCourses?: IRealCourse[]
  isUpdating?: (update: boolean) => void
}

const RealCourses: FC<IRealCoursesProps> = (props) => {

  const [searchParams] = useSearchParams()
  const [filter, setFilter] = useState("All");

  const [realCourses, setRealCourses] = useState<ITableRealCourse[]>([]);
  const [platforms, setPlatforms] = useState<{ value: number, label: string }[]>([]);
  const [courseTypes, setCourseTypes] = useState<{ value: number, label: string }[]>([]);
  const [genericCourses, setGenericCourses] = useState<{ value: number, label: string }[]>([]);
  const [funds, setFunds] = useState<{ value: number, label: string }[]>([]);
  const [teachers, setTeachers] = useState<{ value: number, label: string }[]>([])
  const [currentRealCourse, setCurrentRealCourse] = useState<IRealCourse | undefined>(undefined);
  const [refresh, setRefresh] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const { period, dashboardRealCourses, isUpdating } = props

  // checking if the real courses are in the dashboard page (from props) or in the real courses' page
  const getAllCourses = (period?: string, dashboardRealCourses?: IRealCourse[]) => {
    if (dashboardRealCourses) {
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      const now = today.getTime()
      switch (period) {
        case 'future':
          let futureCourses: IRealCourse[] = []
          dashboardRealCourses.forEach(course => {
            if (new Date(course.start_date.date).getTime() > now)
              futureCourses.push(course)
          })
          setRealCourses(realCoursesService.mapDataForTable(futureCourses))
          break;
        case 'now':
          let activeCourses: IRealCourse[] = []
          dashboardRealCourses.forEach(course => {
            if (new Date(course.start_date.date).getTime() < now && course.end_date && new Date(course.end_date.date).getTime() >= now)
              activeCourses.push(course)
          })
          setRealCourses(realCoursesService.mapDataForTable(activeCourses))
          break;
        case 'past':
          let pastCourses: IRealCourse[] = []
          dashboardRealCourses.forEach(course => {
            if (course.end_date && new Date(course.end_date.date).getTime() < now)
              pastCourses.push(course)
          })
          setRealCourses(realCoursesService.mapDataForTable(pastCourses))
          break;
        default:
          setRealCourses(realCoursesService.mapDataForTable(dashboardRealCourses))
          break;
      }
    } else {
      realCoursesService.getAllForTable().then(res => setRealCourses(res))
    }
  }

  // setting search keys if they exists in the router url
  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams);
    setFilter(Object.values(currentParams)[0]);
  }, [searchParams]);

  // setting the real courses on initialization
  useEffect(() => getAllCourses(period, dashboardRealCourses), [period, dashboardRealCourses]);

  // refreshing data after a certain action
  useEffect(() => {
    if (refresh === true) {
      getAllCourses(period, dashboardRealCourses);
      setRefresh(false);
    }
  }, [refresh, period, dashboardRealCourses]);

  // setting all select menus data and opening the modal
  const getAllSelectData = () => {
    Promise.all([
      courseTypesService.getAll(),
      platformsService.getAll(),
      genericCoursesService.getAll(),
      fundsService.getAll(),
      teachersService.getAllForMenu()
    ]).then(
      res => {
        setCourseTypes(res[0].map(tipologia => { return { value: tipologia.id!, label: tipologia.name } }))
        setPlatforms(res[1].map(piattaforma => { return { value: piattaforma.id!, label: piattaforma.name } }))
        setGenericCourses(res[2].map(corso => { return { value: corso.id!, label: corso.name } }))
        setFunds(res[3].map(fondo => { return { value: fondo.id!, label: fondo.name } }))
        setTeachers(res[4].map(docente => { return { value: docente.id, label: docente.last_name + ' ' + (docente.middle_name || '') + docente.first_name } }))
        setShowModal(true);
      }
    )
  }

  // getting tabs' titles
  const getTitle = () => {
    switch (period) {
      case 'future':
        return 'Corsi futuri'
      case 'now':
        return 'Corsi attivi'
      case 'past':
        return 'Corsi passati'
      default:
        return 'Corsi reali'
    }
  }

  // handling delete button click event
  const onDeleteClick = (tableMeta: MUIDataTableMeta<ITableRealCourse>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0]);
      setShowModal(true);
    }
  }

  // handling edit button click event
  const onEditClick = (tableMeta: MUIDataTableMeta<ITableRealCourse>) => {
    if (tableMeta.rowData[0]) {
      realCoursesService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentRealCourse(res);
            setCurrentId(tableMeta.rowData[0]);
            getAllSelectData();
          }
        )
    }
  }

  // handling refresh button click event
  const onRefreshClick = (tableMeta: MUIDataTableMeta<ITableRealCourse>) => {
    if (tableMeta.rowData[0]) {
      realCoursesService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('refresh')
            setCurrentRealCourse(res);
            setCurrentId(tableMeta.rowData[0]);
            getAllSelectData();
          }
        )
    }
  }

  // handling add button click event
  const onAddClick = () => {
    setAction('add');
    getAllSelectData();
  }

  return (
    <div className="container-fluid">
      <RealCoursesModal
        realCourse={currentRealCourse}
        allSelectData={{ funds, platforms, courseTypes, genericCourses, teachers }}
        id={currentId}
        show={showModal}
        close={(action?: SyntheticEvent | string) => {
          setShowModal(false);
          setAction('')
          setCurrentRealCourse(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            if (period && isUpdating) {
              isUpdating(true);
            } else {
              setRefresh(true);
            }
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">{getTitle()}</h4>
        <button className='btn btn-success rounded-pill' onClick={onAddClick}>
          <FontAwesomeIcon icon={faPlus} className="me-1" />Crea corso reale
        </button>
      </div>
      <RealCoursesColumns
        searchParam={filter}
        realCourses={realCourses}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        onRefreshClick={onRefreshClick}
        period={period}
      />
    </div>
  )
};

export default RealCourses;
