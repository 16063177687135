import { FC, useEffect, useState } from 'react';
import { MUIDataTableMeta } from "mui-datatables";
import GenericCoursesColumns from '../../../config/tableColumns/GenericCoursesColumns';
import GenericCoursesModal from './GenericCoursesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import genericCoursesService from '../../../services/GenericCoursesService';
import { SyntheticEvent } from 'react-toastify/dist/utils';
import { useSearchParams } from 'react-router-dom';
import fileService from '../../../services/FileService';
import { IGenericCourse, ITableGenericCourse } from '../../../interfaces/GenericCourse';
import { IFile } from '../../../interfaces/File';

const GenericCourses: FC<{}> = () => {

  const [searchParams] = useSearchParams()
  const [filter, setFilter] = useState("All");

  const [genericCourses, setGenericCourses] = useState<ITableGenericCourse[]>([]);
  const [currentGenericCourse, setCurrentGenericCourse] = useState<IGenericCourse | undefined>(undefined);
  const [refresh, setRefresh] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [fileId, setFileId] = useState<number | undefined>(undefined)
  const [action, setAction] = useState('');

  const getAllCourses = () => { genericCoursesService.getAll().then(res => setGenericCourses(res.map(course => { return { ...course, duration: course.duration + ' ore' } }))) }

  useEffect(() => getAllCourses(), []);

  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams);
    setFilter(Object.values(currentParams)[0]);
  }, [searchParams]);

  useEffect(() => {
    if (refresh === true) {
      getAllCourses();
      setRefresh(false);
    }
  }, [refresh]);

  const onDeleteClick = (tableMeta: MUIDataTableMeta<IGenericCourse>) => {
    if (tableMeta.rowData[0]) {
      genericCoursesService.get(tableMeta.rowData[0]).then(
        res => {
          setAction('delete')
          setCurrentId(res.id);
          setFileId(res.schedule.id)
          setShowModal(true);
        }
      )
    }
  }

  const onEditClick = (tableMeta: MUIDataTableMeta<IGenericCourse>) => {
    if (tableMeta.rowData[0]) {
      genericCoursesService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentGenericCourse(res);
            setCurrentId(tableMeta.rowData[0]);
            setShowModal(true);
          }
        )
    }
  }

  const onPreviewFile = (file?: IFile) => {
    if (file)
      fileService.preview(file.id)
  }

  const onDownloadFile = (file?: IFile) => {
    if (file)
      fileService.download(file.id)
  }

  return (
    <div className="container-fluid">
      <GenericCoursesModal
        fileId={fileId}
        genericCourse={currentGenericCourse}
        id={currentId}
        show={showModal}
        close={(action?: SyntheticEvent | string) => {
          setShowModal(false);
          setAction('')
          setCurrentGenericCourse(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Corsi generici</h4>
        <button className='btn btn-success rounded-pill' onClick={() => { setShowModal(true); setAction('add') }}>
          <FontAwesomeIcon icon={faPlus} className="me-1" />Crea corso generico
        </button>
      </div>
      <GenericCoursesColumns
        searchParam={filter}
        genericCourses={genericCourses}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        onPreviewFile={onPreviewFile}
        onDownloadFile={onDownloadFile}
      />
    </div>
  )
}

export default GenericCourses;
