import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MUIDataTableColumn, MUIDataTableMeta } from "mui-datatables";
import { Button } from "react-bootstrap";
import CustomTable from "../../common/components/CustomTable/CustomTable";
import { IEmplyomentAgency } from "../../interfaces/EmploymentAgency";

const EmploymentAgenciesColumns = (props: {
    onEditClick: (tableMeta: MUIDataTableMeta<IEmplyomentAgency>) => void,
    onDeleteClick: (tableMeta: MUIDataTableMeta<IEmplyomentAgency>) => void,
    agencies: IEmplyomentAgency[]
}) => {

    const { agencies, onEditClick, onDeleteClick } = props

    const agenciesColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "name",
            label: "Nome"
        },
        {
            name: "code",
            label: "Codice",
            options: {
                filterList: []
            }
        },
        {
            name: "training_fee",
            label: "Compenso formazione (€)"
        },
        {
            name: "azioni",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IEmplyomentAgency>, updateValue: any) => (
                    <>
                        <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
                        <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>
                )
            }
        },
    ]

    return (
        <CustomTable
            title={"Elenco di tutte le agenzie disponibili"}
            data={agencies}
            columns={agenciesColumns}
        />
    )
}

export default EmploymentAgenciesColumns;