import axios from "axios"
import { IClassroom, IClassroomBody, IClassroomsResponse, ITableClassroom } from "../interfaces/Classroom";

class ClassroomsService {

    private baseUrl = process.env.REACT_APP_API_URL

    mapDataForTable = (classrooms: IClassroom[]): ITableClassroom[] => {
        return classrooms.map(
            classroom => {
                return {
                    id: classroom.id,
                    name: classroom.name,
                    course_name: classroom.course_real.name,
                    members: classroom.has_teachers.length + ' docent' +
                        (classroom.has_teachers.length !== 1 ? 'i, ' : 'e, ') +
                        classroom.has_students.length + ' student' +
                        (classroom.has_students.length !== 1 ? 'i' : 'e')
                }
            }
        )
    }

    async getAllForTable(): Promise<ITableClassroom[]> {
        return await axios.get<IClassroomsResponse>(this.baseUrl + '/classrooms').then(response => this.mapDataForTable(response.data.data))
    }

    async getAll(): Promise<IClassroom[]> {
        return await axios.get<IClassroomsResponse>(this.baseUrl + "/classrooms").then((response) => response.data.data)
    }

    async get(id: number): Promise<IClassroom> {
        return await axios.get(this.baseUrl + "/classroom/" + id).then(response => response.data.data)
    }

    async post(body: IClassroomBody): Promise<IClassroom> {
        return await axios.post(this.baseUrl + '/classroom', body).then(response => response.data.data)
    }

    async put(id: number, body: IClassroomBody): Promise<IClassroom> {
        return await axios.put(this.baseUrl + '/classroom/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/classroom/' + id).then(response => response.data.data)
    }
}

const classroomsService = new ClassroomsService();

export default classroomsService;