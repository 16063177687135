import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ITeacher } from '../../interfaces/Teacher';
import { Rating, TableCell, TableRow } from '@mui/material';
import DetailsTeacher from '../../components/Teachers/DetailsTeacher';

const TeachersColumns = (props: {
  onEditClick: (tableMeta: MUIDataTableMeta<ITeacher>) => void,
  onDeleteClick: (tableMeta: MUIDataTableMeta<ITeacher>) => void,
  teachers: ITeacher[]
}) => {
  const { teachers, onEditClick, onDeleteClick } = props

  const teachersColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false
      }
    },
    {
      name: "first_name",
      label: "Nome"
    },
    {
      name: "activity",
      label: "Attività"
    },
    {
      name: "hourly_fee",
      label: "C. orario (€)"
    },
    {
      name: "assessment_course",
      label: "Valutazione HR",
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ITeacher>, updateValue: any) => <Rating className="ms-2" name="read-only" value={value} readOnly />
      }
    },
    {
      name: "VAT_number",
      label: "P. IVA"
    },
    {
      name: "qualification",
      label: "Qualifica"
    },
    {
      name: "actions",
      label: "Azioni",
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ITeacher>, updateValue: any) => (
          <div className='d-flex'>
            <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
            <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
          </div>
        )
      }
    }
  ]

  const renderTeacherDetails = (rowData: string[], rowMeta: {
    dataIndex: number;
    rowIndex: number;
  }) => {
    const colSpan = rowData.length + 1;

    return <TableRow>
      <TableCell colSpan={colSpan}>
        <DetailsTeacher id={Number(rowData[0])} />
      </TableCell>
    </TableRow>
  }

  return <CustomTable
    title={"Elenco di tutti i docenti disponibili"}
    data={teachers}
    columns={teachersColumns}
    options={{
      sortOrder: {
        name: "first_name",
        direction: "asc"
      },
      expandableRows: true,
      renderExpandableRow: renderTeacherDetails
    }}
  />
}

export default TeachersColumns;