import axios from "axios"
import Piattaforma from "../interfaces/Platform"

class PlatformsService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<Piattaforma[]> {
        return await axios.get(this.baseUrl + "/course/platforms").then(response => response.data.data)
    }

    async get(id: number): Promise<Piattaforma> {
        return await axios.get(this.baseUrl + "/course/platform/" + id).then(response => response.data.data)
    }

    async post(body: Piattaforma): Promise<Piattaforma> {
        return await axios.post(this.baseUrl + '/course/platform', body).then(response => response.data.data)
    }

    async put(id: number, body: Piattaforma): Promise<Piattaforma> {
        return await axios.put(this.baseUrl + '/course/platform/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/course/platform/' + id).then(response => response.data.data)
    }
}

const platformsService = new PlatformsService();

export default platformsService;