import { GanttProps, Task, ViewMode } from "gantt-task-react";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Select from "react-select";
import CustomCalendar from "../../common/components/CustomCalendar/CustomCalendar";
import { generateTasksFromEvents, groupEventsByDate } from "../../common/config/configEvents";
import { ICustomCalendarProps } from "../../common/interfaces/CustomCalendar";
import { ICustomViewSwitchProps } from "../../common/interfaces/CustomViewSwitch";
import { ICalendarEvent } from "../../interfaces/Calendar";
import { IRealCourse } from "../../interfaces/RealCourse";
import { ITeacherMenu } from "../../interfaces/Teacher";
import calendarService from "../../services/CalendarService";
import teachersService from "../../services/TeachersService";
import RealCourseCalendarModal from "../Courses/RealCourses/RealCourseCalendar/RealCourseCalendarModal";
import CustomPagination, { paginate } from "../../common/components/CustomPagination/CustomPagination";
import { convertDateToCustomFormat } from "../../common/config/configDate";

const TeacherOverview: FC<{}> = () => {

    // generic data
    const [calendarEventsForTeacher, setCalendarEventsForTeacher] = useState<ICalendarEvent[]>([])
    const [groupedEvents, setGroupedEvents] = useState<ICalendarEvent[]>([]);
    const [realCourses, setRealCourses] = useState<IRealCourse[]>([])
    const [teachers, setTeachers] = useState<ITeacherMenu[]>([])

    // select menu
    const [selectedTeacher, setSelectedTeacher] = useState<{ value: number, label: string } | undefined>()
    const [optionsTeachers, setOptionsTeachers] = useState<{ value: number, label: string }[]>([])

    // gantt
    const [tasksForTeacher, setTasksForTeacher] = useState<Task[]>([])
    const [viewForTeacher, setViewForTeacher] = useState(ViewMode.Month);
    const [columnWidthForTeacher, setColumnWidthForTeacher] = useState(200);
    const [isCheckedTeacher, setIsCheckedTeacher] = useState(true);

    // modal
    const [currentRealCourse, setCurrentRealCourse] = useState<IRealCourse | undefined>(undefined)
    const [currentEvents, setCurrentEvents] = useState<ICalendarEvent[]>([]);
    const [currentIds, setCurrentIds] = useState<string[]>([]);
    const [refresh, setRefresh] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState('');

    // pagination
    const [active, setActive] = useState<number>(1)
    const [shownItems, setShownItems] = useState<number>(10)
    const [hideChildren, setHideChildren] = useState<{ courseId: number, hideChildren: boolean }[]>([])

    useEffect(() => {
        setRefresh(true)
        teachersService.getAllForMenu().then(
            response => {
                setTeachers(response)
                setOptionsTeachers(response.map(teacher => { return { value: teacher.id, label: teacher.last_name + ' ' + (teacher.middle_name || '') + teacher.first_name } }))
            }
        )
    }, [])

    // getting all real courses and their events/lessons based on the selected teacher
    const getAllData = (selectedTeacher?: { value: number, label: string }) => {
        if (selectedTeacher) {
            teachersService.get(selectedTeacher.value).then(
                async teacher => {
                    setCalendarEventsForTeacher(teacher.calendar.map(event => { return { ...event, teacher } }))

                    let realCoursesFromTeacher = teacher.calendar.filter((event, index, self) =>
                        index === self.findIndex((e) => (
                            e.course_real && event.course_real && e.course_real.id === event.course_real.id
                        ))
                    ).map(event => event.course_real!)
                    let allEvents: ICalendarEvent[] = []
                    let hideChildrenCourses: { courseId: number, hideChildren: boolean }[] = []
                    let saveCourses: IRealCourse[] = []

                    realCoursesFromTeacher.forEach(realCourse => {
                        if (realCourse) {
                            saveCourses.push({ ...realCourse, details: realCourse.details, teacher })
                            hideChildrenCourses.push({ courseId: realCourse.id, hideChildren: true })
                            let events = teacher.calendar.filter(event => event.course_real?.id === realCourse.id).map(event => { return { ...event, teacher } })
                            let grouped = groupEventsByDate(events)
                            allEvents = [...allEvents, ...grouped]
                        }
                    })
                    setRealCourses(saveCourses)
                    setHideChildren(hideChildrenCourses)
                    setGroupedEvents(allEvents)
                    setRefresh(false)
                }
            )
        }
    }

    // setting events as tasks to set the gantt chart
    useEffect(() => {
        let allTasks = realCourses.map(course => {
            let filteredTasks = groupedEvents.filter(event => event.course_real?.id === course.id)
            return generateTasksFromEvents(filteredTasks, course)
        })

        let saveTasks: Task[] = []
        allTasks.forEach(tasks => { saveTasks = saveTasks.concat(tasks) })
        saveTasks = saveTasks.filter(t => t.type !== 'project').sort((t1, t2) => t1.start.getTime() - t2.start.getTime())
        let currentTask: Task | undefined = undefined
        saveTasks = paginate(saveTasks, shownItems, active).map(task => {
            let newTask: Task = { ...task, styles: { ...task.styles, backgroundColor: currentTask ? (convertDateToCustomFormat(currentTask.start) !== convertDateToCustomFormat(task.start) ? "#02679a" : "#ef3a3a") : "#02679a" } }
            currentTask = task
            return newTask
        })

        setTasksForTeacher(saveTasks)
    }, [active, shownItems, groupedEvents, realCourses, hideChildren])

    // if refresh then rebuild all
    useEffect(() => {
        if (refresh === true) {
            getAllData(selectedTeacher)
        }
    }, [refresh, selectedTeacher]);

    useEffect(() => {
        if (selectedTeacher) {
            setRefresh(true)
        }
    }, [selectedTeacher]);

    // expanding a real course if selected and showing all their events/lessons as tasks
    const handleExpanderClickTeacher = (task: Task) => {
        setTasksForTeacher(tasksForTeacher.map((t) => (t.id === task.id ? task : t)));
        const splitted = task.id.split('-')
        const taskId = splitted[splitted.length - 1]
        let changeHideChildren = hideChildren.find(ex => ex.courseId === Number(taskId))
        if (changeHideChildren) {
            const newHideChildren = [...hideChildren]
            newHideChildren.splice(newHideChildren.indexOf(changeHideChildren), 1, { ...changeHideChildren, hideChildren: !changeHideChildren.hideChildren })
            setHideChildren(newHideChildren)
        }
    }

    // handling edit or delete action on an event/lesson
    const onOptionClick = (action: string, taskId: string) => {
        if (selectedTeacher) {
            const splittedId = taskId.split('-')
            const eventIds = splittedId[splittedId.length - 1].split('+')
            setCurrentRealCourse(calendarEventsForTeacher.find(event => event.id.toString() === eventIds[eventIds.length - 1])?.course_real)
            const realCourseId = calendarEventsForTeacher.find(event => event.id.toString() === eventIds[eventIds.length - 1])?.course_real?.id
            if (realCourseId) {
                if (action === 'edit')
                    Promise.all(eventIds.map(eventId => calendarService.get(realCourseId, eventId))).then(
                        res => {
                            teachersService.getAllForMenu().then(
                                teachers => {
                                    setAction('edit')
                                    setTeachers(teachers);
                                    setCurrentEvents(res);
                                    setCurrentIds(eventIds);
                                    setShowModal(true);
                                }
                            )
                        }
                    )
                else {
                    setAction('delete')
                    setCurrentIds(eventIds);
                    setShowModal(true);
                }
            }
        }
    }

    // handling double click on calendar event
    const handleDoubleClick = (task: Task) => {
        const splittedId = task.id.split('-')
        const eventIds = splittedId[splittedId.length - 1].split('+')
        setCurrentRealCourse(calendarEventsForTeacher.find(event => event.id.toString() === eventIds[eventIds.length - 1])?.course_real)
        const realCourseId = calendarEventsForTeacher.find(event => event.id.toString() === eventIds[eventIds.length - 1])?.course_real?.id
        if (task.type !== 'project' && realCourseId)
            Promise.all(eventIds.map(eventId => calendarService.get(realCourseId, eventId))).then(
                res => {
                    teachersService.getAllForMenu().then(
                        teachers => {
                            setAction('edit')
                            setTeachers(teachers);
                            setCurrentEvents(res);
                            setCurrentIds(eventIds);
                            setShowModal(true);
                        }
                    )
                }
            )
    }

    // handling pagination change
    const onPaginationChange = (active: number, shownItems: number) => {
        setActive(active)
        setShownItems(shownItems)
    }

    // setting gantt props
    const allCalendarPropsTeacher: ICustomCalendarProps & GanttProps = {
        realCourses,
        onOptionClick,
        calendarEvents: calendarEventsForTeacher,
        viewTasks: isCheckedTeacher,
        tasks: tasksForTeacher,
        viewMode: viewForTeacher,
        columnWidth: columnWidthForTeacher,
        onExpanderClick: handleExpanderClickTeacher,
        onDoubleClick: handleDoubleClick
    }

    const allViewSwitchPropsTeacher: ICustomViewSwitchProps = {
        setIsChecked: setIsCheckedTeacher,
        setView: setViewForTeacher,
        setColumnWidth: setColumnWidthForTeacher,
        isChecked: isCheckedTeacher,
        showTasksLabel: 'Mostra i dettagli dei corsi',
        view: viewForTeacher
    }

    return <div className="container-fluid">
        <h5 className="mb-3">Seleziona un docente</h5>
        <Select
            placeholder={"Seleziona un docente"}
            name="selectedTeacher"
            value={optionsTeachers.find(option => option.value === selectedTeacher?.value)}
            onChange={option => setSelectedTeacher(option || undefined)}
            options={optionsTeachers}
        />
        <RealCourseCalendarModal
            events={currentEvents}
            realCourse={currentRealCourse}
            ids={currentIds}
            teachers={teachers}
            show={showModal}
            close={(action?: SyntheticEvent | string) => {
                setShowModal(false);
                setAction('')
                setCurrentEvents([]);
                setCurrentIds([]);
                if (typeof action === 'string') {
                    setRefresh(true);
                }
            }}
            action={action}
        />
        {
            selectedTeacher && tasksForTeacher.length > 0 ? <Card className="mt-4 shadow-sm">
                <Card.Header>
                    <span className="fw-600">Lista degli orari dei corsi di {selectedTeacher.label}</span>
                </Card.Header>
                <Card.Body className="w-100">
                    <CustomCalendar
                        {...allCalendarPropsTeacher}
                        {...allViewSwitchPropsTeacher}
                    ></CustomCalendar>
                    {groupedEvents.length >= shownItems && <CustomPagination
                        active={active}
                        shownItems={shownItems}
                        sourceItems={groupedEvents}
                        onPaginationChange={(event, active, shownItems) => onPaginationChange(active, shownItems)}
                    />}
                </Card.Body>
            </Card> : <p className="my-3">Non ci sono calendari da mostrare</p>
        }
    </div>
}

export default TeacherOverview;