import axios from "axios"
import IStudentStatus from "../interfaces/StudentStatus"

class StudentStatusesService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IStudentStatus[]> {
        return await axios.get(this.baseUrl + '/student/statuses').then(response => response.data.data)
    }

    async get(id: number): Promise<IStudentStatus> {
        return await axios.get(this.baseUrl + '/student/status/' + id).then(response => response.data.data)
    }

    async post(body: IStudentStatus): Promise<IStudentStatus> {
        return await axios.post(this.baseUrl + '/student/status', body).then(response => response.data.data)
    }

    async put(id: number, body: IStudentStatus): Promise<IStudentStatus> {
        return await axios.put(this.baseUrl + '/student/status/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/student/status/' + id).then(response => response.data.data)
    }
}

const studentStatusesService = new StudentStatusesService();

export default studentStatusesService;