interface IOption {
    value: number,
    label: string
}

export const setRealCoursesFields = (genericCourses: IOption[], funds: IOption[], platforms: IOption[], courseTypes: IOption[], teachers: IOption[]) => {
    return [
        {
            name: 'name',
            type: 'text',
            placeholder: 'Inserisci il nome del corso reale',
            label: 'Nome del corso reale'
        },
        {
            name: 'course',
            type: 'select',
            placeholder: 'Seleziona un corso',
            label: 'Corso generico',
            options: genericCourses
        },
        {
            name: 'fund',
            type: 'select',
            placeholder: 'Seleziona un fondo',
            label: 'Fondo',
            options: funds
        },
        {
            name: 'platform',
            type: 'select',
            placeholder: 'Seleziona una piattaforma',
            label: 'Piattaforma',
            options: platforms
        },
        {
            name: 'type',
            type: 'select',
            placeholder: 'Seleziona una tipologia',
            label: 'Tipologia di corso',
            options: courseTypes
        },
        {
            name: 'mode',
            type: 'select',
            placeholder: 'Seleziona una modalità',
            label: 'Modalità',
            options: [
                { value: 'C', label: "In presenza" },
                { value: 'M', label: "Ibrido" },
                { value: 'D', label: "A distanza" }
            ]
        },
        {
            name: 'teacher_id',
            type: 'select',
            placeholder: 'Seleziona un docente',
            label: 'Docente principale',
            options: teachers
        },
        {
            name: 'start_date',
            type: 'datepicker',
            placeholder: 'Inserisci la data di inizio',
            label: 'Data di inizio'
        },
        {
            name: 'is_internal',
            type: "select",
            options: [
                { value: 1, label: "Sì" },
                { value: 0, label: "No" }
            ],
            placeholder: "Seleziona un'opzione",
            label: "E' un corso interno?",
        },
        {
            name: 'max_students',
            type: 'number',
            placeholder: 'Inserisci il numero massimo',
            label: 'Numero massimo di studenti'
        },
        {
            name: 'details',
            type: 'textarea',
            placeholder: 'Inserisci eventuali dettagli',
            label: 'Dettagli'
        },
    ]
}