import { SingleValue } from "react-select"

export const setClassroomFields = (
    realCourses: { value: number, label: string }[],
    teachers: { value: number, label: string }[],
    students: { value: number, label: string }[],
    onRealCourseChange: (option: SingleValue<string | {
        value: string | number;
        label: string;
    }>) => void
) => {
    return [
        {
            name: 'name',
            type: 'text',
            placeholder: "Inserisci il nome dell'aula",
            label: 'Nome aula',
        },
        {
            name: 'course_real_id',
            type: 'select',
            options: realCourses,
            placeholder: 'Seleziona il corso reale',
            label: 'Corso reale',
            onChange: onRealCourseChange
        },
        {
            name: 'students',
            type: 'multi-select',
            options: students,
            placeholder: 'Seleziona gli studenti',
            label: 'Studenti',
        },
        {
            name: 'teachers',
            type: 'multi-select',
            options: teachers,
            placeholder: 'Seleziona i docenti',
            label: 'Docenti',
        },
        {
            name: 'note',
            type: 'textarea',
            placeholder: "Inserisci eventuali note",
            label: 'Note',
        },
    ]
}