import axios from "axios"
import { IGenericCourse, IGenericCourseBody } from "../interfaces/GenericCourse"

class GenericCoursesService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IGenericCourse[]> {
        return await axios.get(this.baseUrl + "/courses").then(response => response.data.data)
    }

    async get(id: number): Promise<IGenericCourse> {
        return await axios.get(this.baseUrl + "/course/" + id).then(response => response.data.data)
    }

    async post(body: IGenericCourseBody): Promise<IGenericCourse> {
        return await axios.post(this.baseUrl + '/course', body).then(response => response.data.data)
    }

    async put(id: number, body: IGenericCourseBody): Promise<IGenericCourse> {
        return await axios.put(this.baseUrl + '/course/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/course/' + id).then(() => id)
    }
}

const genericCoursesService = new GenericCoursesService();

export default genericCoursesService;