import { FC } from "react"
import { ICalendarEvent, ICalendarEventBody } from "../../../../interfaces/Calendar"
import calendarService from "../../../../services/CalendarService"
import { IModalProps } from "../../../../common/interfaces/CustomModal"
import { ICustomStepperFormProps } from "../../../../common/interfaces/CustomForm"
import CustomModal from "../../../../common/components/CustomModal/CustomModal"
import { getCalendarFields } from "../../../../config/formFields/CalendarFields"
import { ITeacherMenu } from "../../../../interfaces/Teacher"
import { IRealCourse } from "../../../../interfaces/RealCourse"
import { ICalendarEventStepperProps } from "../../../../interfaces/CalendarEventStepper"
import { checkIfMorning, checkIfAfternoon } from "../../../../common/config/configEvents"
import { format } from "date-fns"
import _ from "lodash"
import * as Yup from 'yup';

interface IRealCourseCalendarModalProps {
    events: ICalendarEvent[],
    realCourse: IRealCourse | undefined,
    ids: string[],
    teachers: ITeacherMenu[],
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const RealCourseCalendarModal: FC<IRealCourseCalendarModalProps> = (props) => {
    const { events, realCourse, ids, teachers, action, show, close } = props
    const realCourseId = realCourse?.id
    const morning = checkIfMorning(events)
    const afternoon = checkIfAfternoon(events)
    const steps = ['Mattino', 'Pomeriggio']

    const mappedTeachers = teachers.map(docente => { return { value: docente.id, label: docente.last_name + ' ' + (docente.middle_name || '') + docente.first_name } })

    const modifiedEventMorning = morning ? {
        ...morning,
        date: morning ? new Date(morning.start_date.date) : undefined,
        start_time: morning ? format(new Date(morning.start_date.date), "HH:mm") : undefined,
        end_time: morning ? format(new Date(morning.end_date.date), "HH:mm") : undefined,
        teacher_id: morning ? teachers.find(docente => docente.id === morning.teacher.id)?.id : undefined
    } : undefined

    const modifiedEventAfternoon = afternoon ? {
        ...afternoon,
        date: afternoon ? new Date(afternoon.start_date.date) : undefined,
        start_time: afternoon ? format(new Date(afternoon.start_date.date), "HH:mm") : undefined,
        end_time: afternoon ? format(new Date(afternoon.end_date.date), "HH:mm") : undefined,
        teacher_id: afternoon ? teachers.find(docente => docente.id === afternoon.teacher.id)?.id : undefined
    } : undefined

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        description: Yup.string().nullable(),
        date: Yup.string().required("* Campo obbligatorio"),
        start_time: Yup.string().required("* Campo obbligatorio"),
        end_time: Yup.string().required("* Campo obbligatorio"),
        note: Yup.string().nullable(),
        teacher_id: Yup.number().required("* Campo obbligatorio")
    })

    const setDateWithTime = (date: Date, time: string) => {
        const splittedTime = time.split(':')
        const dateWithTime = new Date(date)
        dateWithTime.setHours(Number(splittedTime[0]))
        dateWithTime.setMinutes(Number(splittedTime[1]))
        return format(dateWithTime, 'yyyy-MM-dd HH:mm')
    }

    const handleSubmit = async (values: { morning: (ICalendarEventBody & { date: Date, start_time: string, end_time: string }) | undefined, afternoon: (ICalendarEventBody & { date: Date, start_time: string, end_time: string }) | undefined }) => {
        let morningBody: ICalendarEventBody | undefined = undefined
        let afternoonBody: ICalendarEventBody | undefined = undefined

        // checking if morning exists
        if (values.morning)
            morningBody = {
                ...values.morning,
                start_date: setDateWithTime(values.morning.date, values.morning.start_time),
                end_date: setDateWithTime(values.morning.date, values.morning.end_time)
            }

        // checking if afternoon exists
        if (values.afternoon)
            afternoonBody = {
                ...values.afternoon,
                start_date: setDateWithTime(values.afternoon.date, values.afternoon.start_time),
                end_date: setDateWithTime(values.afternoon.date, values.afternoon.end_time)
            }

        // setting bodies and requests
        let bodies: (ICalendarEventBody | undefined)[] = [morningBody, afternoonBody]
        let requests: (() => Promise<ICalendarEvent>)[] = []

        // mapping bodies in order to set API calls based on morning and afternoon data
        bodies.forEach((body, index) => {
            if (body !== undefined) {
                let newBody = _.omit(body, ["date", "start_time", "end_time"]) as ICalendarEventBody
                if (action === 'edit' && realCourseId) {
                    let startTime = morning && morning.id === body.id ? morning.start_date.date.split(" ")[1] : (afternoon && afternoon.id === body.id ? afternoon.start_date.date.split(" ")[1] : undefined)
                    let endTime = morning && morning.id === body.id ? morning.end_date.date.split(" ")[1] : (afternoon && afternoon.id === body.id ? afternoon.end_date.date.split(" ")[1] : undefined)

                    let oldEvent: any = {}
                    oldEvent = morning && morning.id === body.id && startTime && endTime ? {
                        ...morning,
                        start_date: setDateWithTime(new Date(morning.start_date.date), startTime),
                        end_date: setDateWithTime(new Date(morning.start_date.date), endTime),
                        teacher_id: morning.teacher.id
                    } : (afternoon && afternoon.id === body.id && startTime && endTime ? {
                        ...afternoon,
                        start_date: setDateWithTime(new Date(afternoon.start_date.date), startTime),
                        end_date: setDateWithTime(new Date(afternoon.start_date.date), endTime),
                        teacher_id: afternoon.teacher.id
                    } : undefined)

                    let id = morning && index === 0 ? morning.id : (afternoon && index === 1 ? afternoon.id : (morning ? morning.id : (afternoon ? afternoon.id : 0)))

                    if (!_.isEqual(newBody, oldEvent)) {
                        requests.push(() => calendarService.put(realCourseId, id, newBody))
                    }
                }

                if (action === 'add' && realCourseId) {
                    requests.push(() => calendarService.post(realCourseId, newBody))
                }
            }
        })

        if (requests.length > 0 && action === 'add') {
            requests.map((request, index) => request().then((res) => {
                if (index === requests.length - 1)
                    close('modified')
            }))
        } else {
            close()
        }

        if (requests.length > 0 && action === 'edit') {
            Promise.all(requests.map(request => request())).then(res => {
                close('modified')
            })
        } else {
            close()
        }
    }

    // handling delete action
    const onConfirm = (selection?: string) => {
        if (selection && action === 'delete' && realCourseId)
            switch (selection) {
                case '0':
                    // delete only morning
                    calendarService.delete(realCourseId, Number(ids[0])).then(() => close('delete'))
                    break;
                case '1':
                    // delete only afternoon
                    calendarService.delete(realCourseId, Number(ids[1])).then(() => close('delete'))
                    break;
                default:
                    // delete both of them
                    Promise.all(ids.map(id => calendarService.delete(realCourseId, Number(id)))).then(() => close('delete'))
                    break;
            }
    }

    const modalProps: IModalProps = {
        close,
        show,
        confirm: (selection?: string) => onConfirm(selection),
        action,
        ids,
        label: morning && afternoon ? 'Modifica "' + morning.name + ' & ' + afternoon.name + '"' :
            (morning && !afternoon ? 'Modifica "' + morning.name + '"' : (!morning && afternoon ? 'Modifica "' + afternoon.name + '"' : 'Aggiungi un nuovo evento'))
    }

    const stepperFormProps: ICustomStepperFormProps = {
        stepperFields: { morning: getCalendarFields(mappedTeachers, 'morning'), afternoon: getCalendarFields(mappedTeachers, 'afternoon') },
        initialValues: { morning: modifiedEventMorning, afternoon: modifiedEventAfternoon },
        validationSchemas: { morning: validationSchema, afternoon: validationSchema },
        close,
        handleAllSubmits: (values) => handleSubmit(values),
        handleOneSubmit: (values) => handleSubmit(values)
    }

    const stepperProps: ICalendarEventStepperProps = {
        type: 'event',
        isModal: true,
        steps,
        close,
        stepperFormProps,
        eventAction: action
    }

    return <CustomModal modalProps={modalProps} stepperFormProps={stepperFormProps} stepperProps={stepperProps}></CustomModal>
}

export default RealCourseCalendarModal;