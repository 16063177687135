import { FC, useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import { IGetTeacher } from "../../interfaces/Teacher";
import teachersService from "../../services/TeachersService";
import Rating from '@mui/material/Rating';
import { format } from "date-fns";
import TeacherFile from "./TeacherFile/TeacherFile";

const DetailsTeacher: FC<{ id: number }> = (props) => {

    const [currentTeacher, setCurrentTeacher] = useState<IGetTeacher | undefined>(undefined)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        teachersService.get(props.id).then(res => setCurrentTeacher(res), error => setShowError(true))
    }, [props.id])

    return currentTeacher ? <div className="d-flex flex-column p-3">
        <div className="col-12 d-flex h-100">
            <Card className="col-3 shadow-sm">
                <Card.Header><span className="fw-600">Informazioni generali</span></Card.Header>
                <Card.Body>
                    <Card.Title className="mb-3">{currentTeacher.last_name + ' ' + (currentTeacher.middle_name || '') + ' ' + currentTeacher.first_name}</Card.Title>
                    <Card.Text>Email principale: {currentTeacher.email1 || '-'}</Card.Text>
                    <Card.Text>Email secondaria: {currentTeacher.email2 || '-'} </Card.Text>
                    <Card.Text>N° di cellulare: {currentTeacher.phone_cell || '-'}</Card.Text>
                    <Card.Text>N° di casa: {currentTeacher.phone_home || '-'}</Card.Text>
                    <Card.Text>N° di lavoro:{currentTeacher.phone_work || '-'}</Card.Text>
                </Card.Body>
            </Card>
            <div className="col-9 ps-3">
                <Card className="shadow-sm">
                    <Card.Header><span className="fw-600">Informazioni professionali</span></Card.Header>
                    <Card.Body className="d-flex flex-wrap">
                        <Card.Text className="col-4">Attività: {currentTeacher.activity || '-'}</Card.Text>
                        <Card.Text className="col-4">Titolo di studio: {currentTeacher.qualification.name || '-'}</Card.Text>
                        <Card.Text className="col-4 d-flex align-items-center">
                            Skills: {
                                currentTeacher.skills?.length !== 0 ? currentTeacher.skills?.map(
                                    (skill, index) => <Badge key={index + '-skill-' + skill} pill bg="secondary" className={index === 0 ? "ms-1" : "me-2"}>
                                        {skill}
                                    </Badge>
                                ) : '-'
                            }
                        </Card.Text>
                        <Card.Text className="col-4 d-flex align-items-center">Valutazione: {currentTeacher.assessment_selection} {currentTeacher && currentTeacher.assessment_course && <Rating className="ms-2" name="read-only" value={currentTeacher.assessment_course} readOnly />}</Card.Text>
                        <Card.Text className="col-4">Partita IVA: {currentTeacher.VAT_number || 'nessuna'}</Card.Text>
                        <Card.Text className="col-4">Compenso orario: {currentTeacher.hourly_fee + ' euro' || '-'}</Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mt-3 shadow-sm">
                    <Card.Header><span className="fw-600">Informazioni personali</span></Card.Header>
                    <Card.Body className="d-flex flex-wrap">
                        <Card.Text className="col-4">Codice Fiscale: {currentTeacher.tax_code || '-'}</Card.Text>
                        <Card.Text className="col-4">Data di nascita: {currentTeacher.birth_date && currentTeacher.birth_date.date ? format(new Date(currentTeacher.birth_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                        <Card.Text className="col-4">Luogo di nascita: {currentTeacher.birth_place || '-'}</Card.Text>
                        <Card.Text className="col-4">Nazionalità: {currentTeacher.nationality || '-'}</Card.Text>
                        <Card.Text className="col-4">Genere: {currentTeacher.gender === 'F' ? 'Femmina' : 'Maschio'}</Card.Text>
                        <Card.Text className="col-4">Città: {currentTeacher.city || '-'}</Card.Text>
                        <Card.Text className="col-4">CAP: {currentTeacher.zip || '-'}</Card.Text>
                    </Card.Body>
                </Card>
                <Card className="mt-3 shadow-sm">
                    <Card.Header><span className="fw-600">Note</span></Card.Header>
                    <Card.Body className="d-flex flex-wrap">
                        <Card.Text className="col-4">
                            {currentTeacher.note || '-'}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
        <TeacherFile teacherId={currentTeacher.id} />
    </div> : (showError ? <p className="mt-2">Nessun dato disponibile</p> : <></>)
}

export default DetailsTeacher;