import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import it from "date-fns/locale/it"
import { FC, useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import IContractType from "../../../interfaces/ContractType"
import { IGetStudent } from "../../../interfaces/Student"
import { IStudentInternal } from "../../../interfaces/StudentInternal"
import contractTypesService from "../../../services/ContractTypesService"
import studentInternalService from "../../../services/StudentInternalService"
import StudentInternalModal from "./StudentInternalModal"

const StudentInternal: FC<{ student: IGetStudent, reload?: (reload: boolean) => void }> = (props) => {
    const { student, reload } = props
    const [currentStudentInternal, setCurrentStudentInternal] = useState<IStudentInternal | undefined>(undefined)
    const [contractTypes, setContractTypes] = useState<IContractType[]>([])
    const [refresh, setRefresh] = useState(false);
    const [currentId, setCurrentId] = useState<number | undefined>(undefined);
    const [action, setAction] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);

    const getStudentInternalData = (studentId: number, id: number) => studentInternalService.get(studentId, id).then(
        res => setCurrentStudentInternal(res),
        error => setShowError(true)
    )

    useEffect(() => {
        if (student.id && student.student_internal)
            getStudentInternalData(student.id, student.student_internal.id)
    }, [student.id, student.student_internal])

    useEffect(() => {
        if (refresh === true && student.id) {
            if (reload)
                reload(true)
            setRefresh(false);
        }
    }, [refresh, reload, student.id]);


    const onAddClick = () => {
        contractTypesService.getAll().then(
            res => {
                setAction('add');
                setContractTypes(res);
                setShowModal(true);
            }
        )
    }

    const onEditClick = () => {
        if (currentStudentInternal)
            contractTypesService.getAll().then(
                res => {
                    setAction('edit');
                    setCurrentId(currentStudentInternal.id)
                    setContractTypes(res);
                    setShowModal(true);
                }
            )
    }

    const onDeleteClick = () => {
        if (currentStudentInternal) {
            setAction('delete')
            setCurrentId(currentStudentInternal.id)
            setShowModal(true);
        }
    }

    return <div className="pt-3">
        <StudentInternalModal
            data={{ student, studentInternal: currentStudentInternal, contractTypes }}
            id={currentId}
            show={showModal}
            close={(action?: string) => {
                setShowModal(false);
                setAction('')
                setCurrentStudentInternal(student.student_internal || undefined);
                setCurrentId(undefined);
                if (typeof action === 'string')
                    setRefresh(true);
            }}
            action={action}
        />
        <Card className="shadow-sm">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="fw-600">Informazioni interne</span>
                {
                    student.student_internal === null ?
                        <button className='btn btn-success rounded-pill d-flex align-items-center' onClick={onAddClick}><FontAwesomeIcon icon={faPlus} className="me-1" />Aggiungi</button>
                        : <div className="d-flex">
                            <button className='btn btn-primary rounded-pill d-flex align-items-center me-2' onClick={onEditClick}><FontAwesomeIcon icon={faPen} className="me-1" />Modifica</button>
                            <button className='btn btn-danger rounded-pill d-flex align-items-center' onClick={onDeleteClick}><FontAwesomeIcon icon={faTrash} className="me-1" />Elimina</button>
                        </div>
                }
            </Card.Header>
            {
                currentStudentInternal ? <Card.Body className="d-flex flex-wrap">
                    <Card.Text className="col-4">Tipo di contratto: {currentStudentInternal.contract_type.name}</Card.Text>
                    <Card.Text className="col-4">Data di inizio: {format(new Date(currentStudentInternal.start_date.date), "EEEE dd/MM/yyyy", { locale: it })}</Card.Text>
                    <Card.Text className="col-4">Data di fine: {format(new Date(currentStudentInternal.end_date.date), "EEEE dd/MM/yyyy", { locale: it })}</Card.Text>
                    <Card.Text className="col-4">Frequentazione ai corsi: {currentStudentInternal.hours} ore </Card.Text>
                    <Card.Text className="col-12">Note: {currentStudentInternal.note || '-'}</Card.Text>
                </Card.Body> : (showError ? <p className="p-3 mb-0">Nessun dato disponibile</p> : <></>)
            }
        </Card>
    </div>

}

export default StudentInternal;