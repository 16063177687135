import { FC, ChangeEvent, useState, Ref, useEffect } from "react"
import { ToggleButtonGroup, ToggleButton, Row, Col } from "react-bootstrap";
import { addDays, format, startOfWeek } from "date-fns";
import it from "date-fns/locale/it";
import Select from "react-select";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface IRealCourseGenerateCalendarProps {
    onSubmit: (values: (string | undefined)[][]) => void,
    formRef: Ref<FormikProps<any>>,
}

const RealCourseGenerateCalendar: FC<IRealCourseGenerateCalendarProps> = (props) => {
    const { onSubmit, formRef } = props

    // hours and days controllers for select menu
    const [dayOptionSelected, setDayOptionSelected] = useState<number | undefined>(undefined);
    const [hoursOptionSelected, setHoursOptionSelected] = useState<number | undefined>(undefined);
    const [daysChecked, setDaysChecked] = useState<number[]>([]);
    const [currentDay, setCurrentDay] = useState<number | undefined>(undefined);
    const [periodOptionSelected, setPeriodOptionSelected] = useState<number>(2);
    const [periodsOptionSelected, setPeriodsOptionSelected] = useState<number[]>(new Array(7).fill(2));

    // single time pickers' controllers for days with the same time
    const [morningStart, setMorningStart] = useState<string | undefined>("09:00");
    const [morningEnd, setMorningEnd] = useState<string | undefined>("13:00");
    const [afternoonStart, setAfternoonStart] = useState<string | undefined>("14:00");
    const [afternoonEnd, setAfternoonEnd] = useState<string | undefined>("16:00");

    // multiple time pickers' controllers for custom days
    const [customMorningStart, setCustomMorningStart] = useState<(string | undefined)[]>(new Array(7).fill("09:00"));
    const [customMorningEnd, setCustomMorningEnd] = useState<(string | undefined)[]>(new Array(7).fill("13:00"));
    const [customAfternoonStart, setCustomAfternoonStart] = useState<(string | undefined)[]>(new Array(7).fill("14:00"));
    const [customAfternoonEnd, setCustomAfternoonEnd] = useState<(string | undefined)[]>(new Array(7).fill("16:00"));

    // setting hours' data
    const [hours, setHours] = useState<(string | undefined)[][]>(Array.from({ length: 7 }, () => ["09:00-13:00", "14:00-16:00"]))

    // initializing the days of the week
    const firstDayOfWeek = startOfWeek(new Date())
    const weekdays = Array.from(Array(7)).map((e, i) => format(addDays(firstDayOfWeek, i), 'EEEEEE', { locale: it }))

    // setting days options for the days' select menu
    const daysOptions = [
        {
            label: "Tutti i giorni",
            value: 0
        },
        {
            label: "Dal lun al ven",
            value: 1
        },
        {
            label: "Personalizza i giorni",
            value: 2
        }
    ]

    // setting hours options for the hours' select menu
    const hoursOptions = [
        {
            label: "Stesso orario per ogni giorno selezionato",
            value: 0
        },
        {
            label: "Personalizza gli orari",
            value: 1
        }
    ]

    // morning time picker's props
    const morningProps = {
        min: "00:00",
        max: "13:00",
        step: '900',
        type: 'time',
        required: true
    }

    // afternoon time picker's props
    const afternoonProps = {
        min: "13:00",
        max: "23:45",
        step: '900',
        type: 'time',
        required: true
    }

    useEffect(() => {
        switch (dayOptionSelected) {
            // all days
            case 0: {
                if (hoursOptionSelected === 0)
                    setHours(Array.from({ length: 7 }, () => [morningStart + '-' + morningEnd, afternoonStart + "-" + afternoonEnd]))
                if (hoursOptionSelected === 1)
                    setHours((hours) => [...hours.map((hour, index) => [customMorningStart[index] + '-' + customMorningEnd[index], customAfternoonStart[index] + "-" + customAfternoonEnd[index]])])
                break;
            }
            // from monday to friday
            case 1: {
                let newHours: string[][] = Array.from({ length: 5 }, () => [])
                if (hoursOptionSelected === 0)
                    newHours = newHours.map(() => [morningStart + '-' + morningEnd, afternoonStart + "-" + afternoonEnd])
                if (hoursOptionSelected === 1 && currentDay)
                    newHours = newHours.map(() => [customMorningStart[currentDay] + '-' + customMorningEnd[currentDay], customAfternoonStart[currentDay] + "-" + customAfternoonEnd[currentDay]])
                newHours.unshift([])
                newHours.push([])
                setHours(newHours)
                break;
            }
            // custom days
            case 2: {
                let newHoursSelected: string[][] = Array.from({ length: 7 }, () => [])
                if (hoursOptionSelected === 0)
                    daysChecked.forEach(day => newHoursSelected.splice(day, 1, [morningStart + '-' + morningEnd, afternoonStart + "-" + afternoonEnd]))
                if (hoursOptionSelected === 1)
                    daysChecked.forEach(day => newHoursSelected.splice(day, 1, [customMorningStart[day] + '-' + customMorningEnd[day], customAfternoonStart[day] + "-" + customAfternoonEnd[day]]))
                setHours(newHoursSelected)
                break;
            }
            default: {
                break;
            }
        }
    }, [dayOptionSelected, daysChecked, hoursOptionSelected, morningStart, morningEnd, afternoonStart, afternoonEnd, customMorningStart, customMorningEnd, customAfternoonStart, customAfternoonEnd, currentDay])

    // setting days and hours depending on the selected day's option
    const onDaySelectChange = (selectedOption: number) => {
        setDayOptionSelected(selectedOption)
        switch (selectedOption) {
            // all days
            case 0: {
                onDaysChecked(weekdays.map((day, index) => index))
                break;
            }
            // from monday to friday
            case 1: {
                // setting checked days
                let days = weekdays.map((day, index) => index)
                days.shift()
                days.pop()
                onDaysChecked(days)
                break;
            }
            // custom days
            case 2: {
                break;
            }
            default: {
                setDaysChecked([])
                break;
            }
        }
    }

    // custom day's option: selecting custom days and updating the hours' array
    const onDaysChecked = (value: number[]) => {
        // setting days
        let newDaysChecked = value.sort((a, b) => a - b)
        setDaysChecked(newDaysChecked)
    }

    // selecting hours' option
    const onHoursOptionChange = (hoursOption: number) => {
        setHoursOptionSelected(hoursOption)
    }

    // selecting "days with the same range of time" option: saving new times in hours
    const setDefaultTimes = (value: string, period: 'morning' | 'afternoon', position: 'start' | 'end') => {
        const timeValue = value
        switch (period) {
            case 'morning':
                if (position === 'start') {
                    setMorningStart(timeValue);
                } else {
                    setMorningEnd(timeValue);
                }
                break;
            default:
                if (position === 'start') {
                    setAfternoonStart(timeValue);
                } else {
                    setAfternoonEnd(timeValue);
                }
                break;
        }
    }

    // selecting "days with custom range of time" option: saving new times in hours
    const setCustomTimes = (value: string, day: number, period: 'morning' | 'afternoon', position: 'start' | 'end') => {
        const timeValue = value
        switch (period) {
            case 'morning':
                if (position === 'start') {
                    let newMorningStart = [...customMorningStart]
                    newMorningStart.splice(day, 1, timeValue)
                    setCustomMorningStart(newMorningStart);
                } else {
                    let newMorningEnd = [...customMorningEnd]
                    newMorningEnd.splice(day, 1, timeValue)
                    setCustomMorningEnd(newMorningEnd);
                }
                setCurrentDay(day)
                break;
            default:
                if (position === 'start') {
                    let newAfternoonStart = [...customAfternoonStart]
                    newAfternoonStart.splice(day, 1, timeValue)
                    setCustomAfternoonStart(newAfternoonStart);
                } else {
                    let newAfternoonEnd = [...customAfternoonEnd]
                    newAfternoonEnd.splice(day, 1, timeValue)
                    setCustomAfternoonEnd(newAfternoonEnd);
                }
                setCurrentDay(day)
                break;
        }
    }

    // selecting default period option: saving new times in hours
    const setDefaultPeriod = (value: number) => {
        setPeriodOptionSelected(value)
        switch (value) {
            // only morning
            case 0: {
                setMorningStart("09:00")
                setMorningEnd("13:00")
                setAfternoonStart(undefined)
                setAfternoonEnd(undefined)
                break;
            }
            // only afternoon
            case 1: {
                setMorningStart(undefined)
                setMorningEnd(undefined)
                setAfternoonStart("14:00")
                setAfternoonEnd("16:00")
                break;
            }
            // both of them
            case 2: {
                setMorningStart("09:00")
                setMorningEnd("13:00")
                setAfternoonStart("14:00")
                setAfternoonEnd("16:00")
                break;
            }
            default: {
                break;
            }
        }
    }

    // selecting custom period option: saving new times in hours
    const setCustomPeriods = (value: number, day?: number) => {
        if (day) {
            // setting periods
            let periods = [...periodsOptionSelected]
            periods.splice(day, 1, value);
            setPeriodsOptionSelected(periods)
            switch (value) {
                // only morning
                case 0: {
                    // setting default time controls
                    let newMorningStart = [...customMorningStart]
                    newMorningStart.splice(day, 1, "09:00")
                    setCustomMorningStart(newMorningStart)
                    let newMorningEnd = [...customMorningEnd]
                    newMorningEnd.splice(day, 1, "13:00")
                    setCustomMorningEnd(newMorningEnd)
                    let newAfternoonStart = [...customAfternoonStart]
                    newAfternoonStart.splice(day, 1, undefined)
                    setCustomAfternoonStart(newAfternoonStart)
                    let newAfternoonEnd = [...customAfternoonEnd]
                    newAfternoonEnd.splice(day, 1, undefined)
                    setCustomAfternoonEnd(newAfternoonEnd)
                    setCurrentDay(day)
                    break;
                }
                // only afternoon
                case 1: {
                    // setting default time controls
                    let newAfternoonStart = [...customAfternoonStart]
                    newAfternoonStart.splice(day, 1, "14:00")
                    setCustomAfternoonStart(newAfternoonStart)
                    let newAfternoonEnd = [...customAfternoonEnd]
                    newAfternoonEnd.splice(day, 1, "16:00")
                    setCustomAfternoonEnd(newAfternoonEnd)
                    let newMorningStart = [...customMorningStart]
                    newMorningStart.splice(day, 1, undefined)
                    setCustomMorningStart(newMorningStart)
                    let newMorningEnd = [...customMorningEnd]
                    newMorningEnd.splice(day, 1, undefined)
                    setCustomMorningEnd(newMorningEnd)
                    setCurrentDay(day)
                    break;
                }
                // both of them
                case 2: {
                    let newMorningStart = [...customMorningStart]
                    newMorningStart.splice(day, 1, "09:00")
                    setCustomMorningStart(newMorningStart)
                    let newMorningEnd = [...customMorningEnd]
                    newMorningEnd.splice(day, 1, "13:00")
                    setCustomMorningEnd(newMorningEnd)
                    let newAfternoonStart = [...customAfternoonStart]
                    newAfternoonStart.splice(day, 1, "14:00")
                    setCustomAfternoonStart(newAfternoonStart)
                    let newAfternoonEnd = [...customAfternoonEnd]
                    newAfternoonEnd.splice(day, 1, "16:00")
                    setCustomAfternoonEnd(newAfternoonEnd)
                    setCurrentDay(day)
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    // rendering fields for period's settings
    const renderPeriodOptions = (day?: number) => {
        return <RadioGroup
            row
            value={!day ? periodOptionSelected : periodsOptionSelected[day]}
            onChange={(event) => !day ? setDefaultPeriod(Number((event.target as HTMLInputElement).value)) : setCustomPeriods(Number((event.target as HTMLInputElement).value), day)}
            name="period-options"
        >
            <FormControlLabel value={0} control={<Field as={Radio} />} label="Solo mattina" />
            <FormControlLabel value={1} control={<Field as={Radio} />} label="Solo pomeriggio" />
            <FormControlLabel value={2} control={<Field as={Radio} />} label="Mattina e pomeriggio" />
        </RadioGroup>
    }

    // rendering fields for hours' settings
    const renderHoursFields = () => {
        switch (hoursOptionSelected) {
            case 0:
                return <div className="d-flex flex-column pt-3">
                    <h6>Imposta orario comune a tutti i giorni impostati</h6>
                    {
                        renderPeriodOptions(undefined)
                    }
                    <Row sm={12}>
                        {
                            periodOptionSelected !== 1 && <Col sm={12} md={6}>
                                <p>Mattina: </p>
                                <Row className="align-items-center">
                                    <Col sm={12} md={6}>
                                        <p>Dalle: </p>
                                        <Field
                                            name="start_morning"
                                            className="form-control"
                                            {...morningProps}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => setDefaultTimes(event.target.value, "morning", "start")}
                                            value={morningStart}
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <p>Alle: </p>
                                        <Field
                                            name="end_morning"
                                            className="form-control"
                                            {...morningProps}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => setDefaultTimes(event.target.value, "morning", "end")}
                                            value={morningEnd}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {
                            periodOptionSelected !== 0 && <Col sm={12} md={6}>
                                <p>Pomeriggio: </p>
                                <Row className="align-items-center">
                                    <Col sm={12} md={6}>
                                        <p>Dalle: </p>
                                        <Field
                                            name="start_afternoon"
                                            className="form-control"
                                            {...afternoonProps}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => setDefaultTimes(event.target.value, "afternoon", "start")}
                                            value={afternoonStart}
                                        />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <p>Alle: </p>
                                        <Field
                                            name="end_afternoon"
                                            className="form-control"
                                            {...afternoonProps}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => setDefaultTimes(event.target.value, "afternoon", "end")}
                                            value={afternoonEnd}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                </div>
            case 1:
                return <div className="pt-3">
                    {
                        daysChecked.map(
                            (day, index) => <div key={day} className="d-flex flex-column">
                                <h6 className="mb-3">{weekdays[day]}</h6>
                                {
                                    renderPeriodOptions(day)
                                }
                                <Row sm={12}>
                                    {
                                        periodsOptionSelected[day] !== 1 && <Col sm={12} md={6}>
                                            <p>Mattina: </p>
                                            <Row className="align-items-center">
                                                <Col sm={12} md={6}>
                                                    <p>Dalle: </p>
                                                    <Field
                                                        name={"start_morning" + weekdays[day]}
                                                        className="form-control"
                                                        {...morningProps}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomTimes(event.target.value, day, 'morning', 'start')}
                                                        value={customMorningStart[day]}
                                                    />
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <p>Alle: </p>
                                                    <Field
                                                        name={"end_morning" + weekdays[day]}
                                                        className="form-control"
                                                        {...morningProps}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomTimes(event.target.value, day, 'morning', 'end')}
                                                        value={customMorningEnd[day]}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {
                                        periodsOptionSelected[day] !== 0 && <Col sm={12} md={6}>
                                            <p>Pomeriggio: </p>
                                            <Row className="align-items-center">
                                                <Col sm={12} md={6}>
                                                    <p>Dalle: </p>
                                                    <Field
                                                        name={"start_afternoon" + weekdays[day]}
                                                        className="form-control"
                                                        {...afternoonProps}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomTimes(event.target.value, day, 'afternoon', 'start')}
                                                        value={customAfternoonStart[day]}
                                                    />
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <p>Alle: </p>
                                                    <Field
                                                        name={"end_afternoon" + weekdays[day]}
                                                        className="form-control"
                                                        {...afternoonProps}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomTimes(event.target.value, day, 'afternoon', 'end')}
                                                        value={customAfternoonEnd[day]}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                                {index !== daysChecked.length - 1 && <hr />}
                            </div>
                        )
                    }
                </div>

            default:
                return null
        }
    }

    return <Formik
        innerRef={formRef}
        initialValues={{
            select_days: undefined,
            select_hours: undefined
        }}
        onSubmit={() => onSubmit(hours)}
        validationSchema={Yup.object().shape({
            select_days: Yup.number().required("* Campo obbligatorio"),
            select_hours: Yup.number().required("* Campo obbligatorio"),
        })}
    >
        {
            ({
                getFieldMeta,
                getFieldProps,
                setFieldValue
            }) => <Form>
                    <div className="d-flex flex-column">
                        <h6>Selezione dei giorni di lezione</h6>
                        <Select
                            {...getFieldProps("select_days")}
                            placeholder="Seleziona un'opzione"
                            options={daysOptions}
                            name="select_days"
                            value={daysOptions.find(option => option.value === getFieldProps('select_days').value)}
                            onChange={(option) => {
                                setFieldValue("select_days", option?.value)
                                if (option)
                                    onDaySelectChange(option.value);
                            }} className="w-100"
                            styles={{ menu: provided => ({ ...provided, zIndex: 3 }) }}
                        />
                        {
                            getFieldMeta("select_days").error ? (
                                <div className="text-danger">{getFieldMeta("select_days").error}</div>
                            ) : null
                        }
                        {
                            dayOptionSelected !== undefined && dayOptionSelected === 2 && <div className="pt-3">
                                <h6>Seleziona i giorni in cui desideri inserire una lezione</h6>
                                <ToggleButtonGroup type="checkbox" value={daysChecked} onChange={onDaysChecked}>
                                    {
                                        weekdays.map((day, i) => <ToggleButton key={day} id={day} value={i}>{day}</ToggleButton>)
                                    }
                                </ToggleButtonGroup>
                            </div>
                        }
                        <hr />
                        <h6>Selezione degli orari di lezione</h6>
                        <Select
                            {...getFieldProps("select_hours")}
                            name="select_hours"
                            placeholder="Seleziona un'opzione"
                            options={hoursOptions}
                            value={hoursOptions.find(option => option.value === getFieldProps('select_hours').value)}
                            onChange={(option) => {
                                setFieldValue("select_hours", option?.value)
                                if (option)
                                    onHoursOptionChange(option.value)
                            }}
                            className="w-100"
                            styles={{ menu: provided => ({ ...provided, zIndex: 3 }) }}
                            isDisabled={dayOptionSelected === null}
                        />
                        {
                            getFieldMeta("select_hours").error ? (
                                <div className="text-danger">{getFieldMeta("select_hours").error}</div>
                            ) : null
                        }
                        {
                            renderHoursFields()
                        }
                    </div>
                </Form>
        }
    </Formik>
}

export default RealCourseGenerateCalendar;