import React, { FC } from 'react';
import * as Yup from 'yup';
import CustomModal from '../../common/components/CustomModal/CustomModal';
import { ICustomFormProps } from '../../common/interfaces/CustomForm';
import { IModalProps } from '../../common/interfaces/CustomModal';
import reportsService from '../../services/ReportsService';

interface IGenerateReportModalProps {
    reports: { value: number, label: string, infoLabel: string }[],
    show: boolean,
    close: (action?: string) => void,
}

const GenerateReportModal: FC<IGenerateReportModalProps> = (props) => {

    const { reports, show, close } = props

    const validationSchema = Yup.object().shape({
        reportId: Yup.number().required("* Campo obbligatorio"),
    })

    const handleSubmit = async (values: { reportId: string }) => {
        reportsService.download(values.reportId).then(() => close("generate"))
    }

    const modalProps: IModalProps = {
        close,
        confirm: () => { },
        show,
        action: 'generate',
        label: "Scegli quale report generare"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: [{
            name: 'reportId',
            type: 'select',
            placeholder: 'Seleziona il tipo di report',
            label: 'Report',
            options: reports,
        }],
        initialValues: { reportId: undefined },
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default GenerateReportModal;