import { IGenericCourse } from '../../../interfaces/GenericCourse';
import * as Yup from 'yup';
import CustomModal from '../../../common/components/CustomModal/CustomModal';
import { genericCoursesFields } from '../../../config/formFields/GenericCoursesFields';
import genericCoursesService from '../../../services/GenericCoursesService';
import { IModalProps } from '../../../common/interfaces/CustomModal';
import { FC, useState } from 'react';
import fileService from '../../../services/FileService';
import _ from 'lodash';

interface IGenericCoursesModalProps {
    genericCourse: IGenericCourse | undefined,
    fileId: number | undefined,
    id: number | undefined,
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const GenericCoursesModal: FC<IGenericCoursesModalProps> = (props) => {
    const { genericCourse, fileId, id, action, show, close } = props
    const [schedule, setSchedule] = useState<{ file: File | undefined, fileId: number | undefined }>({ file: undefined, fileId: undefined })

    const newGenericCourse = {
        ...genericCourse,
        schedule: genericCourse?.schedule.id.toString(),
        description: genericCourse?.schedule.description
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        duration: Yup.number().required("* Campo obbligatorio").integer("Deve essere un intero").positive("La durata deve essere positiva"),
        schedule: Yup.string().required("* Campo obbligatorio"),
        description: Yup.string().nullable(),
        note: Yup.string().nullable()
    })

    const handleSubmit = async (values: IGenericCourse) => {
        if (action === 'add') {
            if (schedule.file)
                fileService.post({ file: schedule.file, description: values.description }).then(
                    res => {
                        if (res) {
                            const body = { ...values, schedule: Number(res.id) }
                            genericCoursesService.post(body).then(() => close('add'))
                        }
                    }
                )
        }
        if (action === 'edit' && id) {
            if (!_.isEqual(genericCourse?.schedule.description, values.description) && genericCourse) {
                fileService.put(genericCourse.schedule.id, { file: { ...genericCourse.schedule, name: genericCourse.schedule.client_name }, description: values.description })
            }
            if (schedule.fileId && schedule.file) {
                fileService.delete(schedule.fileId).then(
                    res => {
                        if (res && schedule.file)
                            fileService.post({ file: schedule.file, description: values.description }).then(
                                file => {
                                    const body = { ...values, schedule: Number(file.id) }
                                    genericCoursesService.put(id, body).then(() => close('edit'))
                                }
                            )
                    }
                )
            } else
                genericCoursesService.put(id, { ...values, schedule: Number(values.schedule) }).then(() => close('edit'))
        }
    }

    const onConfirm = () => {
        if (action === 'delete' && id && fileId) {
            Promise.all([
                genericCoursesService.delete(id),
                fileService.delete(fileId)
            ]).then(res => close('delete'))
        }
    }

    const modalProps: IModalProps = {
        close,
        show,
        confirm: onConfirm,
        action,
        label: genericCourse && genericCourse.name ? 'Modifica "' + genericCourse.name + '"' : 'Aggiungi un nuovo corso generico'
    }

    const formProps = {
        fields: genericCoursesFields,
        initialValues: newGenericCourse,
        onSubmit: handleSubmit,
        validationSchema,
        close,
        setFile: (file: File | undefined, fileId: number | undefined) => setSchedule({ file, fileId })
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default GenericCoursesModal;