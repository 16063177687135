export const getStudentInternalFields = (contractTypes: { value: number, label: string }[]) => {
    return [
        {
            name: 'contract_type_id',
            type: 'select',
            options: contractTypes,
            placeholder: 'Inserisci la tipologia di contratto',
            label: 'Tipologia di contratto',
        },
        {
            name: 'start_date',
            type: 'datepicker',
            placeholder: 'Inserisci la data di inizio',
            label: 'Data di inizio formazione',
        },
        {
            name: 'end_date',
            type: 'datepicker',
            placeholder: 'Inserisci la data di inizio',
            label: 'Data di fine formazione',
        },
        {
            name: 'hours',
            type: 'number',
            placeholder: 'Inserisci le ore di frequentazione',
            label: 'Ore di frequentazione',
        },
        {
            name: 'note',
            type: 'textarea',
            placeholder: 'Inserisci eventuali note',
            label: 'Note',
        }
    ]
}