export const genericCoursesFields = [
    {
        name: 'name',
        type: 'text',
        placeholder: 'Inserisci il nome',
        label: 'Nome',
    },
    {
        name: 'duration',
        type: 'number',
        placeholder: 'Inserisci la durata',
        label: 'Durata',
    },
    {
        name: 'schedule',
        type: 'file',
        placeholder: 'Carica il file del programma',
        label: 'Programma',
    },
    {
        name: 'description',
        type: 'text',
        placeholder: 'Inserisci la descrizione del file',
        label: 'Descrizione del file',
    },
    {
        name: 'note',
        type: 'textarea',
        placeholder: 'Inserisci le note',
        label: 'Note',
    }
]