import axios from "axios";
import IQualification from "../interfaces/Qualification";

class QualificationsService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IQualification[]> {
        return await axios.get(this.baseUrl + '/qualifications').then(response => response.data.data)
    }

    async get(id: number): Promise<IQualification> {
        return await axios.get(this.baseUrl + '/qualification/' + id).then(response => response.data.data)
    }

    async post(body: IQualification): Promise<IQualification> {
        return await axios.post(this.baseUrl + '/qualification', body).then(response => response.data.data)
    }

    async put(id: number, body: IQualification): Promise<IQualification> {
        return await axios.put(this.baseUrl + '/qualification/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/qualification/' + id).then(response => response.data.data)
    }
}

const qualificationsService = new QualificationsService();

export default qualificationsService;