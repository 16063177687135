export const getCalendarFields = (teachers: { value: number, label: string }[], period: 'morning' | 'afternoon') => {
    return [
        {
            name: 'name',
            type: 'text',
            placeholder: 'Inserisci il nome del corso',
            label: 'Nome del corso'
        },
        {
            name: 'description',
            type: 'text',
            placeholder: 'Inserisci la descrizione',
            label: 'Descrizione'
        },
        {
            name: 'teacher_id',
            type: 'select',
            placeholder: 'Seleziona un docente',
            label: 'Docente',
            options: teachers
        },
        {
            name: 'date',
            type: 'datepicker',
            placeholder: "Inserisci la data",
            label: 'Data di svolgimento',
            disabled: period === 'afternoon' ? true : false
        },
        {
            name: 'start_time',
            type: 'time',
            placeholder: period === 'morning' ? "Inserisci l'orario di inizio del mattino" : "Inserisci l'orario di inizio del pomeriggio",
            label: 'Dalle',
            min: period === 'morning' ? '00:00' : '13:00',
            max: period === 'morning' ? '13:00' : '23:45',
            step: '900'
        },
        {
            name: 'end_time',
            type: 'time',
            placeholder: period === 'morning' ? "Inserisci l'orario di fine del mattino" : "Inserisci l'orario di fine del pomeriggio",
            label: 'Alle',
            min: period === 'morning' ? '00:00' : '13:00',
            max: period === 'morning' ? '13:00' : '23:45',
            step: '900'
        },
        {
            name: 'note',
            type: 'textarea',
            placeholder: 'Inserisci le note',
            label: 'Note'
        }
    ]
} 