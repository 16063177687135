import IQualification from "../../../interfaces/Qualification"
import * as Yup from 'yup';
import qualificationsService from "../../../services/QualificationsService";
import CustomModal from "../../../common/components/CustomModal/CustomModal";
import { qualificationsFields } from '../../../config/formFields/QualificationsFields';
import { FC } from "react";
import { IModalProps } from "../../../common/interfaces/CustomModal";
import { ICustomFormProps } from "../../../common/interfaces/CustomForm";

interface IQualificationsModalProps {
    qualification: IQualification | undefined,
    id: number | undefined
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const QualificationsModal: FC<IQualificationsModalProps> = (props) => {
    const { qualification, id, show, close, action } = props

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        code: Yup.string().required("* Campo obbligatorio").length(3, "La lunghezza deve essere di 3"),
    })

    const handleSubmit = async (values: IQualification) => {
        const body = { ...values, code: values.code.toUpperCase() }
        if (action === "add") {
            qualificationsService.post(body).then(() => close("add"))
        }
        if (action === "edit" && id) {
            qualificationsService.put(id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            qualificationsService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: qualification ? 'Modifica i dati di "' + qualification.name + '"' : "Aggiungi un nuovo titolo di studio"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: qualificationsFields,
        initialValues: qualification,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>

}

export default QualificationsModal;