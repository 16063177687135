import axios from "axios"
import { IClassroomTeacher, IClassroomTeacherBody } from "../interfaces/ClassroomTeacher"

class ClassroomTeachersService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(classroomId: string): Promise<IClassroomTeacher[]> {
        return await axios.get(this.baseUrl + '/classroom/' + classroomId + '/teachers').then(response => response.data.data)
    }

    async get(classroomId: number, teacherId: number): Promise<IClassroomTeacher> {
        return await axios.get(this.baseUrl + "/classroom/" + classroomId + '/teacher/' + teacherId).then(response => response.data.data)
    }

    async post(classroomId: number, body: IClassroomTeacherBody): Promise<IClassroomTeacher> {
        return await axios.post(this.baseUrl + '/classroom/' + classroomId + '/teacher', body).then(response => response.data.data)
    }

    async put(classroomId: number, teacherId: number, body: IClassroomTeacherBody): Promise<IClassroomTeacher> {
        return await axios.put(this.baseUrl + "/classroom/" + classroomId + '/teacher/' + teacherId, body).then(response => response.data.data)
    }

    async delete(classroomId: number, teacherId: number) {
        return await axios.delete(this.baseUrl + "/classroom/" + classroomId + '/teacher/' + teacherId).then(() => teacherId)
    }
}

const classroomTeachersService = new ClassroomTeachersService();

export default classroomTeachersService;