import axios from "axios";
import ISource from "../interfaces/Source"

class SourcesService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<ISource[]> {
        return await axios.get(this.baseUrl + '/student/sources').then(response => response.data.data)
    }

    async get(id: number): Promise<ISource> {
        return await axios.get(this.baseUrl + '/student/source/' + id).then(response => response.data.data)
    }

    async post(body: ISource): Promise<ISource> {
        return await axios.post(this.baseUrl + '/student/source', body).then(response => response.data.data)
    }

    async put(id: number, body: ISource): Promise<ISource> {
        return await axios.put(this.baseUrl + '/student/source/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/student/source/' + id).then(response => response.data.data)
    }
}

const sourcesService = new SourcesService();

export default sourcesService;