import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faPen, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons'
import CustomTable from '../../common/components/CustomTable/CustomTable';
import { ITableRealCourse } from '../../interfaces/RealCourse';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import RealCourseDetails from '../../components/Courses/RealCourses/RealCourseDetails';

const RealCoursesColumns = (props: {
    period?: string,
    onEditClick: (tableMeta: MUIDataTableMeta<ITableRealCourse>) => void,
    onDeleteClick: (tableMeta: MUIDataTableMeta<ITableRealCourse>) => void,
    onRefreshClick: (tableMeta: MUIDataTableMeta<ITableRealCourse>) => void,
    realCourses: ITableRealCourse[],
    searchParam?: string
}) => {
    const navigate = useNavigate()
    const { realCourses, searchParam, onEditClick, onDeleteClick, onRefreshClick, period } = props

    const realCoursesColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "name",
            label: "Corso reale",
            options: {
                filterList: []
            }
        },
        {
            name: "course_name",
            label: "Corso generico"
        },
        {
            name: "duration",
            label: "Durata"
        },
        {
            name: "start_date",
            label: "Inizio"
        },
        {
            name: "end_date",
            label: "Fine"
        },
        {
            name: "azioni",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ITableRealCourse>, updateValue: any) => (
                    <>
                        <Button className='btn btn-primary rounded-circle me-1 shadow-sm' title="Modifica corso" onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
                        <Button className='btn btn-danger rounded-circle me-1 shadow-sm' title="Elimina corso" onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
                        <Button className='btn btn-primary rounded-circle me-1 shadow-sm' title="Rinnova corso" onClick={() => onRefreshClick(tableMeta)}><FontAwesomeIcon icon={faRefresh} /></Button>
                        <Button className='btn btn-secondary rounded-circle shadow-sm' title="Mostra calendario" onClick={() => navigate('/corsi-reali/' + tableMeta.rowData[0] + '/calendario')}><FontAwesomeIcon icon={faCalendar} /></Button>
                    </>
                )
            }
        },
    ]

    const renderProfileDetails = (rowData: string[], rowMeta: {
        dataIndex: number;
        rowIndex: number;
    }) => {
        const colSpan = rowData.length + 1;

        return <TableRow>
            <TableCell colSpan={colSpan}>
                <RealCourseDetails id={rowData[0]} />
            </TableCell>
        </TableRow>
    }

    const filteredCols = searchParam ? [...realCoursesColumns] : [];

    if (searchParam) {
        let filterList: string[] = [];
        if (searchParam !== "All") {
            filterList = [searchParam];
        }
        // Target the column to filter on.
        let colWithFilter = filteredCols.find(col => col.options && col.options.filterList)
        if (colWithFilter && colWithFilter.options && colWithFilter.options.filterList)
            colWithFilter.options.filterList = filterList;
    }

    const getTitle = () => {
        switch (period) {
            case 'future':
                return 'Elenco dei corsi futuri'
            case 'now':
                return 'Elenco dei corsi attivi'
            case 'past':
                return 'Elenco dei corsi passati'
            default:
                return 'Elenco di tutti i corsi reali disponibili'
        }
    }

    return (
        <CustomTable
            title={getTitle()}
            data={realCourses}
            columns={filteredCols.length !== 0 ? filteredCols : realCoursesColumns}
            options={{
                expandableRows: true,
                renderExpandableRow: renderProfileDetails,
                rowsExpanded: searchParam ? [0] : []
            }}
        />
    )
}

export default RealCoursesColumns;