import axios from "axios"
import { IClassroomValuation, IClassroomValuationBody, IListClassroomValuation } from "../interfaces/ClassroomValuation"
import studentsService from "./StudentsService"
import teachersService from "./TeachersService"

class ClassroomValuationsService {

    private baseUrl = process.env.REACT_APP_API_URL

    mapDataForTable = (valuations: IClassroomValuation[], recipient: number): Promise<IListClassroomValuation[]> => {
        let mapPromise: Promise<IListClassroomValuation[]> = new Promise(() => { })

        // calling all students/teachers mapping valuations if they exists
        if (valuations.length !== 0) {
            if (recipient === 2) {
                mapPromise = studentsService.getAllForMenu().then(
                    students => {
                        return valuations.map(valuation => {
                            let studentFound = students.find(student => student.id === valuation.has_student.student.id)
                            return {
                                id: valuation.id,
                                vote: valuation.vote,
                                from: studentFound?.last_name + " " + (studentFound?.middle_name || '') + studentFound?.first_name,
                                note: valuation.note
                            }
                        })
                    }
                )
            } else {
                mapPromise = teachersService.getAllForMenu().then(
                    teachers => {
                        return valuations.map(valuation => {
                            let teacherFound = teachers.find(teacher => teacher.id === valuation.has_teacher.teacher.id)
                            return {
                                id: valuation.id,
                                vote: valuation.vote,
                                from: teacherFound?.last_name + " " + (teacherFound?.middle_name || '') + teacherFound?.first_name,
                                note: valuation.note
                            }
                        })
                    }
                )
            }
        }

        return Promise.resolve(mapPromise)
    }

    async getAll(classroomId: string): Promise<IClassroomValuation[]> {
        return await axios.get(this.baseUrl + '/classroom/' + classroomId + '/valuations').then(response => response.data.data)
    }

    async getAllByProfile(classroomId: string, profileId: number, recipient: number): Promise<IClassroomValuation[]> {
        return await axios.get(this.baseUrl + '/classroom/' + classroomId + '/valuations').then(response => {
            if (recipient === 2)
                return response.data.data.filter((valuation: IClassroomValuation) => valuation.has_teacher.id === profileId && valuation.recipient === recipient)
            else
                return response.data.data.filter((valuation: IClassroomValuation) => valuation.has_student.id === profileId && valuation.recipient === recipient)
        })
    }

    async get(classroomId: number, valuationId: number): Promise<IClassroomValuation> {
        return await axios.get(this.baseUrl + "/classroom/" + classroomId + '/valuation/' + valuationId).then(response => response.data.data)
    }

    async post(classroomId: number, body: IClassroomValuationBody): Promise<IClassroomValuation> {
        return await axios.post(this.baseUrl + '/classroom/' + classroomId + '/valuation', body).then(response => response.data.data)
    }

    async put(classroomId: number, valuationId: number, body: IClassroomValuationBody): Promise<IClassroomValuation> {
        return await axios.put(this.baseUrl + "/classroom/" + classroomId + '/valuation/' + valuationId, body).then(response => response.data.data)
    }

    async delete(classroomId: number, valuationId: number) {
        return await axios.delete(this.baseUrl + "/classroom/" + classroomId + '/valuation/' + valuationId).then(() => valuationId)
    }
}

const classroomValuationsService = new ClassroomValuationsService();

export default classroomValuationsService;