import axios from "axios"
import { IStudentFile } from "../interfaces/StudentFile"

class StudentFileService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(studentId: number): Promise<IStudentFile[]> {
        return await axios.get<{ statusCode: number, data: IStudentFile[] }>(this.baseUrl + '/student/' + studentId + '/files').then(response => response.data.data)
    }

    async get(studentId: number, id: number): Promise<IStudentFile> {
        return await axios.get(this.baseUrl + '/student/' + studentId + '/file/' + id).then(response => response.data.data)
    }

    async post(studentId: number, body: { file_id: number }): Promise<IStudentFile> {
        return await axios.post(this.baseUrl + '/student/' + studentId + '/file', body).then(response => response.data.data)
    }

    async put(studentId: number, id: number, body: { file_id: number }): Promise<IStudentFile> {
        return await axios.put(this.baseUrl + '/student/' + studentId + '/file/' + id, body).then(response => response.data.data)
    }

    async delete(studentId: number, id: number) {
        return await axios.delete(this.baseUrl + '/student/' + studentId + '/file/' + id).then(response => response.data.data)
    }
}

const studentFileService = new StudentFileService();

export default studentFileService;