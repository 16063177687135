import { FC, useEffect, useState } from "react"
import classroomValuationsService from "../../../../services/ClassroomValuationsService"
import { Card } from "react-bootstrap"
import { IClassroomValuation, IListClassroomValuation } from "../../../../interfaces/ClassroomValuation"
import { ClassroomValuationColumns } from "../../../../config/tableColumns/ClassroomDetailsColumns"
import ClassroomDetailsModalValuation from "./ClassroomDetailsModalValuation"
import { IClassroomTeacher } from "../../../../interfaces/ClassroomTeacher"
import { IClassroomStudent } from "../../../../interfaces/ClassroomStudent"
import classroomTeachersService from "../../../../services/ClassroomTeachersService"
import classroomStudentsService from "../../../../services/ClassroomStudentsService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleDown, faArrowCircleRight, faPlus } from "@fortawesome/free-solid-svg-icons"

interface IClassroomValuationsProps {
    classroomId: string | undefined,
    profileId: number,
    recipient: number,
}

const ClassroomValuations: FC<IClassroomValuationsProps> = (props) => {

    const { classroomId, profileId, recipient } = props
    const [listValuations, setListValuations] = useState<IListClassroomValuation[]>([])
    const [currentValuation, setCurrentValuation] = useState<IClassroomValuation | undefined>(undefined);
    const [teachers, setTeachers] = useState<IClassroomTeacher[]>([])
    const [students, setStudents] = useState<IClassroomStudent[]>([])
    const [currentId, setCurrentId] = useState<number | undefined>(undefined);
    const [studentId, setStudentId] = useState<number | undefined>(undefined);
    const [teacherId, setTeacherId] = useState<number | undefined>(undefined);
    const [action, setAction] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showValuations, setShowValuations] = useState(false)

    useEffect(() => {
        if (classroomId && showValuations)
            classroomValuationsService.getAllByProfile(classroomId, profileId, recipient).then(
                res => {
                    classroomValuationsService.mapDataForTable(res, recipient).then(
                        mapped => setListValuations(mapped)
                    )
                }
            )
    }, [classroomId, profileId, recipient, showValuations])


    useEffect(() => {
        if (refresh === true) {
            if (classroomId && showValuations)
                classroomValuationsService.getAllByProfile(classroomId, profileId, recipient).then(
                    res => {
                        classroomValuationsService.mapDataForTable(res, recipient).then(
                            mapped => setListValuations(mapped)
                        )
                    }
                )
            setRefresh(false);
        }
    }, [refresh, classroomId, profileId, recipient, showValuations]);

    const onAddClick = () => {
        if (classroomId)
            classroomTeachersService.getAll(classroomId).then(
                res => {
                    setAction('add');
                    setTeachers(res);
                    if (recipient === 2) {
                        setTeacherId(profileId)
                        setStudentId(undefined)
                    } else {
                        setStudentId(profileId)
                        setTeacherId(undefined)
                    }
                    classroomStudentsService.getAll(classroomId).then(
                        res => {
                            setStudents(res)
                            setShowModal(true);
                        }
                    )
                }
            )
    }

    const onDeleteClick = (valuation: IListClassroomValuation) => {
        if (valuation.id) {
            setAction('delete')
            setCurrentId(valuation.id)
            setShowModal(true);
        }
    }

    const onEditClick = (valuation: IListClassroomValuation) => {
        if (valuation.id && classroomId) {
            classroomValuationsService.get(Number(classroomId), valuation.id)
                .then(
                    res => {
                        setAction('edit')
                        setCurrentId(res.id)
                        if (recipient === 2) {
                            setTeacherId(profileId)
                            setStudentId(res.has_student.id)
                        } else {
                            setStudentId(profileId)
                            setTeacherId(res.has_teacher.id)
                        }
                        setCurrentValuation(res)
                        classroomTeachersService.getAll(classroomId).then(
                            res => {
                                setTeachers(res);
                                classroomStudentsService.getAll(classroomId).then(
                                    res => {
                                        setStudents(res)
                                        setShowModal(true);
                                    }
                                )
                            }
                        )
                    }
                )
        }
    }

    return <>
        <Card className="mb-3 shadow-sm">
            <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fw-600">Valutazioni</span>
                    <div className="d-flex">
                        {!showValuations && <Card.Link className="btn btn-link p-0 d-flex align-items-center" onClick={() => setShowValuations(!showValuations)}><FontAwesomeIcon icon={faArrowCircleRight} className="me-1" />Mostra valutazioni</Card.Link>}
                        {showValuations && <Card.Link className="btn btn-link p-0 d-flex align-items-center" onClick={() => setShowValuations(!showValuations)}><FontAwesomeIcon icon={faArrowCircleDown} className="me-1" />Nascondi valutazioni</Card.Link>}
                        <Card.Link className='btn btn-success rounded-pill' onClick={onAddClick}><FontAwesomeIcon icon={faPlus} className="me-1" />Aggiungi valutazione</Card.Link>
                    </div>
                </div>
            </Card.Header>
            <ClassroomDetailsModalValuation
                currentValuation={currentValuation}
                studentId={studentId}
                teacherId={teacherId}
                recipient={recipient}
                teachers={teachers}
                students={students}
                classroomId={Number(classroomId)}
                id={currentId}
                show={showModal}
                close={(action?: string) => {
                    setShowModal(false);
                    setAction('')
                    setCurrentValuation(undefined);
                    setCurrentId(undefined);
                    if (typeof action === 'string')
                        setRefresh(true);
                }}
                action={action}
            />
            {
                showValuations && <Card.Body>
                    <ClassroomValuationColumns listData={listValuations} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
                </Card.Body>
            }
        </Card>
    </>
}

export default ClassroomValuations