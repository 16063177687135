import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import IFund from '../../interfaces/Fund';

const FundsColumns = (props: {
  onEditClick: (tableMeta: MUIDataTableMeta<IFund>) => void,
  onDeleteClick: (tableMeta: MUIDataTableMeta<IFund>) => void,
  funds: IFund[],
  searchParam?: string
}) => {

  const { funds, searchParam, onEditClick, onDeleteClick } = props

  const fundsColumns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false
      }
    },
    {
      name: "name",
      label: "Nome"
    },
    {
      name: "code",
      label: "Codice",
      options: {
        filterList: []
      }
    },
    {
      name: "azioni",
      label: "Azioni",
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IFund>, updateValue: any) => (
          <>
            <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
            <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
          </>
        )
      }
    },
  ]

  const filteredCols = searchParam ? [...fundsColumns] : [];

  if (searchParam) {
    let filterList: string[] = [];
    if (searchParam !== "All") {
      filterList = [searchParam];
    }
    // Target the column to filter on.
    let colWithFilter = filteredCols.find(col => col.options && col.options.filterList)
    if (colWithFilter && colWithFilter.options && colWithFilter.options.filterList)
      colWithFilter.options.filterList = filterList;

  }

  return (
    <CustomTable
      title={"Elenco di tutti i fondi disponibili"}
      data={funds}
      columns={filteredCols.length !== 0 ? filteredCols : fundsColumns}
    />
  )
}

export default FundsColumns;