import * as Yup from 'yup';
import CustomModal from '../../../../common/components/CustomModal/CustomModal';
import { IRealCourse, IRealCourseBody } from '../../../../interfaces/RealCourse';
import realCoursesService from '../../../../services/RealCoursesService';
import { IModalProps } from '../../../../common/interfaces/CustomModal';
import { ICustomFormProps } from '../../../../common/interfaces/CustomForm';
import { FC } from 'react';
import { convertDateToCustomFormat } from '../../../../common/config/configDate';
import { setRealCoursesFields } from '../../../../config/formFields/RealCoursesFields';
import calendarService from '../../../../services/CalendarService';
import { ICalendarEventStepperProps } from '../../../../interfaces/CalendarEventStepper';
import _ from 'lodash';


interface IRealCoursesModalProps {
    realCourse: IRealCourse | undefined,
    allSelectData: {
        courseTypes: { value: number, label: string }[],
        genericCourses: { value: number, label: string }[],
        funds: { value: number, label: string }[],
        platforms: { value: number, label: string }[],
        teachers: { value: number, label: string }[]
    }
    id: number | undefined,
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const RealCoursesModal: FC<IRealCoursesModalProps> = (props) => {
    const { realCourse, allSelectData, id, action, show, close } = props
    // setting steps' labels
    const steps = ['Inserisci i dati del corso reale', 'Inserisci i dati del calendario']

    const mappedRealCourse = {
        ...realCourse,
        name: realCourse ? realCourse.name : undefined,
        details: realCourse ? realCourse.details : undefined,
        mode: realCourse ? realCourse.mode : undefined,
        course: realCourse ? realCourse.course.id : undefined,
        fund: realCourse ? realCourse.fund.id : undefined,
        platform: realCourse ? realCourse.platform.id : undefined,
        type: realCourse ? realCourse.type.id : undefined,
        is_internal: realCourse ? Number(realCourse.is_internal) : undefined,
        start_date: realCourse ? (action === 'edit' ? new Date(realCourse.start_date.date) : undefined) : undefined,
        max_students: realCourse ? realCourse.max_students : undefined,
        teacher_id: realCourse ? realCourse.teacher.id : undefined
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        course: Yup.number().required("* Campo obbligatorio"),
        start_date: Yup.string().required("* Campo obbligatorio"),
        fund: Yup.number().required("* Campo obbligatorio"),
        is_internal: Yup.number().required("* Campo obbligatorio"),
        mode: Yup.string().required("* Campo obbligatorio"),
        platform: Yup.number().required("* Campo obbligatorio"),
        type: Yup.number().required("* Campo obbligatorio"),
        teacher_id: Yup.number().required("* Campo obbligatorio"),
        max_students: Yup.number().required("* Campo obbligatorio").integer("Deve essere un intero"),
        details: Yup.string().nullable(),
    })

    const onConfirm = () => {
        if (action === 'delete' && id) {
            realCoursesService.delete(id).then(() => close(action))
        }
    }

    const handleSubmit = (dataToSend: (IRealCourseBody & { start_date: Date, hours?: string[][] })) => {
        if (dataToSend && dataToSend.hours) {

            let hoursToSend = dataToSend.hours.map(hours => hours.filter(hour => hour !== "undefined-undefined"))

            const confrontValues = {
                ...realCourse,
                fund: realCourse?.fund.id,
                platform: realCourse?.platform.id,
                teacher_id: realCourse?.teacher.id,
                is_internal: Number(realCourse?.is_internal),
                course: realCourse?.course.id,
                type: realCourse?.type.id,
                start_date: realCourse ? new Date(realCourse?.start_date.date) : new Date()
            }

            let body = {
                ...dataToSend,
                ...hoursToSend,
                is_internal: Boolean(dataToSend.is_internal),
                start_date: convertDateToCustomFormat(dataToSend.start_date)
            }

            if (action === 'edit' && id && realCourse) {
                if (!_.isEqual(dataToSend, confrontValues)) {
                    if (convertDateToCustomFormat(dataToSend.start_date) !== convertDateToCustomFormat(new Date(realCourse.start_date.date))) {
                        calendarService.moveCalendar(realCourse.id, { start_date: convertDateToCustomFormat(dataToSend.start_date), hours: hoursToSend })
                    }
                    realCoursesService.put(id, body).then(() => close(action))
                } else {
                    close()
                }
            }

            if (action === 'add') {
                realCoursesService.post(body).then((res) => {
                    if (hoursToSend)
                        calendarService.generateCalendarEvents(res.id, { start_date: dataToSend.start_date, hours: hoursToSend }).then(
                            () => { close(action) }
                        )
                })
            }

            if (action === 'refresh' && realCourse) {
                realCoursesService.post(body).then((res) => {
                    calendarService.refreshCalendar(res.id, { old_course_real_id: realCourse.id }).then(
                        () => { close(action) }
                    )
                })
            }
        }
    }

    const modalProps: IModalProps = {
        close,
        show,
        confirm: onConfirm,
        action,
        label: realCourse && realCourse.name ?
            (action === 'edit' ? 'Modifica "' + realCourse.name + '"' : 'Rinnova "' + realCourse.name + '"')
            :
            'Aggiungi un nuovo corso reale'
    }

    const formProps: ICustomFormProps = {
        close,
        fields: setRealCoursesFields(allSelectData.genericCourses, allSelectData.funds, allSelectData.platforms, allSelectData.courseTypes, allSelectData.teachers),
        initialValues: mappedRealCourse,
        onSubmit: handleSubmit,
        validationSchema,
    }

    const stepperProps: ICalendarEventStepperProps = {
        type: 'realCourse',
        isModal: true,
        steps,
        close,
        formProps
    }

    return <CustomModal modalProps={modalProps} stepperProps={action === 'add' ? stepperProps : undefined} formProps={action !== 'add' ? formProps : undefined}></CustomModal>
}

export default RealCoursesModal;