import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import ICourseType from '../../interfaces/CourseType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../../common/components/CustomTable/CustomTable';

const CoursesTypesColumns = (props: {
  onEditClick: (tableMeta: MUIDataTableMeta<ICourseType>) => void,
  onDeleteClick: (tableMeta: MUIDataTableMeta<ICourseType>) => void,
  courseTypes: ICourseType[]
}) => {

  const { courseTypes, onEditClick, onDeleteClick } = props

  const coursesTypesColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false
      }
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "code",
      label: "Codice",
    },
    {
      name: "azioni",
      label: "Azioni",
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta<ICourseType>, updateValue: any) => (
          <>
            <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
            <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
          </>
        )
      }
    },
  ]

  return (
    <CustomTable
      title={"Elenco di tutte le tipologie disponibili"}
      data={courseTypes}
      columns={coursesTypesColumns}
    />
  )
}

export default CoursesTypesColumns;