import { FC } from "react"
import CustomModal from "../../../common/components/CustomModal/CustomModal"
import { IEmplyomentAgency } from "../../../interfaces/EmploymentAgency"
import * as Yup from "yup";
import employmentAgenciesService from "../../../services/EmploymentAgenciesService";
import { IModalProps } from "../../../common/interfaces/CustomModal";
import { ICustomFormProps } from "../../../common/interfaces/CustomForm";
import { employmentAgenciesFields } from "../../../config/formFields/EmploymentAgenciesFields";

interface IEmploymentAgenciesModalProps {
    agency: IEmplyomentAgency | undefined,
    id: number | undefined,
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const EmploymentAgenciesModal: FC<IEmploymentAgenciesModalProps> = (props) => {
    const { agency, id, show, close, action } = props

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        code: Yup.string().required("* Campo obbligatorio").length(3, "La lunghezza deve essere di 3"),
        training_fee: Yup.number().required("* Campo obbligatorio")
    })

    const handleSubmit = async (values: IEmplyomentAgency) => {
        const body = { ...values, code: values.code.toUpperCase() }
        if (action === "add") {
            employmentAgenciesService.post(body).then(() => close("add"))
        }
        if (action === "edit" && id) {
            employmentAgenciesService.put(id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            employmentAgenciesService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: agency ? 'Modifica i dati di "' + agency.name + '"' : "Aggiungi una nuova agenzia"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: employmentAgenciesFields,
        initialValues: agency,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>

}

export default EmploymentAgenciesModal;