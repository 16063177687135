export const sourcesFields = [
    {
        name: 'name',
        type: 'text',
        placeholder: 'Inserisci il nome',
        label: 'Nome'
    },
    {
        name: 'code',
        type: 'text',
        placeholder: 'Inserisci il codice',
        label: 'Codice'
    }
]