import React, { FC } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import './Home.css';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

interface HomeProps { }

const Home: FC<HomeProps> = () => {

  const navigate = useNavigate();
  const profile = authService.getProfile();
  const location = useLocation();

  return <div className="h-100">
    {/* navbar */}
    <Navbar bg="light" expand="lg" fixed="top" className='shadow-sm'>
      <Container className='mx-2 mw-100'>
        <Navbar.Brand>
          <Link to="dashboard">
            <img src="/assets/img/egeria-logo.png" alt="egeria-logo" height="40px" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" activeKey={location.pathname}>
            <Nav.Link as={Link} to="/dashboard" eventKey={'/dashboard'}>
              Dashboard
            </Nav.Link>
            <Nav.Link as={Link} to="/calendari" eventKey={'/calendari'}>
              Calendari
            </Nav.Link>
            <NavDropdown title="Corsi" active={location.pathname === '/corsi-generici' || location.pathname === '/corsi-reali'}>
              <NavDropdown.Item as={Link} to="/corsi-generici">
                Corsi generici
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/corsi-reali">
                Corsi reali
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/aule" eventKey={'/aule'}>
              Aule
            </Nav.Link>
            <Nav.Link as={Link} to="/docenti" eventKey={'/docenti'}>
              Docenti
            </Nav.Link>
            <Nav.Link as={Link} to="/studenti" eventKey={'/studenti'}>
              Studenti
            </Nav.Link>
            <Nav.Link as={Link} to="/utenti" eventKey={'/utenti'}>
              Utenti
            </Nav.Link>
            <NavDropdown title="Database" active={
              location.pathname === '/fondi' || location.pathname === '/piattaforme' ||
              location.pathname === '/tipologie-corsi' || location.pathname === '/titoli-studio' ||
              location.pathname === '/sorgenti' || location.pathname === '/tipologie-contratti'
            }>
              <NavDropdown.Item as={Link} to="/fondi">
                Fondi formativi
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/piattaforme">
                Piattaforme
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tipologie-corsi">
                Tipologie corsi
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/titoli-studio">
                Titoli studio
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/sorgenti">
                Sorgenti
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tipologie-contratti">
                Tipologie contratti
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/stati-studenti">
                Stati degli studenti
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/apl">
                APL
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <NavDropdown align="end" title={
            <div className='btn bg-light border-dark border-opacity-25 border-1 d-flex align-items-center'>
              <FontAwesomeIcon icon={faUserCircle} className="fs-4 me-2" />
              {profile && <span>Ciao {profile.name}</span>}
            </div>
          } className="user-menu nav-link">
            {/* <NavDropdown.Item>
              <FontAwesomeIcon icon={faGear} className="me-2" />Impostazioni
            </NavDropdown.Item>
            <NavDropdown.Divider /> */}
            <NavDropdown.Item onClick={() => {
              authService.logout()
              navigate('/login')
            }}>
              <FontAwesomeIcon icon={faSignOut} className="me-2" />Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className='outlet-container'>
      <Outlet />
      {/* footer */}
      <Navbar bg="light" expand="lg">
        <Container>
          <footer className='d-flex align-items-center mx-auto'>
            <span>Powered by</span>
            <a className='nav-link' href="https://www.cgmconsulting.it" target="blank">
              <img src="/assets/img/CGM-logo.png" alt="logo" height="40px" />
            </a>
          </footer>
        </Container>
      </Navbar>
    </div>
  </div>
};

export default Home;
