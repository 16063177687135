import { Task } from "gantt-task-react";
import _ from "lodash";
import { ICalendarEvent } from "../../interfaces/Calendar";
import { IRealCourse } from "../../interfaces/RealCourse";
import { IGetTeacher, ITeacherMenu } from "../../interfaces/Teacher";
import { IDate } from "../interfaces/Date";

export const getDarkColor = () => {
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
    }
    return color;
}

// calculating progress
const calculateProgress = (start_date: Date, end_date: Date) => {
    if (new Date().getTime() > start_date.getTime()) {
        const total = end_date.getTime() - start_date.getTime()
        const progress = new Date().getTime() - start_date.getTime()
        return Math.ceil(progress * 100 / total) < 101 ? Math.ceil(progress * 100 / total) : 100
    } else {
        return 0
    }
}

// getting progress value of events or projects 
const getProgress = (project: IRealCourse | ((IGetTeacher | ITeacherMenu) & { start_date: IDate, end_date: IDate }) | null = null, event: ICalendarEvent | null = null) => {
    let progress = 0
    if (project) {
        progress = calculateProgress(new Date(project.start_date.date), new Date(project.end_date.date))
    }
    if (event) {
        progress = calculateProgress(new Date(event.start_date.date), new Date(event.end_date.date))
    }
    return progress
}

// checking if the project is a teacher
const isTeacher = (object: any): object is IGetTeacher | ITeacherMenu => {
    return 'demetra_id' in object;
}

// checking if an event is morning
export const checkIfMorning = (events: ICalendarEvent[]) => {
    if (events.length === 2) {
        return events[0]
    }
    if (events.length === 1) {
        let date = new Date(events[0].end_date.date)
        let limit = _.cloneDeep(date)
        limit.setHours(13)
        limit.setMinutes(0)
        limit.setSeconds(0)
        limit.setMilliseconds(1)
        if (date.getTime() < limit.getTime()) {
            return events[0]
        } else {
            return undefined
        }
    }
}

// checking if an event is afternoon
export const checkIfAfternoon = (events: ICalendarEvent[]) => {
    if (events.length === 2) {
        return events[1]
    }
    if (events.length === 1) {
        let date = new Date(events[0].start_date.date)
        let limit = _.cloneDeep(date)
        limit.setHours(13)
        limit.setMinutes(0)
        limit.setSeconds(0)
        limit.setMilliseconds(1)
        if (date.getTime() > limit.getTime()) {
            return events[0]
        } else {
            return undefined
        }
    }
}

export const groupEventsByDate = (source: ICalendarEvent[]) => {
    // sorting by date
    source.sort((a: ICalendarEvent, b: ICalendarEvent) => {
        return new Date(a.start_date.date).getTime() - new Date(b.start_date.date).getTime()
    })

    // group events merging morning and afternoon
    let step = 0;
    let newEvents: ICalendarEvent[] = []
    if (source.length !== 0)
        for (let i = 0; i < source.length; i = step) {
            let groupTasks = source.filter(task => task.start_date.date.split(' ')[0] === source[i].start_date.date.split(' ')[0])
            const morning = checkIfMorning(groupTasks)
            const afternoon = checkIfAfternoon(groupTasks)

            if (morning && afternoon) {
                newEvents.push({
                    ...morning,
                    name: (morning.description ? (morning.description + ' (' + morning.name + ' - ') : morning.name + ' (') + morning.teacher.last_name + ' ' + (morning.teacher.middle_name || '') + morning.teacher.first_name + ') - '
                        + (afternoon.description ? (afternoon.description + ' (' + afternoon.name + ' - ') : afternoon.name + ' (') + afternoon.teacher.last_name + ' ' + (afternoon.teacher.middle_name || '') + afternoon.teacher.first_name + ')',
                    ids: groupTasks.map(task => task.id).join('+'),
                    start_date: {
                        ...morning.start_date,
                        date: morning.start_date.date
                    },
                    end_date: {
                        ...morning.end_date,
                        date: afternoon.end_date.date
                    }
                })
            }

            if (morning && !afternoon) {
                newEvents.push({
                    ...morning,
                    name: (morning.description ? (morning.description + ' (' + morning.name + ' - ') : morning.name + ' (') + morning.teacher.last_name + ' ' + (morning.teacher.middle_name || '') + morning.teacher.first_name + ')',
                    ids: groupTasks.map(task => task.id).join('+'),
                    start_date: {
                        ...morning.start_date,
                        date: morning.start_date.date
                    },
                    end_date: {
                        ...morning.end_date,
                        date: morning.end_date.date
                    }
                })
            }

            if (!morning && afternoon) {
                newEvents.push({
                    ...afternoon,
                    name: (afternoon.description ? (afternoon.description + ' (' + afternoon.name + ' - ') : afternoon.name + ' (') + afternoon.teacher.last_name + ' ' + (afternoon.teacher.middle_name || '') + afternoon.teacher.first_name + ')',
                    ids: groupTasks.map(task => task.id).join('+'),
                    start_date: {
                        ...afternoon.start_date,
                        date: afternoon.start_date.date
                    },
                    end_date: {
                        ...afternoon.end_date,
                        date: afternoon.end_date.date
                    }
                })
            }
            step = step + groupTasks.length
        }
    return newEvents
}

// generating events as tasks for the gantt chart (project + events)
export const generateTasksFromEvents = (events: ICalendarEvent[], project?: IRealCourse | ((IGetTeacher | ITeacherMenu) & { start_date: IDate, end_date: IDate }), hideChildren: boolean = false): Task[] => {

    if (project) {
        const newProject: Task = {
            name: isTeacher(project) ? project.last_name + ' ' + (project.middle_name || '') + project.first_name : project.name + " (" + project.teacher.last_name + ' ' + (project.teacher.middle_name || '') + project.teacher.first_name + ")",
            start: new Date(project.start_date.date),
            end: new Date(project.end_date.date),
            id: isTeacher(project) ? 'teacher-' + project.id : 'course-' + project.id,
            hideChildren,
            type: 'project',
            progress: getProgress(project, null),
            styles: {
                backgroundColor: "#02679a",
                backgroundSelectedColor: "#ec8a4a",
                progressColor: '#ec8a4a',
                progressSelectedColor: '#02679a'
            },
        }

        const newTasks: Task[] = events.map((event, index) => {
            return {
                name: event.name,
                start: new Date(event.start_date.date),
                end: new Date(event.end_date.date),
                id: isTeacher(project) ? 'teacher-' + project.id + '-event-' + event.ids : 'course-' + project.id + '-event-' + event.ids,
                project: isTeacher(project) ? 'teacher-' + project.id : 'course-' + project.id,
                type: 'task',
                progress: getProgress(null, event),
                styles: {
                    backgroundColor: "#02679a",
                    backgroundSelectedColor: "#212121",
                    progressColor: '#ec8a4a',
                    progressSelectedColor: '#212121'
                },
            }
        })

        newTasks.unshift(newProject)
        return newTasks;
    }
    return [];
}