import { faArrowRight, faDownload, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useEffect, useState } from "react"
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap"
import CustomFileView from "../../../common/components/CustomFileView/CustomFileView"
import { IFile } from "../../../interfaces/File"
import { IStudentFile } from "../../../interfaces/StudentFile"
import fileService from "../../../services/FileService"
import studentFileService from "../../../services/StudentFileService"
import StudentFileModal from "./StudentFileModal"

const StudentFile: FC<{ studentId: number, reload?: (reload: boolean) => void }> = (props) => {

    // generic data
    const { studentId, reload } = props
    const [files, setFiles] = useState<IStudentFile[]>([])
    const [refresh, setRefresh] = useState(false);

    // modal
    const [showModal, setShowModal] = useState(false);
    const [currentStudentFile, setCurrentStudentFile] = useState<IStudentFile | undefined>(undefined)
    const [currentFile, setCurrentFile] = useState<IFile | undefined>(undefined)
    const [currentId, setCurrentId] = useState<number | undefined>(undefined);
    const [action, setAction] = useState('');

    useEffect(() => {
        studentFileService.getAll(studentId).then(
            files => setFiles(files)
        )
    }, [studentId])

    useEffect(() => {
        if (refresh === true && studentId) {
            if (reload)
                reload(true)
            studentFileService.getAll(studentId).then(
                files => {
                    setFiles(files)
                    setRefresh(false)
                },
                error => setRefresh(false)
            )
        }
    }, [refresh, reload, studentId]);

    const onPreviewFile = (file?: IStudentFile) => {
        if (file)
            fileService.preview(file.file.id)
    }

    const onDownloadFile = (file?: IStudentFile) => {
        if (file)
            fileService.download(file.file.id)
    }

    const onAddClick = () => {
        setAction('add');
        setShowModal(true);
    }

    const onDeleteClick = (file: IStudentFile) => {
        if (file) {
            setCurrentStudentFile(file)
            setAction('delete')
            setCurrentId(file.id)
            setShowModal(true);
        }
    }

    const onEditClick = (file: IStudentFile) => {
        fileService.get(file.file.id)
            .then(
                res => {
                    setAction('edit')
                    setCurrentFile(res);
                    setCurrentId(res.id);
                    setShowModal(true);
                }
            )
    }

    return <div className="d-flex flex-column pt-3">
        <StudentFileModal
            studentFile={currentStudentFile}
            currentFile={currentFile}
            studentId={studentId}
            id={currentId}
            show={showModal}
            close={(action?: string) => {
                setShowModal(false);
                setAction('')
                setCurrentId(undefined);
                setCurrentFile(undefined);
                setCurrentStudentFile(undefined);
                if (typeof action === 'string')
                    setRefresh(true);
            }}
            action={action}
        />
        <Card className="shadow-sm h-100">
            <Card.Header className="d-flex align-items-center justify-content-between">
                <span className="fw-600">Allegati</span>
                <button className='btn btn-success rounded-pill d-flex align-items-center' onClick={onAddClick}><FontAwesomeIcon icon={faPlus} className="me-1" />Aggiungi</button>
            </Card.Header>
            {
                files.length > 0 ? <Card.Body>
                    {
                        files.map(file => <div key={file.id} className="d-flex col-12 align-items-center">
                            <div className="col-3">
                                <Dropdown as={ButtonGroup} className="shadow-sm">
                                    <Button className='btn btn-light' onClick={() => onPreviewFile(file)}>
                                        <CustomFileView file={file.file} />
                                    </Button>
                                    <Dropdown.Toggle split id="dropdown-split-basic" className='btn btn-light' />
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => onPreviewFile(file)}><FontAwesomeIcon icon={faArrowRight} className="me-1" />Anteprima</Dropdown.Item>
                                        <Dropdown.Item onClick={() => onDownloadFile(file)}><FontAwesomeIcon icon={faDownload} className="me-1" />Download</Dropdown.Item>
                                        <Dropdown.Item onClick={() => onEditClick(file)}><FontAwesomeIcon icon={faPen} className="me-1" />Modifica</Dropdown.Item>
                                        <Dropdown.Item onClick={() => onDeleteClick(file)}><FontAwesomeIcon icon={faTrash} className="me-1" />Elimina</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <Card.Text className="col-9 ms-2">
                                {file.file.description || '-'}
                            </Card.Text>
                        </div>
                        )
                    }
                </Card.Body> : null
            }
        </Card>
    </div>
}

export default StudentFile;