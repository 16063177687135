import { ViewMode } from "gantt-task-react";
import { FC } from "react"
import Select, { ActionMeta, SingleValue } from "react-select";
import { ICustomViewSwitchProps } from "../../interfaces/CustomViewSwitch";

interface ICustomViewOption {
    value: ViewMode,
    label: string
}

// rendering the gantt chart's filter view options (customized)
const CustomViewSwitch: FC<ICustomViewSwitchProps> = (props) => {

    // view options based on a specific period of time
    const viewOptions: ICustomViewOption[] = [
        { value: ViewMode.Hour, label: 'Visualizza per ore' },
        { value: ViewMode.Day, label: 'Visualizza per giorni' },
        { value: ViewMode.Week, label: 'Visualizza per settimane' },
        { value: ViewMode.Month, label: 'Visualizza per mesi' },
        { value: ViewMode.Year, label: 'Visualizza per anni' },
    ]

    const { setView, setColumnWidth, setIsChecked, isChecked, showTasksLabel, view } = props

    // setting the columns width based on the chosen period of time
    const onViewChange = (newValue: SingleValue<ICustomViewOption>, actionMeta: ActionMeta<ICustomViewOption>) => {
        if (newValue) {
            setView(newValue.value)
            switch (newValue.value) {
                case ViewMode.Hour:
                    return setColumnWidth(50)
                case ViewMode.Day:
                    return setColumnWidth(70)
                case ViewMode.Week:
                    return setColumnWidth(150)
                case ViewMode.Month:
                    return setColumnWidth(200)
                case ViewMode.Year:
                    return setColumnWidth(300)
                default:
                    return undefined
            }
        }

    }

    return <div className="d-flex align-items-center my-3">
        <Select
            placeholder="Filtra visualizzazione..."
            name="selectedView"
            value={viewOptions.find(option => option.value === view)}
            onChange={onViewChange}
            options={viewOptions}
            className="col-3 me-4"
        />
        <div className="form-check form-switch col-3 me-4">
            <input
                id="flexSwitchCheckDefault"
                className='form-check-input'
                type="checkbox"
                defaultChecked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                {showTasksLabel}
            </label>
        </div>
    </div>
}

export default CustomViewSwitch

