import { FC, useEffect, useState } from "react";
import { MUIDataTableMeta } from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TitoloStudio from "../../../interfaces/Qualification";
import qualificationsService from "../../../services/QualificationsService";
import QualificationsModal from "./QualificationsModal";
import QualificationsColumns from "../../../config/tableColumns/QualificationsColumns";

const Qualifications: FC<{}> = () => {
  const [qualifications, setQualifications] = useState<TitoloStudio[]>([]);
  const [currentQualification, setCurrentQualification] = useState<TitoloStudio | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState("");

  const getAllQualifications = () =>
    qualificationsService.getAll().then((res) => setQualifications(res));
  useEffect(() => {
    getAllQualifications();
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getAllQualifications();
      setRefresh(false);
    }
  }, [refresh]);

  const onEditClick = (tableMeta: MUIDataTableMeta<TitoloStudio>) => {
    if (tableMeta.rowData[0]) {
      qualificationsService.get(tableMeta.rowData[0]).then(
        (res) => {
          setAction("edit");
          setCurrentId(tableMeta.rowData[0]);
          setCurrentQualification(res);
          setShowModal(true);
        }
      );
    }
  };

  const onDeleteClick = (tableMeta: MUIDataTableMeta<TitoloStudio>) => {
    if (tableMeta.rowData[0]) {
      setAction("delete");
      setCurrentId(tableMeta.rowData[0]);
      setShowModal(true);
    }
  };

  return (
    <div className="container-fluid">
      <QualificationsModal
        qualification={currentQualification}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction("");
          setCurrentQualification(undefined);
          setCurrentId(undefined);
          if (typeof action === "string") setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Titoli di studio</h4>
        <button
          className="btn btn-success rounded-pill"
          onClick={() => {
            setShowModal(true);
            setAction("add");
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="me-1" />
          Crea un titolo
        </button>
      </div>
      <QualificationsColumns
        qualifications={qualifications}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
    </div>
  );
}

export default Qualifications;
