import React, { Component } from 'react';
import './CustomSpinner.css';

// rendering the loading spinner (customized)
export default class CustomSpinner extends Component<{}> {
    render() {
        return <div className='spinner-container fixed-top'>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }
}