import axios from "axios";
import { IStudent, IStudentsResponse, IStudentForm, IGetStudent, IStudentMenu, IStudentMenuResponse } from "../interfaces/Student";
class StudentsService {

    private baseUrl = process.env.REACT_APP_API_URL

    mapDataForTable = (students: IGetStudent[]): IStudent[] => {
        return students.map(
            student => {
                return {
                    id: student.id,
                    first_name: student.last_name + ' ' + (student.middle_name || '') + student.first_name,
                    email: student.email1,
                    phone_cell: student.phone_cell,
                    qualification: student.qualification.name,
                    contract_type: student.student_internal ? student.student_internal.contract_type.name : '',
                    source: student.source.name,
                    status: student.status.name
                }
            }
        )
    }

    async getAllForTable(): Promise<IStudent[]> {
        return await axios.get<IStudentsResponse>(this.baseUrl + '/students').then(response => this.mapDataForTable(response.data.data))
    }

    async getAllForMenu(): Promise<IStudentMenu[]> {
        return await axios.get<IStudentMenuResponse>(this.baseUrl + '/students/menu').then(response => response.data.data)
    }

    async getAll(): Promise<IGetStudent[]> {
        return await axios.get<IStudentsResponse>(this.baseUrl + '/students').then(response => response.data.data)
    }

    async get(id: number): Promise<IGetStudent> {
        return await axios.get(this.baseUrl + '/student/' + id).then(response => response.data.data)
    }

    async post(body: IStudentForm): Promise<IStudentsResponse> {
        return await axios.post(this.baseUrl + '/student', body).then(response => response.data.data)
    }

    async put(id: number, body: IStudentForm): Promise<IStudentsResponse> {
        return await axios.put(this.baseUrl + '/student/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/student/' + id).then(response => response.data.data)
    }
}

const studentsService = new StudentsService();

export default studentsService;
