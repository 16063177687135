import * as Yup from "yup";
import CustomModal from "../../common/components/CustomModal/CustomModal";
import { getTeachersFields } from "../../config/formFields/TeachersFields";
import { ITeacherForm, IGetTeacher } from "../../interfaces/Teacher";
import teachersService from "../../services/TeachersService";
import { FC } from "react";
import { IModalProps } from "../../common/interfaces/CustomModal";
import { ICustomFormProps } from "../../common/interfaces/CustomForm";
import IQualification from "../../interfaces/Qualification";
import { IPeopleAvailable } from "../../interfaces/Person";

interface ITeachersModalProps {
    data: {
        teacher: IGetTeacher | undefined,
        qualifications: IQualification[],
        availablePeople: IPeopleAvailable[]
    };
    show: boolean;
    close: (action?: string) => void;
    action: string;
    id: number | undefined
}

const TeachersModal: FC<ITeachersModalProps> = (props) => {
    const { id, action, show, close, data } = props;

    const qualifications = data.qualifications.map(qualification => { return { value: qualification.id || 0, label: qualification.name } });
    const availablePeople = data.availablePeople.map(person => { return { value: person.id, label: person.last_name + ' ' + (person.middle_name || '') + person.first_name } });

    const docenteForForm = {
        demetra_id: data.teacher?.demetra_id,
        activity: data.teacher?.activity,
        assessment_selection: data.teacher?.assessment_selection,
        assessment_course: data.teacher?.assessment_course,
        note: data.teacher?.note,
        VAT_number: data.teacher?.VAT_number,
        gender: data.teacher?.gender,
        nationality: data.teacher?.nationality,
        tax_code: data.teacher?.tax_code,
        qualification_id: data.teacher?.qualification.id,
        hourly_fee: data.teacher?.hourly_fee || '0.00'
    };

    const validationSchema = Yup.object().shape({
        demetra_id: Yup.number().required("* Campo obbligatorio"),
        activity: Yup.string().nullable(),
        hourly_fee: Yup.string().nullable(),
        assessment_selection: Yup.string().nullable(),
        assessment_course: Yup.number().nullable(),
        note: Yup.string().nullable(),
        VAT_number: Yup.number().test(
            "len",
            "Il valore deve essere composto da 11 numeri",
            (val) => {
                if (val)
                    return val.toString().length === 11
                else
                    return true
            }
        ),
        gender: Yup.string().nullable(),
        nationality: Yup.string().required("* Campo obbligatorio"),
        qualification_id: Yup.number().required("* Campo obbligatorio"),
        tax_code: Yup.string().nullable().length(16, "Il valore deve essere composto da 16 caratteri").nullable()
    })

    const handleSubmit = async (values: ITeacherForm) => {
        const body = { ...values, VAT_number: Number(values.VAT_number), assessment_course: values.assessment_course !== 0 ? values.assessment_course : null }
        if (action === "add") {
            teachersService.post(body).then(() => close("add"))
        }
        if (action === "edit" && id) {
            teachersService.put(id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            teachersService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: data.teacher ? 'Modifica i dati di "' + data.teacher.last_name + ' ' + (data.teacher.middle_name || '') + data.teacher.first_name + '"' : "Aggiungi un nuovo docente"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: getTeachersFields(availablePeople, qualifications, action),
        initialValues: docenteForForm,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default TeachersModal;
