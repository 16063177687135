import axios from "axios"
import { format } from "date-fns"
import { ICorsiRealiResponse, IRealCourse, IRealCourseBody, IRealCourseMenu, IRealCourseMenuResponse, ITableRealCourse } from "../interfaces/RealCourse"

class RealCoursesService {

    private baseUrl = process.env.REACT_APP_API_URL

    mapDataForTable = (courses: IRealCourse[]): ITableRealCourse[] => {
        return courses.map(
            course => {
                return {
                    id: course.id,
                    name: course.name,
                    course_name: course.course.name,
                    start_date: format(new Date(course.start_date.date), "dd/MM/yyyy"),
                    end_date: course.end_date ? format(new Date(course.end_date.date), "dd/MM/yyyy") : '-',
                    duration: course.course.duration + ' ore'
                }
            }
        )
    }

    async getAllForTable(): Promise<ITableRealCourse[]> {
        return await axios.get<ICorsiRealiResponse>(this.baseUrl + "/course/real").then(response => this.mapDataForTable(response.data.data))
    }

    async getAllForMenu(): Promise<IRealCourseMenu[]> {
        return await axios.get<IRealCourseMenuResponse>(this.baseUrl + '/course/real/menu').then(response => response.data.data)
    }

    async getAll(): Promise<IRealCourse[]> {
        return await axios.get<ICorsiRealiResponse>(this.baseUrl + "/course/real").then(response => response.data.data)
    }

    async get(id: number): Promise<IRealCourse> {
        return await axios.get(this.baseUrl + "/course/real/" + id).then(response => response.data.data)
    }

    async post(body: IRealCourseBody): Promise<IRealCourse> {
        return await axios.post(this.baseUrl + '/course/real', body).then(response => response.data.data)
    }

    async put(id: number, body: IRealCourseBody): Promise<IRealCourse> {
        return await axios.put(this.baseUrl + '/course/real/' + id, body).then(response => response.data.data)
    }

    async move(id: number, start_date: string): Promise<IRealCourse> {
        return await axios.put(this.baseUrl + '/course/real/' + id + '/move', { start_date }).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/course/real/' + id).then(response => response.data.data)
    }
}

const realCoursesService = new RealCoursesService();

export default realCoursesService;