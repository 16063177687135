import * as Yup from 'yup';
import CustomModal from '../../../common/components/CustomModal/CustomModal';
import ISource from '../../../interfaces/Source';
import sourcesService from '../../../services/SourcesService';
import { sourcesFields } from '../../../config/formFields/SourcesFields';
import { IModalProps } from '../../../common/interfaces/CustomModal';
import { ICustomFormProps } from '../../../common/interfaces/CustomForm';
import { FC } from 'react';

interface ISourcesModalProps {
    source: ISource | undefined,
    id: number | undefined
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const SourcesModal: FC<ISourcesModalProps> = (props) => {
    const { source, id, show, close, action } = props

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        code: Yup.string().required("* Campo obbligatorio").length(3, "La lunghezza deve essere di 3"),
    })

    const handleSubmit = async (values: ISource) => {
        const body = { ...values, code: values.code.toUpperCase() }
        if (action === "add") {
            sourcesService.post(body).then(() => close("add"))
        }
        if (action === "edit" && id) {
            sourcesService.put(id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            sourcesService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: source ? 'Modifica i dati di "' + source.name + '"' : "Aggiungi una nuova sorgente"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: sourcesFields,
        initialValues: source,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default SourcesModal;