import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import IContractType from '../../interfaces/ContractType';

const ContractTypesColumns = (props: {
    onEditClick: (tableMeta: MUIDataTableMeta<IContractType>) => void,
    onDeleteClick: (tableMeta: MUIDataTableMeta<IContractType>) => void,
    contractTypes: IContractType[]
}) => {

    const { contractTypes, onEditClick, onDeleteClick } = props

    const contractTypesColumns = [
        {
            name: "id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "name",
            label: "Nome",
        },
        {
            name: "code",
            label: "Codice",
        },
        {
            name: "azioni",
            label: "Azioni",
            options: {
                customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IContractType>, updateValue: any) => (
                    <>
                        <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
                        <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>
                )
            }
        },
    ]

    return (
        <CustomTable
            title={"Elenco di tutte le tipologie di contratto disponibili"}
            data={contractTypes}
            columns={contractTypesColumns}
        />
    )
}

export default ContractTypesColumns;