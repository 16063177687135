import React, { FC } from 'react';
import * as Yup from 'yup';
import CustomModal from '../../../common/components/CustomModal/CustomModal';
import ICourseType from '../../../interfaces/CourseType';
import courseTypesService from '../../../services/CourseTypesService';
import { courseTypesFields } from '../../../config/formFields/CourseTypesFields';
import { IModalProps } from '../../../common/interfaces/CustomModal';
import { ICustomFormProps } from '../../../common/interfaces/CustomForm';

interface ICourseTypesModalProps {
  courseType: ICourseType | undefined,
  id: number | undefined,
  show: boolean,
  close: (action?: string) => void,
  action: string
}

const CourseTypesModal: FC<ICourseTypesModalProps> = (props) => {

  const { courseType, id, show, close, action } = props

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("* Campo obbligatorio"),
    code: Yup.string().required("* Campo obbligatorio").length(3, "La lunghezza deve essere di 3"),
  })

  const handleSubmit = async (values: ICourseType) => {
    const body = { ...values, code: values.code.toUpperCase() }
    if (action === "add") {
      courseTypesService.post(body).then(() => close("add"))
    }
    if (action === "edit" && id) {
      courseTypesService.put(id, body).then(() => close("edit"))
    }
  }

  const onConfirm = () => {
    if (action === "delete" && id) {
      courseTypesService.delete(id).then(() => close("delete"))
    }
  }

  const modalProps: IModalProps = {
    close,
    confirm: onConfirm,
    show,
    action,
    label: courseType ? 'Modifica i dati di "' + courseType.name + '"' : "Aggiungi una nuova tipologia di corso"
  }

  const formProps: ICustomFormProps = {
    close,
    fields: courseTypesFields,
    initialValues: courseType,
    onSubmit: handleSubmit,
    validationSchema
  }

  return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default CourseTypesModal;