import React, { FC } from 'react';
import * as Yup from 'yup';
import CustomModal from '../../../common/components/CustomModal/CustomModal';
import IContractType from '../../../interfaces/ContractType';
import contractTypesService from '../../../services/ContractTypesService';
import { contractTypesFields } from '../../../config/formFields/ContractTypesFields';
import { IModalProps } from '../../../common/interfaces/CustomModal';
import { ICustomFormProps } from '../../../common/interfaces/CustomForm';

interface IContractTypesModalProps {
    contractType: IContractType | undefined,
    id: number | undefined,
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const ContractTypesModal: FC<IContractTypesModalProps> = (props) => {

    const { contractType, id, show, close, action } = props

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        code: Yup.string().required("* Campo obbligatorio").length(3, "La lunghezza deve essere di 3"),
    })

    const handleSubmit = async (values: IContractType) => {
        const body = { ...values, code: values.code.toUpperCase() }
        if (action === "add") {
            contractTypesService.post(body).then(() => close("add"))
        }
        if (action === "edit" && id) {
            contractTypesService.put(id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            contractTypesService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: contractType ? 'Modifica i dati di "' + contractType.name + '"' : "Aggiungi una nuova tipologia di contratto"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: contractTypesFields,
        initialValues: contractType,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default ContractTypesModal;