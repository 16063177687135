import { FC, useEffect, useState } from "react";
import { ClassroomStudentColumns } from "../../../../config/tableColumns/ClassroomDetailsColumns";
import { IClassroomStudent } from "../../../../interfaces/ClassroomStudent";
import { IStudent } from "../../../../interfaces/Student";
import classroomStudentsService from "../../../../services/ClassroomStudentsService";
import studentsService from "../../../../services/StudentsService";
import ClassroomDetailsModalStudent from "./ClassroomDetailsModalStudent";

interface IClassroomStudentsProps {
    classroomId: string | undefined
}

const ClassroomStudents: FC<IClassroomStudentsProps> = (props) => {

    const { classroomId } = props

    const [internalStudents, setInternalStudents] = useState<IClassroomStudent[]>([]);
    const [tableInternalStudents, setTableInternalStudents] = useState<IStudent[]>([]);
    const [tableExternalStudents, setTableExternalStudents] = useState<IStudent[]>([]);
    const [loadStudents, setLoadStudents] = useState<boolean>(false)
    const [addStudent, setAddStudent] = useState<boolean>(false)
    const [currentStudent, setCurrentStudent] = useState<IClassroomStudent | undefined>(undefined);
    const [currentStudentId, setCurrentStudentId] = useState<number | undefined>(undefined);
    const [actionStudent, setActionStudent] = useState('');
    const [showStudentModal, setShowStudentModal] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (classroomId)
            classroomStudentsService.getAll(classroomId).then(
                res => {
                    setInternalStudents(res)
                    setTableInternalStudents(studentsService.mapDataForTable(res.map(s => { return { ...s.student, id: s.id } }) || []))
                }
            )
    }, [classroomId])

    useEffect(() => {
        if (loadStudents)
            studentsService.getAll().then(
                res => {
                    const intStudents = internalStudents.map(s => s.student)
                    const extStudents = res.filter(x => !intStudents.find(rm => (rm.id === x.id)))
                    setTableExternalStudents(studentsService.mapDataForTable(extStudents))
                    setLoadStudents(false)
                },
                err => setLoadStudents(false)
            )
    }, [loadStudents, internalStudents])

    useEffect(() => {
        if (refresh === true && classroomId) {
            classroomStudentsService.getAll(classroomId).then(
                res => {
                    setInternalStudents(res)
                    setTableInternalStudents(studentsService.mapDataForTable(res.map(s => { return { ...s.student, id: s.id } }) || []))
                    setAddStudent(false)
                    setTableExternalStudents([])
                    setRefresh(false)
                },
                err => setRefresh(false)
            )
        }
    }, [refresh, classroomId]);

    const onAddStudentClick = (studentId: number) => {
        studentsService.get(studentId).then(
            res => {
                setCurrentStudent({
                    id: res.id,
                    note: "",
                    student: res
                })
                setCurrentStudentId(res.id)
                setActionStudent('add');
                setShowStudentModal(true);
            }
        )
    }

    const onDeleteStudentClick = (studentId: number) => {
        if (studentId) {
            setActionStudent('delete')
            setCurrentStudentId(studentId)
            setShowStudentModal(true);
        }
    }

    const onEditStudentClick = (studentId: number) => {
        classroomStudentsService.get(Number(classroomId), studentId).then(
            res => {
                setCurrentStudent(res)
                setCurrentStudentId(res.id)
                setActionStudent('edit');
                setShowStudentModal(true);
            }
        )
    }

    return <>
        <ClassroomDetailsModalStudent
            currentStudent={currentStudent}
            classroomId={Number(classroomId)}
            id={currentStudentId}
            show={showStudentModal}
            close={(action?: string) => {
                setShowStudentModal(false);
                setActionStudent('')
                setCurrentStudent(undefined);
                setCurrentStudentId(undefined);
                if (typeof action === 'string')
                    setRefresh(true);
            }}
            action={actionStudent}
        />
        <div className="d-flex justify-content-end">
            <button className="btn btn-link" onClick={() => { setAddStudent(!addStudent); if (addStudent === false) setLoadStudents(true) }}>{addStudent ? "Nascondi gli studenti esterni" : "Aggiungi studente all'aula"}</button>
        </div>
        <ClassroomStudentColumns classroomId={classroomId} title="Studenti di questa aula" data={internalStudents} tableData={tableInternalStudents} onEditClick={onEditStudentClick} onDeleteClick={onDeleteStudentClick} />
        {
            addStudent && <>
                <hr />
                <ClassroomStudentColumns classroomId={classroomId} title="Aggiungi studenti a questa aula" tableData={tableExternalStudents} onAddClick={onAddStudentClick} />
            </>
        }
    </>
}

export default ClassroomStudents;