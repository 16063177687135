import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import CustomTabs from "../../common/components/CustomTabs/CustomTabs";
import { ITab } from "../../common/interfaces/CustomTabs";
import { IRealCourse } from "../../interfaces/RealCourse";
import realCoursesService from "../../services/RealCoursesService";
import reportsService from "../../services/ReportsService";
import RealCourses from "../Courses/RealCourses/RealCourses";
import GenerateReportModal from "./GenerateReportModal";

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = () => {

  const [dashboardRealCourses, setDashboardRealCourses] = useState<IRealCourse[]>([])
  const [reports, setReports] = useState<{ value: number, label: string, infoLabel: string }[]>([])
  const [showModal, setShowModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true)

  useEffect(() => {
    if (refresh)
      realCoursesService.getAll().then(
        courses => {
          setDashboardRealCourses(courses)
          setRefresh(false)
        },
        () => setRefresh(false)
      )
  }, [refresh])

  // handling generate report event
  const onGenerateReport = () => {
    reportsService.getAll()
      .then(
        res => {
          setReports(res.map(report => { return { value: report.id, label: report.description, infoLabel: report.tooltip } }))
          setShowModal(true);
        }
      )
  }

  // all tabs render RealCourses component with different props
  const tabs: ITab[] = [
    {
      id: "corsi-attivi",
      label: "Corsi attivi",
      content: <RealCourses period='now' dashboardRealCourses={dashboardRealCourses} isUpdating={(value) => setRefresh(value)} />,
      isActive: true
    },
    {
      id: "corsi-futuri",
      label: "Corsi futuri",
      content: <RealCourses period='future' dashboardRealCourses={dashboardRealCourses} isUpdating={(value) => setRefresh(value)} />,
      isActive: false
    },
    {
      id: "corsi-passati",
      label: "Corsi passati",
      content: <RealCourses period='past' dashboardRealCourses={dashboardRealCourses} isUpdating={(value) => setRefresh(value)} />,
      isActive: false
    }
  ]

  return <div className="container-fluid">
    <GenerateReportModal
      reports={reports}
      show={showModal}
      close={(action?: SyntheticEvent | string) => {
        setShowModal(false);
        if (typeof action === 'string')
          setRefresh(true);
      }}
    />
    <div className="d-flex align-items-center justify-content-between mb-3">
      <h4 className='mb-3'>Dashboard</h4>
      <button className='btn btn-primary rounded-pill' onClick={onGenerateReport}>
        <FontAwesomeIcon icon={faDownload} className="me-1" />Genera report
      </button>
    </div>
    <CustomTabs id="dashboard-tabs" tabs={tabs} useCustomState={false}></CustomTabs>
  </div>
}

export default Dashboard;
