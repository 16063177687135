import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AuthHandler } from './common/components/AuthHandler/AuthHandler';
import { AppRouter } from './router/AppRouter';

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    // fontSize: 14
  }
})

// initiali<ing router, MUI theme and Auth handler
function App() {
  return (
    <BrowserRouter>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <AuthHandler>
            <AppRouter />
          </AuthHandler>
        </ThemeProvider>
      </CacheProvider>
    </BrowserRouter>
  );
}

export default App;

