import axios from "axios";
import IContractType from "../interfaces/ContractType";

class ContractTypesService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IContractType[]> {
        return await axios.get(this.baseUrl + "/student/contractTypes").then(response => response.data.data)
    }

    async get(id: number): Promise<IContractType> {
        return await axios.get(this.baseUrl + "/student/contractType/" + id).then(response => response.data.data)
    }

    async post(body: IContractType): Promise<IContractType> {
        return await axios.post(this.baseUrl + '/student/contractType', body).then(response => response.data.data)
    }

    async put(id: number, body: IContractType): Promise<IContractType> {
        return await axios.put(this.baseUrl + '/student/contractType/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/student/contractType/' + id).then(response => response.data.data)
    }
}

const contractTypesService = new ContractTypesService();

export default contractTypesService;