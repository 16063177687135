import React, { FC } from 'react';
import IFund from '../../../interfaces/Fund';
import * as Yup from 'yup';
import CustomModal from '../../../common/components/CustomModal/CustomModal';
import fundsService from '../../../services/FundsService';
import { fundsFields } from '../../../config/formFields/FundsFields';
import { IModalProps } from '../../../common/interfaces/CustomModal';
import { ICustomFormProps } from '../../../common/interfaces/CustomForm';

interface IFundsModalProps {
    fund: IFund | undefined,
    id: number | undefined,
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const FundsModal: FC<IFundsModalProps> = (props) => {
    const { fund, id, show, close, action } = props

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        code: Yup.string().required("* Campo obbligatorio").length(3, "La lunghezza deve essere di 3"),
    })

    const handleSubmit = async (values: IFund) => {
        const body = { ...values, code: values.code.toUpperCase() }
        if (action === "add") {
            fundsService.post(body).then(() => close("add"))
        }
        if (action === "edit" && id) {
            fundsService.put(id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            fundsService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: fund ? 'Modifica i dati di "' + fund.name + '"' : "Aggiungi un nuovo fondo formativo"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: fundsFields,
        initialValues: fund,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>

}

export default FundsModal;