import { Navigate, Route, Routes } from "react-router-dom";
import { FC } from "react";
import Login from "../components/Login/Login";
import Home from "../components/Home/Home";
import GenericCourses from "../components/Courses/GenericCourses/GenericCourses";
import Dashboard from "../components/Dashboard/Dashboard";
import Teachers from "../components/Teachers/Teachers";
import { AuthContext } from "../common/components/AuthHandler/AuthHandler";
import Funds from "../components/Database/Fund/Funds";
import Platforms from "../components/Database/Platforms/Platforms";
import CourseTypes from "../components/Database/CourseTypes/CourseTypes";
import Students from "../components/Students/Students";
import Qualifications from "../components/Database/Qualifications/Qualifications";
import Sources from "../components/Database/Sources/Sources";
import ContractTypes from "../components/Database/ContractTypes/ContractTypes";
import Users from "../components/Users/Users";
import RealCourses from "../components/Courses/RealCourses/RealCourses";
import Calendars from "../components/Calendars/Calendars";
import ClassroomsDetails from "../components/Classrooms/ClassroomDetails/ClassroomsDetails";
import RealCourseCalendar from "../components/Courses/RealCourses/RealCourseCalendar/RealCourseCalendar";
import StudentStatuses from "../components/Database/StudentStatuses/StudentStatuses";
import ClassroomsTabs from "../components/Classrooms/ClassroomsTabs";
import EmplyomentAgencies from "../components/Database/EmploymentAgencies/EmploymentAgencies";

export const AppRouter: FC<{}> = () => {

  return (
    <Routes>
      <Route path="login" element={<Login />}></Route>
      <Route path="/" element={
        <AuthContext.Consumer>
          {({ auth }) => {
            return auth !== null ? <Home /> : <Navigate to="/login" replace />
          }}
        </AuthContext.Consumer>
      }>
        <Route index element={<Navigate to="dashboard" replace />}></Route>
        <Route path="dashboard" element={<Dashboard />}></Route>
        <Route path="calendari" element={<Calendars />}></Route>
        <Route path="corsi-generici" element={<GenericCourses />}></Route>
        <Route path="corsi-reali" element={<RealCourses />}></Route>
        <Route path="docenti" element={<Teachers />}></Route>
        <Route path="studenti" element={<Students />}></Route>
        <Route path="fondi" element={<Funds />}></Route>
        <Route path="piattaforme" element={<Platforms />}></Route>
        <Route path="tipologie-corsi" element={<CourseTypes />}></Route>
        <Route path="titoli-studio" element={<Qualifications />}></Route>
        <Route path="sorgenti" element={<Sources />}></Route>
        <Route path="tipologie-contratti" element={<ContractTypes />}></Route>
        <Route path="stati-studenti" element={<StudentStatuses />}></Route>
        <Route path="apl" element={<EmplyomentAgencies />}></Route>
        <Route path="utenti" element={<Users />}></Route>
        <Route path="aule" element={<ClassroomsTabs />}></Route>
        <Route path="aule/:id" element={<ClassroomsDetails />}></Route>
        <Route path="corsi-reali/:id/calendario" element={<RealCourseCalendar />}></Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />}></Route>
    </Routes>
  );
}
