import { format } from "date-fns";
import { Gantt, GanttProps } from "gantt-task-react";
import { FC } from "react";
import { ICustomCalendarProps, ICustomListProps } from "../../interfaces/CustomCalendar";
import { ICustomViewSwitchProps } from "../../interfaces/CustomViewSwitch";
import CustomTableHeader from "./CustomTableHeader";
import CustomTaskListTable from "./CustomTaskListTable";
import CustomTooltip from "./CustomTooltip";
import CustomViewSwitch from "./CustomViewSwitch";

// rendering calendars' gantt for teachers and real courses (customized)
const CustomCalendar: FC<ICustomCalendarProps & GanttProps & ICustomViewSwitchProps> = (props) => {

    const {
        realCourses,
        teachers,
        tasks,
        viewMode,
        onDateChange,
        onDelete,
        onProgressChange,
        onClick,
        onDoubleClick,
        onSelect,
        onExpanderClick,
        viewTasks,
        columnWidth,
        setView,
        setColumnWidth,
        setIsChecked,
        isChecked,
        showTasksLabel,
        calendarEvents,
        onOptionClick
    } = props

    const customProps: ICustomListProps = {
        includeTime: true,
        onOptionClick,
        formatDateShort: (value, includeTime) => {
            let date = format(value, "dd/MM/yyyy")
            if (includeTime) {
                let time = format(value, "HH:mm")
                return date + ' ore ' + time
            }
            return date
        },
    }

    return <>
        <CustomViewSwitch
            setView={setView}
            setColumnWidth={setColumnWidth}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            showTasksLabel={showTasksLabel}
            view={viewMode}
        ></CustomViewSwitch>
        {
            tasks.length > 0 && <Gantt
                tasks={tasks}
                viewMode={viewMode}
                onDateChange={onDateChange}
                onDelete={onDelete}
                onProgressChange={onProgressChange}
                onDoubleClick={onDoubleClick}
                onClick={onClick}
                onSelect={onSelect}
                onExpanderClick={onExpanderClick}
                columnWidth={columnWidth}
                TaskListHeader={(props) => { return viewTasks ? <CustomTableHeader {...props} /> : null }}
                TaskListTable={(props) => { return viewTasks ? <CustomTaskListTable {...props} {...customProps} /> : null }}
                TooltipContent={(props) => <CustomTooltip events={calendarEvents} realCourses={realCourses} teachers={teachers} {...props} />}
                locale={'it'}
                fontFamily="'Montserrat', sans-serif"
                fontSize="13px"
            />}
    </>
}

export default CustomCalendar;