export const setClassroomValuationsFields = (
    teachers: { value: number, label: string }[],
    students: { value: number, label: string }[],
) => {
    if (students.length === 0)
        return [
            {
                name: 'has_teacher_id',
                type: 'select',
                options: teachers,
                placeholder: 'Seleziona un docente',
                label: 'Mittente (docente)',
            },
            {
                name: 'note',
                type: 'textarea',
                placeholder: 'Inserisci le note',
                label: 'Note',
            },
            {
                name: 'vote',
                type: 'select',
                options: [
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" }
                ],
                placeholder: 'Seleziona un voto',
                label: 'Voto',
            },
        ]
    else
        return [
            {
                name: 'has_student_id',
                type: 'select',
                options: students,
                placeholder: 'Seleziona uno studente',
                label: 'Mittente (studente)',
            },
            {
                name: 'note',
                type: 'textarea',
                placeholder: 'Inserisci le note',
                label: 'Note',
            },
            {
                name: 'vote',
                type: 'select',
                options: [
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" }
                ],
                placeholder: 'Seleziona un voto',
                label: 'Voto',
            },
        ]
}