import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { FC, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import CustomTabs from "../../../common/components/CustomTabs/CustomTabs";
import { ITab } from "../../../common/interfaces/CustomTabs";
import { IClassroom } from "../../../interfaces/Classroom";
import classroomsService from "../../../services/ClassroomsService";
import ClassroomStudents from "./ClassroomStudents/ClassroomStudents";
import ClassroomTeachers from "./ClassroomTeachers/ClassroomTeachers";

const ClassroomDetails: FC<{}> = () => {

    const routeProps = useParams()
    const navigate = useNavigate()

    const [classroomId, setClassroomId] = useState<string>("")
    const [currentClassroom, setCurrentClassroom] = useState<IClassroom | undefined>(undefined)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        if (routeProps.id)
            setClassroomId(routeProps.id)
    }, [routeProps])

    useEffect(() => {
        if (classroomId !== "")
            classroomsService.get(Number(classroomId)).then(
                response => setCurrentClassroom(response),
                error => setShowError(true)
            )
    }, [classroomId])


    const getCourseMode = () => {
        switch (currentClassroom?.course_real.mode) {
            case 'C':
                return "In presenza";
            case 'M':
                return "Ibrido";
            case 'D':
                return "A distanza";
            default:
                return "";
        }
    }

    const tabs: ITab[] = [
        {
            id: "aula-docenti",
            label: "Docenti",
            content: <ClassroomTeachers classroomId={classroomId} />,
            isActive: true
        },
        {
            id: "aula-studenti",
            label: "Studenti",
            content: <ClassroomStudents classroomId={classroomId} />,
            isActive: false
        }
    ]

    return <div className="container-fluid">
        {currentClassroom ? <>
            <h4 className="mb-4">{currentClassroom.name} - {currentClassroom.course_real.name}</h4>
            <Card className="mb-3 shadow-sm">
                <Card.Header>
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="fw-600">Informazioni del corso</span>
                        <Card.Link onClick={() => navigate({ pathname: '/corsi-reali', search: createSearchParams({ name: currentClassroom.course_real.name || "" }).toString() })} className="btn btn-link p-0"><FontAwesomeIcon icon={faArrowCircleRight} className="me-1" />Vai al corso reale</Card.Link>
                    </div>
                </Card.Header>
                <Card.Body className="d-flex flex-wrap">
                    <Card.Text className="col-4">Data di inizio: {currentClassroom.course_real.start_date.date ? format(new Date(currentClassroom.course_real.start_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                    <Card.Text className="col-4">Data di fine: {currentClassroom.course_real.end_date.date ? format(new Date(currentClassroom.course_real.end_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                    <Card.Text className="col-4">Docente principale: {currentClassroom.course_real.teacher.last_name + ' ' + (currentClassroom.course_real.teacher.middle_name || '') + currentClassroom.course_real.teacher.first_name || '-'}</Card.Text>
                    <Card.Text className="col-4">Tipologia: {currentClassroom.course_real.type.name}</Card.Text>
                    <Card.Text className="col-4">Modalità: {getCourseMode()}</Card.Text>
                    <Card.Text className="col-4">Categoria: {currentClassroom.course_real.is_internal ? 'Corso interno' : 'Corso esterno'}</Card.Text>
                    <Card.Text className="col-4">Piattaforma: {currentClassroom.course_real.platform.name}</Card.Text>
                    <Card.Text className="col-4">Fondo: {currentClassroom.course_real.fund.name}</Card.Text>
                    <Card.Text className="col-4">Capienza massima (studenti): {currentClassroom.course_real.max_students}</Card.Text>
                </Card.Body>
            </Card>

            <Card className="mb-3 shadow-sm">
                <Card.Header>
                    <span className="fw-600">Risorse collegate all'aula</span>
                </Card.Header>
                <Card.Body className="p-3">
                    <CustomTabs id="aula-tabs" tabs={tabs} useCustomState={true} />
                </Card.Body>
            </Card> </>
            : (showError ? <p className="mt-2">Nessun dato disponibile</p> : <></>)}
    </div>
}

export default ClassroomDetails;