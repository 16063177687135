import { FC, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { ICustomTabsProps } from "../../interfaces/CustomTabs"

// rendering tabs (customized)
const CustomTabs: FC<ICustomTabsProps> = (props: ICustomTabsProps) => {

    const { id, tabs, useCustomState } = props

    const [activeTabKey, setActiveTabKey] = useState<string | undefined>(tabs.find(tab => tab.isActive)?.id || undefined)

    return <Tabs
        activeKey={activeTabKey}
        defaultActiveKey={activeTabKey}
        onSelect={(k) => setActiveTabKey(k || undefined)}
        transition={true}
        id={id}
        className="mb-3"
    >
        {
            tabs.map(
                (tab, index) =>
                    <Tab eventKey={tab.id} title={tab.label} key={id + '-' + tab.id + '-tab'}>
                        {
                            useCustomState === true ? tabs.findIndex(tab => tab.id === activeTabKey) === index && tabs.find(tab => tab.id === activeTabKey)?.content : tab.content
                        }
                    </Tab>
            )
        }
    </Tabs>
}

export default CustomTabs