
import { FC } from "react";

interface ICustomTableHeaderProps {
    headerHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
}

// rendering the header's columns (top-left on the gantt chart)
const CustomTableHeader: FC<ICustomTableHeaderProps> = (props) => {

    const { headerHeight, rowWidth } = props

    return <div className="Gantt-Task-List_Row d-flex border-top border-start border-bottom" style={{ height: headerHeight, width: '465px' }}>
        <div className="Gantt-Task-List_Cell d-flex align-items-end justify-content-center pb-1" style={{ minWidth: rowWidth, maxWidth: rowWidth }}>
            <div className="Gantt-Task-List_Name-Container">
                <div className="Gantt-Task-List_Cell__Link">
                    Nome
                </div>
            </div>
        </div>
        <div className="Gantt-Task-List_Cell d-flex align-items-end justify-content-center pb-1" style={{ minWidth: '170px', maxWidth: rowWidth }}>
            <div className="Gantt-Task-List_Name-Container">
                <div className="Gantt-Task-List_Cell__Link">
                    Data
                </div>
            </div>
        </div>
        <div className="Gantt-Task-List_Cell d-flex align-items-end justify-content-center pb-1" style={{ minWidth: "70px", maxWidth: rowWidth }}>
            <div className="Gantt-Task-List_Name-Container">
                <div className="Gantt-Task-List_Cell__Link">
                    Dalle
                </div>
            </div>
        </div>
        <div className="Gantt-Task-List_Cell d-flex align-items-end justify-content-center pb-1" style={{ minWidth: "70px", maxWidth: rowWidth }}>
            <div className="Gantt-Task-List_Name-Container">
                <div className="Gantt-Task-List_Cell__Link">
                    Alle
                </div>
            </div>
        </div>
    </div>
}

export default CustomTableHeader;