import { faArrowCircleRight, faArrowRight, faDownload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { FC, useEffect, useState } from "react"
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap"
import { createSearchParams, useNavigate } from "react-router-dom"
import CustomFileView from "../../../common/components/CustomFileView/CustomFileView"
import { IFile } from "../../../interfaces/File"
import { IRealCourse } from "../../../interfaces/RealCourse"
import fileService from "../../../services/FileService"
import realCoursesService from "../../../services/RealCoursesService"
import RealCoursePDFModal from "./RealCoursePDFModal"

interface IRealCourseDetailsProps {
    id: string | undefined
}

const RealCourseDetails: FC<IRealCourseDetailsProps> = (props) => {

    const { id } = props
    const navigate = useNavigate()

    const [realCourseId, setRealCourseId] = useState<string>("")
    const [currentRealCourse, setCurrentRealCourse] = useState<IRealCourse | undefined>(undefined)
    const [showError, setShowError] = useState(false)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (id)
            setRealCourseId(id)
    }, [id])

    useEffect(() => {
        if (realCourseId !== "")
            realCoursesService.get(Number(realCourseId)).then(
                response => {
                    setCurrentRealCourse({ ...response })
                },
                error => setShowError(true)
            )
    }, [realCourseId])

    const getCourseMode = () => {
        switch (currentRealCourse?.mode) {
            case 'C':
                return "In presenza";
            case 'M':
                return "Ibrido";
            case 'D':
                return "A distanza";
            default:
                return "-";
        }
    }

    const onPreviewFile = (file?: IFile) => {
        if (file)
            fileService.preview(file.id)
    }

    const onDownloadFile = (file?: IFile) => {
        if (file)
            fileService.download(file.id)
    }

    const openPdfPreview = () => {
        setShowModal(true)
    }

    const realCourseDetails = currentRealCourse && <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between mb-3">
            <h5 className="my-3">{currentRealCourse.name}</h5>
            <button className='btn btn-primary rounded-pill' onClick={openPdfPreview}>
                <FontAwesomeIcon icon={faDownload} className="me-1" />Esporta in pdf
            </button>
        </div>
        <Card className="mb-3 shadow-sm">
            <Card.Header>
                <span className="fw-600">Informazioni corso reale</span>
            </Card.Header>
            <Card.Body className="d-flex flex-wrap">
                <Card.Text className="col-4">Data di inizio: {currentRealCourse.start_date.date ? format(new Date(currentRealCourse.start_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                <Card.Text className="col-4">Data di fine: {currentRealCourse.end_date.date ? format(new Date(currentRealCourse.end_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                <Card.Text className="col-4">Docente principale: {currentRealCourse.teacher.last_name + ' ' + (currentRealCourse.teacher.middle_name || '') + currentRealCourse.teacher.first_name || '-'}</Card.Text>
                <Card.Text className="col-4">Tipologia: {currentRealCourse.type.name || "-"}</Card.Text>
                <Card.Text className="col-4">Modalità: {getCourseMode()}</Card.Text>
                <Card.Text className="col-4">Categoria: {currentRealCourse.is_internal ? 'Corso interno' : 'Corso esterno'}</Card.Text>
                <Card.Text className="col-4">Capienza massima studenti: {currentRealCourse.max_students || "-"}</Card.Text>
                <Card.Text className="col-4">Durata generata dal calendario: {currentRealCourse.calendar_duration ? currentRealCourse.calendar_duration + ' ore' : "-"}</Card.Text>
            </Card.Body>
        </Card>
        {
            (currentRealCourse.classrooms && currentRealCourse.classrooms?.length > 0) && <Card className="mb-3 shadow-sm">
                <Card.Header>
                    <span className="fw-600">Aule</span>
                </Card.Header>
                <Card.Body className="d-flex flex-wrap">
                    {
                        currentRealCourse.classrooms.map(
                            classroom => <div className="col-12 d-flex" key={classroom.id}>
                                <Card.Text className="col-4">{classroom.name || "-"}</Card.Text>
                                <Card.Text className="col-4 pe-2">{classroom.note || "-"}</Card.Text>
                                <Card.Link className="btn btn-link p-0" onClick={() => navigate({ pathname: "/aule/" + classroom.id.toString() })}><FontAwesomeIcon icon={faArrowCircleRight} className="me-1" />Vai a {classroom.name}</Card.Link>
                            </div>
                        )
                    }
                </Card.Body>
            </Card>
        }
        <Card className="mb-3 shadow-sm">
            <Card.Header>
                <span className="fw-600">Informazioni corso generico</span>
            </Card.Header>
            <Card.Body className="d-flex flex-wrap">
                <Card.Text className="col-4">Nome: {currentRealCourse.course.name || "-"}</Card.Text>
                <Card.Text className="col-4">Durata: {currentRealCourse.course.duration || "-"} ore</Card.Text>
                <Card.Text className="col-4">
                    <Card.Link onClick={() => navigate({ pathname: "/corsi-generici", search: createSearchParams({ name: currentRealCourse.course.name || "" }).toString() })} className="btn btn-link p-0"><FontAwesomeIcon icon={faArrowCircleRight} className="me-1" />Vai al corso generico</Card.Link>
                </Card.Text>
                <div className="col-4 d-flex align-items-center">
                    <Card.Text>Programma:</Card.Text>
                    <Dropdown as={ButtonGroup} className="shadow-sm ms-2">
                        <Button className='btn btn-light' onClick={() => onPreviewFile(currentRealCourse.course.schedule)}>
                            <CustomFileView file={currentRealCourse && currentRealCourse.course ? currentRealCourse.course.schedule : new File([""], "")} />
                        </Button>
                        <Dropdown.Toggle split id="dropdown-split-basic" className='btn btn-light' />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => onPreviewFile(currentRealCourse.course.schedule)}><FontAwesomeIcon icon={faArrowRight} className="me-1" />Anteprima</Dropdown.Item>
                            <Dropdown.Item onClick={() => onDownloadFile(currentRealCourse.course.schedule)}><FontAwesomeIcon icon={faDownload} className="me-1" />Download</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Card.Text className="col-8">Note: {currentRealCourse.course.note || "-"}</Card.Text>
            </Card.Body>
        </Card>
        <Card className="mb-3 shadow-sm">
            <Card.Header>
                <span className="fw-600">Organizzazione</span>
            </Card.Header>
            <Card.Body className="d-flex flex-wrap">
                <Card.Text className="col-4">Fondo formativo: {currentRealCourse.fund.name || "-"}</Card.Text>
                <Card.Text className="col-4">Codice del fondo: {currentRealCourse.fund.code || "-"}</Card.Text>
                <Card.Text className="col-4">
                    <Card.Link onClick={() => navigate({ pathname: "/fondi", search: createSearchParams({ code: currentRealCourse.fund.code || "" }).toString() })} className="btn btn-link p-0"><FontAwesomeIcon icon={faArrowCircleRight} className="me-1" />Vai al fondo formativo</Card.Link>
                </Card.Text>
                <Card.Text className="col-4">Piattaforma: {currentRealCourse.platform.name || "-"}</Card.Text>
                <Card.Text className="col-4">Codice della piattaforma: {currentRealCourse.platform.code || "-"}</Card.Text>
                <Card.Text className="col-4">
                    <Card.Link onClick={() => navigate({ pathname: "/piattaforme", search: createSearchParams({ code: currentRealCourse.platform.code || "" }).toString() })} className="btn btn-link p-0"><FontAwesomeIcon icon={faArrowCircleRight} className="me-1" />Vai alla piattaforma</Card.Link>
                </Card.Text>
            </Card.Body>
        </Card>
    </div>

    return <>
        <RealCoursePDFModal
            realCourse={currentRealCourse}
            show={showModal}
            close={() => setShowModal(false)}
            content={realCourseDetails}
        />
        {
            currentRealCourse ? realCourseDetails : (showError ? <p className="mt-2">Nessun dato disponibile</p> : <></>)
        }
    </>

}

export default RealCourseDetails;