import { FC } from "react";
import * as Yup from "yup";
import CustomModal from "../../../common/components/CustomModal/CustomModal";
import { convertDateToCustomFormat } from "../../../common/config/configDate";
import { ICustomFormProps } from "../../../common/interfaces/CustomForm";
import { IModalProps } from "../../../common/interfaces/CustomModal";
import { getStudentInternalFields } from "../../../config/formFields/StudentInternalFields";
import IContractType from "../../../interfaces/ContractType";
import { IStudentInternal, IStudentInternalBody } from "../../../interfaces/StudentInternal";
import studentInternalService from "../../../services/StudentInternalService";
import { IGetStudent } from "../../../interfaces/Student";

interface IStudentInternalModalProps {
    data: {
        studentInternal: IStudentInternal | undefined,
        student: IGetStudent,
        contractTypes: IContractType[]
    }
    show: boolean;
    close: (action?: string) => void;
    action: string;
    id: number | undefined
}

const StudentInternalModal: FC<IStudentInternalModalProps> = (props) => {
    const { id, action, show, close, data } = props;

    const contractTypes = data.contractTypes.map(type => { return { value: type.id || 0, label: type.name } });
    const studentInternal = data.studentInternal ? {
        ...data.studentInternal,
        contract_type_id: data.studentInternal.contract_type.id,
        start_date: convertDateToCustomFormat(new Date(data.studentInternal?.start_date.date)),
        end_date: convertDateToCustomFormat(new Date(data.studentInternal?.end_date.date))
    } : undefined

    const validationSchema = Yup.object().shape({
        contract_type_id: Yup.number().required("* Campo obbligatorio"),
        start_date: Yup.string().required("* Campo obbligatorio"),
        end_date: Yup.string().required("* Campo obbligatorio"),
        hours: Yup.number().required("* Campo obbligatorio"),
        note: Yup.string().nullable(),
    })

    const handleSubmit = async (values: IStudentInternalBody) => {
        const body = {
            ...values,
            start_date: convertDateToCustomFormat(new Date(values.start_date)),
            end_date: convertDateToCustomFormat(new Date(values.end_date))
        }
        if (action === "add") {
            studentInternalService.post(data.student.id, body).then(() => close("add"))
        }
        if (action === "edit" && id && data.studentInternal) {
            studentInternalService.put(data.studentInternal.student.id, id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id && data.studentInternal) {
            studentInternalService.delete(data.studentInternal.student.id, id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: data.student && data.studentInternal ? 'Modifica le informazioni interne di "' + data.student.last_name + ' ' + (data.student.middle_name || '') + data.student.first_name + '"'
            : 'Aggiungi informazioni interne di "' + data.student.last_name + ' ' + (data.student.middle_name || '') + data.student.first_name + '"'
    }

    const formProps: ICustomFormProps = {
        close,
        fields: getStudentInternalFields(contractTypes),
        initialValues: studentInternal,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default StudentInternalModal;
