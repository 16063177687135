import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'react-bootstrap';
import { FC } from 'react';
import { ICustomFormProps, IField } from '../../interfaces/CustomForm';
import "./CustomForm.css";
import { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import { CustomTextNumberField, CustomTextareaField, CustomSelectField, CustomMultiSelectField, CustomDatePickerField, CustomTimePickerField, CustomFileField } from './CustomFields';

registerLocale('it', it)

// rendering forms and their fields (customized)
const CustomForm: FC<ICustomFormProps> = (props) => {
  const { initialValues, fields, close, onSubmit, validationSchema, setFile, isStep, formRef } = props

  const renderField = (props: any, field: IField) => {
    switch (field.type) {
      case 'text':
        return <CustomTextNumberField field={field} />
      case 'number':
        return <CustomTextNumberField field={field} />
      case 'textarea':
        return <CustomTextareaField field={field} />
      case 'select':
        return <CustomSelectField field={field} />
      case 'multi-select':
        return <CustomMultiSelectField field={field} />
      case 'datepicker':
        return <CustomDatePickerField field={field} />
      case 'time':
        return <CustomTimePickerField field={field} />
      case 'file':
        return <CustomFileField field={field} setFile={setFile} />
      default:
        return <></>;
    }
  }

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={initialValues || {}}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {
        (props: FormikProps<any>) => {
          return <Form>
            {
              fields.map((field, key) =>
                <div key={key} className="mb-3">
                  <h6>{field.label}</h6>
                  {
                    renderField(props, field)
                  }
                </div>
              )
            }
            {
              isStep !== true && <div className="d-flex justify-content-between pt-2">
                <Button variant="secondary" onClick={() => close()}>
                  Chiudi
                </Button>
                <Button variant="primary" type="submit" disabled={props.isSubmitting}>
                  Conferma
                </Button>
              </div>
            }
          </Form>
        }
      }
    </Formik>
  )
}

export default CustomForm