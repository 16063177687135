import axios from "axios";
import { IPeopleAvailable, IPeopleAvailableResponse, IPerson } from "../interfaces/Person";

class PeopleService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IPerson[]> {
        return await axios.get<{ statusCode: number, data: IPerson[] }>(this.baseUrl + '/people').then(response => response.data.data)
    }

    async getAllAvailableStudents(): Promise<IPeopleAvailable[]> {
        return await axios.get<IPeopleAvailableResponse>(this.baseUrl + '/students/available').then(response => response.data.data)
    }

    async getAllAvailableTeachers(): Promise<IPeopleAvailable[]> {
        return await axios.get<IPeopleAvailableResponse>(this.baseUrl + '/teachers/available').then(response => response.data.data)
    }

}

const peopleService = new PeopleService();

export default peopleService;