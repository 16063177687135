import { FormikProps } from 'formik';
import { useRef } from 'react';
import { SingleValue } from 'react-select';
import * as Yup from 'yup';
import CustomModal from '../../common/components/CustomModal/CustomModal';
import { ICustomFormProps } from '../../common/interfaces/CustomForm';
import { IModalProps } from '../../common/interfaces/CustomModal';
import { setClassroomFields } from '../../config/formFields/ClassroomFields';
import { IClassroom, IClassroomBody } from '../../interfaces/Classroom';
import { IRealCourseMenu } from '../../interfaces/RealCourse';
import { IStudentMenu } from '../../interfaces/Student';
import { ITeacherMenu } from '../../interfaces/Teacher';
import classroomsService from '../../services/ClassroomsService';

const ClassroomsModal = (props: {
    classroom: IClassroom | undefined,
    data: {
        students: IStudentMenu[],
        teachers: ITeacherMenu[],
        realCourses: IRealCourseMenu[]
    },
    id: number | undefined,
    show: boolean,
    close: (action?: string) => void,
    action: string
}) => {
    const { classroom, data, id, action, show, close } = props

    const realCourses = data.realCourses.map(corso => { return { value: corso.id, label: corso.name + ' (' + data.teachers.find(t => t.id === corso.teacher_id)?.last_name + ' ' + (data.teachers.find(t => t.id === corso.teacher_id)?.middle_name || '') + data.teachers.find(t => t.id === corso.teacher_id)?.first_name + ')' } })
    const students = data.students.map(studente => { return { value: studente.id, label: studente.last_name + ' ' + (studente.middle_name || '') + studente.first_name } })
    const teachers = data.teachers.map(docente => { return { value: docente.id, label: docente.last_name + ' ' + (docente.middle_name || '') + docente.first_name } })
    const classroomFormRef = useRef<FormikProps<any>>(null);

    const classroomForm = {
        course_real_id: classroom ? classroom.course_real.id : undefined,
        name: classroom ? classroom.name : '',
        note: classroom ? classroom.note : '',
        students: classroom ? classroom.has_students.map(studente => { return studente.student.id }) : [],
        teachers: classroom ? classroom.has_teachers.map(docente => { return docente.teacher.id }) : [],
    }

    const validationSchema = Yup.object().shape({
        course_real_id: Yup.number().required("* Campo obbligatorio"),
        students: Yup.array().of(Yup.number()).min(1, "* Campo obbligatorio"),
        teachers: Yup.array().of(Yup.number()).min(1, "* Campo obbligatorio"),
        note: Yup.string().nullable(),
        name: Yup.string().required("* Campo obbligatorio")
    })

    const handleSubmit = async (values: IClassroomBody) => {
        if (action === 'add') {
            classroomsService.post(values).then(() => close('add'))
        }
        if (action === 'edit' && id) {
            classroomsService.put(id, values).then(() => close('edit'))
        }
    }

    const onConfirm = () => {
        if (action === 'delete' && id) {
            classroomsService.delete(id).then(() => close('delete'))
        }
    }

    const onRealCourseChange = (option: SingleValue<string | {
        value: string | number;
        label: string;
    }>) => {
        let defaultTeacher = data.realCourses.find(course => course.id === (option as { value: number; label: string; })?.value)?.teacher_id
        if (action === 'add')
            classroomFormRef.current?.setFieldValue("teachers", defaultTeacher ? [defaultTeacher] : [])
        if (action === 'edit') {
            let teachers = classroom ? classroom.has_teachers.map(docente => { return docente.teacher.id }) : []
            if (defaultTeacher && !teachers.find(t => t === defaultTeacher))
                teachers.unshift(defaultTeacher)
            classroomFormRef.current?.setFieldValue("teachers", teachers)
        }
    }

    const modalProps: IModalProps = {
        close,
        show,
        confirm: onConfirm,
        action,
        label: classroom && classroom.name ? 'Modifica "' + classroom.name + '"' : 'Aggiungi una nuova aula'
    }

    const formProps: ICustomFormProps = {
        close,
        fields: setClassroomFields(realCourses, teachers, students, onRealCourseChange),
        initialValues: classroomForm,
        onSubmit: handleSubmit,
        validationSchema,
        formRef: classroomFormRef
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>

}

export default ClassroomsModal;