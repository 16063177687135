import Select from "react-select";
import { Task, ViewMode, GanttProps } from "gantt-task-react";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import CustomCalendar from "../../common/components/CustomCalendar/CustomCalendar";
import { generateTasksFromEvents, groupEventsByDate } from "../../common/config/configEvents";
import { ICustomCalendarProps } from "../../common/interfaces/CustomCalendar";
import { ICustomViewSwitchProps } from "../../common/interfaces/CustomViewSwitch";
import { ICalendarEvent } from "../../interfaces/Calendar";
import { ITeacherMenu } from "../../interfaces/Teacher";
import calendarService from "../../services/CalendarService";
import realCoursesService from "../../services/RealCoursesService";
import teachersService from "../../services/TeachersService";
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import RealCourseCalendarModal from "../Courses/RealCourses/RealCourseCalendar/RealCourseCalendarModal";
import { IRealCourse } from "../../interfaces/RealCourse";
import CustomPagination, { paginate } from "../../common/components/CustomPagination/CustomPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFileCsv, faFileExcel, faFileExport } from "@fortawesome/free-solid-svg-icons";

const RealCourseOverview: FC<{}> = () => {

    // generic data
    const [calendarEventsForCourse, setCalendarEventsForCourse] = useState<ICalendarEvent[]>([])
    const [groupedEvents, setGroupedEvents] = useState<ICalendarEvent[]>([]);
    const [currentRealCourse, setCurrentRealCourse] = useState<IRealCourse | undefined>(undefined)
    const [teachers, setTeachers] = useState<ITeacherMenu[]>([])

    // select menu
    const [selectedCourse, setSelectedCourse] = useState<{ value: number, label: string } | undefined>(undefined)
    const [optionsCourses, setOptionsCourses] = useState<{ value: number, label: string }[]>([])
    const [optionsTeachers, setOptionsTeachers] = useState<ITeacherMenu[]>([])

    // gantt
    const [tasksForCourse, setTasksForCourse] = useState<Task[]>([])
    const [viewForCourse, setViewForCourse] = useState(ViewMode.Month);
    const [columnWidthForCourse, setColumnWidthForCourse] = useState(200);
    const [isCheckedCourse, setIsCheckedCourse] = useState(true);

    // modal
    const [currentEvents, setCurrentEvents] = useState<ICalendarEvent[]>([]);
    const [currentIds, setCurrentIds] = useState<string[]>([]);
    const [refresh, setRefresh] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState('');

    // pagination
    const [active, setActive] = useState<number>(1)
    const [shownItems, setShownItems] = useState<number>(10)
    const [maxEventsLength, setMaxEventsLength] = useState<number>(0)
    const [hideChildren, setHideChildren] = useState<{ teacherId: number, hideChildren: boolean }[]>([])

    useEffect(() => {
        setRefresh(true)
        realCoursesService.getAllForMenu().then(
            response => {
                setOptionsCourses(response.map(course => { return { value: course.id, label: course.name } }).reverse())
            }
        )
    }, [])

    // getting all teachers and their lessons based on the selected course
    const getAllData = (selectedCourse?: { value: number, label: string }) => {
        if (selectedCourse) {
            Promise.all([
                calendarService.getCalendarEventsByRealCourse(selectedCourse.value),
                realCoursesService.get(selectedCourse.value),
            ]).then(
                async response => {
                    const realCourseEvents = response[0]
                    setCalendarEventsForCourse(realCourseEvents)
                    let teachers = realCourseEvents.filter((event, index, self) =>
                        index === self.findIndex((e) => (
                            e.teacher.id === event.teacher.id
                        ))
                    ).map(event => event.teacher)

                    let allEvents: ICalendarEvent[] = []
                    let savedEvents: ICalendarEvent[] = []
                    let hideChildrenTeachers: { teacherId: number, hideChildren: boolean }[] = []
                    teachersService.getAllForMenu().then(
                        options => {
                            let teachersInfo = options.filter((option, index) => {
                                let findTeacher = teachers.find(t => t.id === option.id)
                                return option.id === findTeacher?.id
                            })
                            setTeachers(teachersInfo)
                            teachersInfo.forEach(
                                t => {
                                    if (t.id === response[1].teacher.id) {
                                        setCurrentRealCourse({ ...response[1], teacher: t })
                                    }
                                    hideChildrenTeachers.push({ teacherId: t.id, hideChildren: true })
                                    let filteredEvents = realCourseEvents.filter(event => event.teacher.id === t.id).map(event => { return { ...event, teacher: t } })
                                    let grouped = groupEventsByDate(filteredEvents)
                                    if (savedEvents.length < grouped.length) {
                                        setMaxEventsLength(grouped.length)
                                    }
                                    savedEvents = grouped
                                    allEvents = [...allEvents, ...grouped]
                                }
                            )
                            setHideChildren(hideChildrenTeachers)
                            setGroupedEvents(allEvents)
                            setRefresh(false)
                        }
                    )
                }
            )
        }
    }

    // setting events as tasks to set the gantt chart
    useEffect(() => {
        let allTasks = teachers.map(teacher => {
            let filteredTasks = groupedEvents.filter(event => event.teacher.id === teacher.id)
            let paginated = paginate(filteredTasks, shownItems, active)
            let hideChildrenForCourse = hideChildren.find(ex => ex.teacherId === teacher.id)?.hideChildren
            if (filteredTasks.length !== 0)
                return generateTasksFromEvents(paginated, { ...teacher, start_date: filteredTasks[0].start_date, end_date: filteredTasks[filteredTasks.length - 1].end_date }, hideChildrenForCourse)
            else
                return []
        }).sort((e1, e2) => e2.length - e1.length)
        let saveTasks: Task[] = []
        allTasks.forEach(tasks => saveTasks = saveTasks.concat(tasks))
        setTasksForCourse(saveTasks)
    }, [active, shownItems, groupedEvents, teachers, hideChildren])

    // if refresh or select menu option change then rebuild all
    useEffect(() => {
        if (refresh === true) {
            getAllData(selectedCourse)
        }
    }, [refresh, selectedCourse]);

    useEffect(() => {
        if (selectedCourse) {
            setRefresh(true)
        }
    }, [selectedCourse]);

    // expanding a teacher if selected and showing all their events/lessons as tasks
    const handleExpanderClickCourse = (task: Task) => {
        setTasksForCourse(tasksForCourse.map((t) => (t.id === task.id ? task : t)));
        const splitted = task.id.split('-')
        const taskId = splitted[splitted.length - 1]
        let changeHideChildren = hideChildren.find(ex => ex.teacherId === Number(taskId))
        if (changeHideChildren) {
            const newHideChildren = [...hideChildren]
            newHideChildren.splice(newHideChildren.indexOf(changeHideChildren), 1, { ...changeHideChildren, hideChildren: !changeHideChildren.hideChildren })
            setHideChildren(newHideChildren)
        }
    }

    // handling edit or delete action on an event/lesson
    const onOptionClick = (action: string, taskId: string) => {
        if (selectedCourse) {
            const splittedId = taskId.split('-')
            const eventIds = splittedId[splittedId.length - 1].split('+')
            if (action === 'edit')
                Promise.all(
                    eventIds.map(eventId => calendarService.get(selectedCourse.value, eventId))
                ).then(
                    res => {
                        teachersService.getAllForMenu().then(
                            teachers => {
                                setOptionsTeachers(teachers)
                                setAction('edit')
                                setCurrentEvents(res);
                                setCurrentIds(eventIds);
                                setShowModal(true);
                            }
                        )
                    }
                )
            else {
                setAction('delete')
                setCurrentIds(eventIds);
                setShowModal(true);
            }
        }
    }

    // handling double click on calendar event
    const handleDoubleClick = (task: Task) => {
        const splittedId = task.id.split('-')
        const eventIds = splittedId[splittedId.length - 1].split('+')
        if (task.type !== 'project' && selectedCourse)
            Promise.all(
                eventIds.map(eventId => calendarService.get(selectedCourse.value, eventId))
            ).then(
                res => {
                    teachersService.getAllForMenu().then(
                        teachers => {
                            setOptionsTeachers(teachers)
                            setAction('edit')
                            setCurrentEvents(res);
                            setCurrentIds(eventIds);
                            setShowModal(true);
                        }
                    )
                }
            )
    }

    // handling pagination change
    const onPaginationChange = (active: number, shownItems: number) => {
        setActive(active)
        setShownItems(shownItems)
    }

    // handling export cvs button click event
    const onExportCsv = (courseId: number) => {
        calendarService.exportCsv(courseId)
    }

    // handling export excel button click event
    const onExportXls = (courseId: number) => {
        calendarService.exportXls(courseId)
    }


    // setting gantt props
    const allViewSwitchPropsCourse: ICustomViewSwitchProps = {
        setIsChecked: setIsCheckedCourse,
        setView: setViewForCourse,
        setColumnWidth: setColumnWidthForCourse,
        isChecked: isCheckedCourse,
        showTasksLabel: 'Mostra i dettagli dei docenti',
        view: viewForCourse
    }

    const allCalendarPropsCourse: ICustomCalendarProps & GanttProps = {
        teachers,
        onOptionClick,
        calendarEvents: calendarEventsForCourse,
        viewTasks: isCheckedCourse,
        tasks: tasksForCourse,
        viewMode: viewForCourse,
        columnWidth: columnWidthForCourse,
        onExpanderClick: handleExpanderClickCourse,
        onDoubleClick: handleDoubleClick,
    }

    return <div className="container-fluid">
        <h5 className="mb-3">Seleziona un corso</h5>
        <Select
            placeholder={"Seleziona..."}
            name="selectedCourse"
            value={optionsCourses.find(option => option.value === selectedCourse?.value)}
            onChange={option => setSelectedCourse(option || undefined)}
            options={optionsCourses}
        />
        <RealCourseCalendarModal
            events={currentEvents}
            realCourse={currentRealCourse}
            ids={currentIds}
            teachers={optionsTeachers}
            show={showModal}
            close={(action?: SyntheticEvent | string) => {
                setShowModal(false);
                setAction('')
                setCurrentEvents([]);
                setCurrentIds([]);
                if (typeof action === 'string')
                    setRefresh(true);
            }}
            action={action}
        />
        <div className="d-flex my-4">
            <div className="d-flex align-items-center me-5">
                <FontAwesomeIcon icon={faClock} className="me-1" /><p className="mb-0 fw-bold">Durata prevista dal corso: {currentRealCourse?.course.duration} ore</p>
            </div>
            <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faClock} className="me-1" /><p className="mb-0 fw-bold">Durata generata dal calendario: {currentRealCourse?.calendar_duration ? currentRealCourse?.calendar_duration + ' ore' : '-'}</p>
            </div>
        </div>
        {
            selectedCourse && tasksForCourse.length > 0 ? <Card className="mt-4 shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <span className="fw-600">Lista degli orari dei docenti del corso "{selectedCourse.label}"</span>
                    <Dropdown as={ButtonGroup} className="shadow-sm ms-2 btn-primary">
                        <Button>
                            <FontAwesomeIcon icon={faFileExport} className="me-1" />Esporta calendario del corso
                        </Button>
                        <Dropdown.Toggle split id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => onExportCsv(selectedCourse.value)}><FontAwesomeIcon icon={faFileCsv} className="me-1" />Esporta csv</Dropdown.Item>
                            <Dropdown.Item onClick={() => onExportXls(selectedCourse.value)}><FontAwesomeIcon icon={faFileExcel} className="me-2" />Esporta excel</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Header>
                <Card.Body className="w-100">
                    <CustomCalendar
                        {...allCalendarPropsCourse}
                        {...allViewSwitchPropsCourse}
                    ></CustomCalendar>
                    {groupedEvents.length >= shownItems && <CustomPagination
                        active={active}
                        shownItems={shownItems}
                        sourceItems={groupedEvents}
                        maxLength={maxEventsLength}
                        onPaginationChange={(event, active, shownItems) => onPaginationChange(active, shownItems)}
                    />}
                </Card.Body>
            </Card> : <p className="my-3">Non ci sono calendari da mostrare</p>
        }
    </div>
}

export default RealCourseOverview;