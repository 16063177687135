import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MUIDataTableMeta } from 'mui-datatables';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { IClassroom, ITableClassroom } from '../../interfaces/Classroom';
import classroomsService from '../../services/ClassroomsService';
import ClassroomsModal from './ClassroomsModal';
import teachersService from '../../services/TeachersService';
import studentsService from '../../services/StudentsService';
import realCoursesService from '../../services/RealCoursesService';
import { IRealCourseMenu } from '../../interfaces/RealCourse';
import { IStudentMenu } from '../../interfaces/Student';
import { ITeacherMenu } from '../../interfaces/Teacher';
import ClassroomsColumns from '../../config/tableColumns/ClassroomsColumns';

interface IClassroomsProps {
  period: 'past' | 'now' | 'future',
  allClassrooms: IClassroom[]
  isUpdating: (update: boolean) => void
}

const Classrooms: FC<IClassroomsProps> = (props) => {

  const [classrooms, setClassrooms] = useState<ITableClassroom[]>([])
  const [currentClassroom, setCurrentClassroom] = useState<IClassroom | undefined>(undefined);
  const [teachers, setTeachers] = useState<ITeacherMenu[]>([])
  const [students, setStudents] = useState<IStudentMenu[]>([])
  const [realCourses, setRealCourses] = useState<IRealCourseMenu[]>([])
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const { period, allClassrooms, isUpdating } = props

  const getAllClassrooms = (period: string, allClassrooms: IClassroom[]) => {
    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    const now = today.getTime()
    switch (period) {
      case 'future':
        let futureClassrooms: IClassroom[] = []
        allClassrooms.forEach(classroom => {
          if (new Date(classroom.course_real.start_date.date).getTime() > now)
            futureClassrooms.push(classroom)
        })
        setClassrooms(classroomsService.mapDataForTable(futureClassrooms))
        break;
      case 'now':
        let activeClassrooms: IClassroom[] = []
        allClassrooms.forEach(classroom => {
          if (new Date(classroom.course_real.start_date.date).getTime() < now && classroom.course_real.end_date && new Date(classroom.course_real.end_date.date).getTime() >= now)
            activeClassrooms.push(classroom)
        })
        setClassrooms(classroomsService.mapDataForTable(activeClassrooms))
        break;
      case 'past':
        let pastClassrooms: IClassroom[] = []
        allClassrooms.forEach(classroom => {
          if (classroom.course_real.end_date && new Date(classroom.course_real.end_date.date).getTime() < now)
            pastClassrooms.push(classroom)
        })
        setClassrooms(classroomsService.mapDataForTable(pastClassrooms))
        break;
      default:
        setClassrooms(classroomsService.mapDataForTable(allClassrooms))
        break;
    }
  }

  useEffect(() => getAllClassrooms(period, allClassrooms), [period, allClassrooms])

  // getting tabs' titles
  const getTitle = () => {
    switch (period) {
      case 'future':
        return 'Aule future'
      case 'now':
        return 'Aule attive'
      case 'past':
        return 'Aule passate'
      default:
        return 'Aule'
    }
  }

  const onDeleteClick = (tableMeta: MUIDataTableMeta<ITableClassroom>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0]);
      setShowModal(true);
    }
  }

  const onEditClick = (tableMeta: MUIDataTableMeta<ITableClassroom>) => {
    if (tableMeta.rowData[0]) {
      classroomsService.get(tableMeta.rowData[0])
        .then(
          res => {
            Promise.all([
              teachersService.getAllForMenu(),
              studentsService.getAllForMenu(),
              realCoursesService.getAllForMenu()
            ]).then(
              response => {
                setAction('edit')
                setCurrentClassroom(res);
                setCurrentId(tableMeta.rowData[0]);
                setTeachers(response[0]);
                setStudents(response[1]);
                setRealCourses(response[2]);
                setShowModal(true);
              }
            )
          }
        )
    }
  }

  const onAddClick = () => {
    Promise.all([
      teachersService.getAllForMenu(),
      studentsService.getAllForMenu(),
      realCoursesService.getAllForMenu()
    ]).then(
      response => {
        setShowModal(true);
        setAction('add');
        setTeachers(response[0]);
        setStudents(response[1]);
        setRealCourses(response[2]);
        setShowModal(true);
      }
    )
  }

  return (
    <div className="container-fluid">
      <ClassroomsModal
        classroom={currentClassroom}
        data={{ students, teachers, realCourses }}
        id={currentId}
        show={showModal}
        close={(action?: SyntheticEvent | string) => {
          setShowModal(false);
          setAction('')
          setCurrentClassroom(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string') {
            isUpdating(true);
          }
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">{getTitle()}</h4>
        <button className='btn btn-success rounded-pill' onClick={onAddClick}>
          <FontAwesomeIcon icon={faPlus} className="me-1" />Crea aula
        </button>
      </div>
      <ClassroomsColumns classrooms={classrooms} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
    </div>
  )
};

export default Classrooms;
