import { faArrowDown, faArrowRight, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import it from "date-fns/locale/it";
import { Task } from "gantt-task-react";
import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { ICustomListProps } from "../../interfaces/CustomCalendar";

interface ICustomTaskListTableProps extends ICustomListProps {
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    locale: string;
    tasks: Task[];
    selectedTaskId: string;
    setSelectedTask: (taskId: string) => void;
    onExpanderClick: (task: Task) => void;
}

// rendering the header's events details (rows on the left on the gantt chart)
const CustomTaskListTable: FC<ICustomTaskListTableProps> = (props) => {

    const {
        rowHeight,
        rowWidth,
        fontFamily,
        fontSize,
        tasks,
        selectedTaskId,
        onOptionClick,
        setSelectedTask,
        onExpanderClick
    } = props

    return (
        <div className="Gantt-Task-List_Wrapper border-start border-bottom" style={{ fontFamily: fontFamily, fontSize: fontSize }}>
            {
                tasks.map((t) => {
                    return (
                        <div className="Gantt-Task-List_Row d-flex" style={{ height: rowHeight }} key={`${t.id}row`} onClick={
                            () => {
                                if (selectedTaskId === t.id) {
                                    setSelectedTask("");
                                } else {
                                    setSelectedTask(t.id);
                                }
                            }
                        }
                        >
                            <div className="Gantt-Task-List_Cell">
                                <div className={selectedTaskId === t.id ? "Gantt-Task-List-Checkbox__Checked" : "Gantt-Task-List-Checkbox"}></div>
                            </div>
                            {/* Name and actions */}
                            <div className="Gantt-Task-List_Cell d-flex align-items-center" style={{ minWidth: rowWidth, maxWidth: rowWidth }}>
                                <div style={{ maxWidth: rowWidth }} className="Gantt-Task-List_Name-Container d-flex">
                                    {/* show edit or delete button only if the row is showing an event's data (a lesson) */}
                                    {
                                        t.type !== 'project' && <div className="ms-2 me-1">
                                            <Dropdown className="position-static">
                                                <Dropdown.Toggle variant="light"></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => onOptionClick('edit', t.id)} className="text-primary">
                                                        <FontAwesomeIcon icon={faPen} className="me-2" />
                                                        Modifica
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => onOptionClick('delete', t.id)} className="text-danger">
                                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                        Elimina
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                    {/* rendering the arrow icon only if the row is showing a project's data to expand and show its events as rows */}
                                    {
                                        t.type === 'project' && <div className="Gantt-Task-List_Cell__Expander btn ms-2" onClick={
                                            (e) => {
                                                onExpanderClick(t);
                                                e.stopPropagation();
                                            }
                                        }>
                                            {t.hideChildren === false ? <FontAwesomeIcon icon={faArrowDown} className="" /> : <FontAwesomeIcon icon={faArrowRight} className="" />}
                                        </div>
                                    }
                                    {/* rendering the event/project name */}
                                    <div className="Gantt-Task-List_Cell__Link d-flex align-items-center paragraph-overflow">
                                        <span className="paragraph-overflow">{t.name || '-'}</span>
                                    </div>
                                </div>
                            </div>
                            {/* Date */}
                            <div className="Gantt-Task-List_Cell d-flex align-items-center justify-content-center" style={{ minWidth: '170px', maxWidth: rowWidth }} >
                                {/* { formatDateShort(t.start, t.type === 'project' ? false : true)} */}
                                {t.type !== 'project' ? format(t.start, 'EEEE dd/MM/yyyy', { locale: it }) : ''}
                            </div>
                            {/* Start */}
                            <div className="Gantt-Task-List_Cell d-flex align-items-center justify-content-center" style={{ minWidth: "70px", maxWidth: rowWidth }} >
                                {/* {formatDateShort(t.start, t.type === 'project' ? false : true)} */}
                                {t.type !== 'project' ? format(t.start, 'HH:mm') : ''}
                            </div>
                            {/* End */}
                            <div className="Gantt-Task-List_Cell d-flex align-items-center justify-content-center" style={{ minWidth: "70px", maxWidth: rowWidth }}>
                                {/* {formatDateShort(t.end, t.type === 'project' ? false : true)} */}
                                {t.type !== 'project' ? format(t.end, 'HH:mm') : ''}
                            </div>
                        </div>
                    );
                })}
        </div >
    );
};

export default CustomTaskListTable;