import { FC } from 'react';
import CustomTabs from '../../common/components/CustomTabs/CustomTabs';
import { ITab } from '../../common/interfaces/CustomTabs';
import RealCourseOverview from './RealCourseOverview';
import TeacherOverview from './TeacherOverview';

interface CalendarsProps { }

const Calendars: FC<CalendarsProps> = () => {

  const tabs: ITab[] = [
    {
      id: "teacher-overview",
      label: "Cerca per docente",
      content: <TeacherOverview />,
      isActive: true
    },
    {
      id: "course-overview",
      label: "Cerca per corso",
      content: <RealCourseOverview />,
      isActive: false
    }
  ]

  return <div className="container-fluid">
    <h4 className='mb-3'>Calendari</h4>
    <CustomTabs id="dashboard-tabs" tabs={tabs} useCustomState={false}></CustomTabs>
  </div>
}

export default Calendars;
