import { FC } from "react";
import { Formik, Field, Form } from "formik";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { IModalProps } from "../../../../common/interfaces/CustomModal";


const DeleteCalendarEventModal: FC<IModalProps> = (props) => {
    const { show, close, confirm, ids } = props
    return <>
        <Modal show={show} onHide={close} backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>Seleziona un'opzione di eliminazione</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    selection: '',
                }}
                onSubmit={(values) => confirm(values.selection)}
            >
                {({ values }) => (
                    <Form className='form-group' role="group" aria-labelledby="my-radio-group">
                        <div className='p-3'>
                            {
                                ids?.length === 2 && <React.Fragment>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="selection" value="0" />
                                        <label className="form-check-label">
                                            Solo mattino
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="selection" value="1" />
                                        <label className="form-check-label">
                                            Solo pomeriggio
                                        </label>
                                    </div>
                                </React.Fragment>
                            }
                            <div className="form-check">
                                <Field className="form-check-input" type="radio" name="selection" value="2" />
                                <label className="form-check-label">
                                    L'evento intero
                                </label>
                            </div>
                        </div>
                        <Modal.Footer className="d-flex justify-content-between">
                            <Button variant="secondary" onClick={close}>
                                Chiudi
                            </Button>
                            <Button variant="primary" type="submit" disabled={!values.selection}>
                                Conferma
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    </>
}

export default DeleteCalendarEventModal;