export const employmentAgenciesFields = [
    {
        name: 'name',
        type: 'text',
        placeholder: 'Inserisci il nome',
        label: 'Nome'
    },
    {
        name: 'code',
        type: 'text',
        placeholder: 'Inserisci il codice',
        label: 'Codice'
    },
    {
        name: 'training_fee',
        type: 'text',
        placeholder: 'Inserisci il compenso di formazione',
        label: 'Compenso formazione (€)'
    }
]