export const usersFields = [
    {
        name: 'first_name',
        type: 'text',
        placeholder: 'Inserisci il nome',
        label: 'Nome'
    },
    {
        name: 'last_name',
        type: 'text',
        placeholder: 'Inserisci il cognome',
        label: 'Cognome'
    },
    {
        name: 'username',
        type: 'text',
        placeholder: 'Inserisci lo username',
        label: 'Username'
    },
    {
        name: 'email_address',
        type: 'text',
        placeholder: "Inserisci l'email",
        label: 'Email'
    },
    {
        name: 'status',
        type: 'select',
        placeholder: 'Seleziona lo status',
        label: 'Status',
        options: [
            { value: "E", label: "Abilitato" },
            { value: "D", label: "Disabilitato" },
            { value: "S", label: "Sospeso" }
        ]
    },
]