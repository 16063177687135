import * as Yup from 'yup';

export const getTeachersFields = (
    people: { value: number, label: string }[],
    qualifications: { value: number, label: string }[],
    action: string
) => {
    const fields = [
        {
            name: "qualification_id",
            label: "Titolo di studio",
            type: "select",
            options: qualifications,
            placeholder: "Seleziona il titolo di studio",
        },
        {
            name: "nationality",
            label: "Nazionalità",
            type: "text",
            placeholder: "Inserisci la nazionalità",
        },
        {
            name: "gender",
            label: "Genere",
            type: "select",
            options: [
                { value: "M", label: "M" },
                { value: "F", label: "F" }
            ],
            placeholder: "Inserisci il genere",
        },
        {
            name: "tax_code",
            label: "Codice fiscale",
            type: "text",
            placeholder: "Inserisci il codice fiscale",
        },
        {
            name: "VAT_number",
            label: "Partita IVA",
            type: "number",
            placeholder: "Inserisci la partita IVA",
        },
        {
            name: "activity",
            label: "Attività svolte",
            type: "text",
            placeholder: "Inserisci l'attività",
        },
        {
            name: "hourly_fee",
            label: "Compenso orario (€)",
            type: "text",
            placeholder: "Inserisci il compenso orario",
        },
        {
            name: "assessment_selection",
            label: "Valutazione selezione",
            type: "text",
            placeholder: "Inserisci la val. selezione",
        },
        {
            name: "assessment_course",
            label: "Valutazione corso",
            type: "select",
            options: [
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" }
            ],
            placeholder: "Seleziona la val. corso",
        },
        {
            name: "note",
            label: "Note",
            type: "textarea",
            placeholder: "Inserisci le note",
        }
    ]

    if (action === 'add') {
        return [
            {
                name: "demetra_id",
                label: "Scegli dal personale disponibile",
                type: "select",
                placeholder: "Seleziona un utente",
                options: people,
                validation: Yup.number().required("* Campo obbligatorio")
            },
            ...fields
        ]
    } else {
        return fields
    }
}