import { TableRow } from "@mui/material";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { FC } from "react";

const options: MUIDataTableOptions = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: 'vertical',
    tableBodyHeight: "",
    tableBodyMaxHeight: "",
    onTableChange: (action: any, state: any) => { },
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    expandableRows: false,
    sortOrder: {
        name: 'id',
        direction: 'desc'
    },
    setTableProps: () => {
        return {
            size: 'small',
        };
    },
    rowsExpanded: [],
    isRowExpandable: (dataIndex, expandedRows) => {
        // prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
        if (expandedRows)
            if (expandedRows.data.length > 4 && expandedRows.data.filter((d: any) => d.dataIndex === dataIndex).length === 0)
                return false;
        return true;
    },
    renderExpandableRow: (rowData, rowMeta) => <TableRow>{JSON.stringify(rowData) + ' - ' + JSON.stringify(rowMeta)}</TableRow>,
    onRowExpansionChange: (curExpanded: any, allExpanded: any, rowsExpanded: any) => { },
    // rendering labels in local language
    textLabels: {
        body: {
            noMatch: "Nessun risultato trovato",
            toolTip: "Ordina",
            columnHeaderTooltip: column => `Ordina per ${column.label}`
        },
        pagination: {
            next: "Pagina successiva",
            previous: "Pagina precedente",
            rowsPerPage: "Righe per pagina:",
            displayRows: "di",
        },
        toolbar: {
            search: "Cerca",
            downloadCsv: "Download CSV",
            print: "Stampa",
            viewColumns: "Mostra colonne",
            filterTable: "Filtri",
        },
        filter: {
            all: "Tutti",
            title: "Lista di filtri",
            reset: "Resetta i filtri",
        },
        viewColumns: {
            title: "Mostra colonne",
            titleAria: "Mostra/Nascondi colonne",
        },
        selectedRows: {
            text: "Riga/righe selezionate",
            delete: "Elimina",
            deleteAria: "Elimina righe selezionate",
        },
    }
};

interface ITableProps {
    title: React.ReactNode,
    data: (object | string[] | number[])[]
    columns: MUIDataTableColumnDef[]
    options?: MUIDataTableOptions
}

// rendering MUI Datatables (customized)
const CustomTable: FC<ITableProps> = (props: ITableProps) => {

    return <MUIDataTable
        {...props}
        title={<h5>{props.title}</h5>}
        options={props.options !== undefined ? { ...options, ...props.options } : options}
    />
}

export default CustomTable