import React, { FC, useEffect, useState } from 'react';
import { MUIDataTableMeta } from 'mui-datatables';
import IPlatform from '../../../interfaces/Platform';
import PlatformsColumns from '../../../config/tableColumns/PlatformsColumns';
import PlatformsModal from './PlatformsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import platformsService from '../../../services/PlatformsService';
import { useSearchParams } from 'react-router-dom';

const Platforms: FC<{}> = () => {

  const [searchParams] = useSearchParams()
  const [filter, setFilter] = useState("All");

  const [platforms, setPlatforms] = useState<IPlatform[]>([]);
  const [currentPlatform, setCurrentPlatform] = useState<IPlatform | undefined>(undefined)
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const [action, setAction] = useState('');

  const getAllPlatforms = () => platformsService.getAll().then(res => setPlatforms(res))

  useEffect(() => {
    getAllPlatforms()
  }, []);

  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams);
    setFilter(Object.values(currentParams)[0]);
  }, [searchParams]);

  useEffect(() => {
    if (refresh === true) {
      getAllPlatforms();
      setRefresh(false);
    }
  }, [refresh]);

  const onEditClick = (tableMeta: MUIDataTableMeta<IPlatform>) => {
    if (tableMeta.rowData[0]) {
      platformsService.get(tableMeta.rowData[0])
        .then(
          res => {
            setAction('edit')
            setCurrentId(tableMeta.rowData[0])
            setCurrentPlatform(res)
            setShowModal(true);
          }
        )
    }
  }

  const onDeleteClick = (tableMeta: MUIDataTableMeta<IPlatform>) => {
    if (tableMeta.rowData[0]) {
      setAction('delete')
      setCurrentId(tableMeta.rowData[0])
      setShowModal(true);
    }
  }

  return (
    <div className="container-fluid">
      <PlatformsModal
        platform={currentPlatform}
        id={currentId}
        show={showModal}
        close={(action?: string) => {
          setShowModal(false);
          setAction('')
          setCurrentPlatform(undefined);
          setCurrentId(undefined);
          if (typeof action === 'string')
            setRefresh(true);
        }}
        action={action}
      />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="mb-0">Piattaforme</h4>
        <button className='btn btn-success rounded-pill' onClick={() => { setShowModal(true); setAction('add') }}><FontAwesomeIcon icon={faPlus} className="me-1" />Crea piattaforma</button>
      </div>
      <PlatformsColumns searchParam={filter} platforms={platforms} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
    </div>
  )
}

export default Platforms;