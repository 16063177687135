import axios from "axios";
import { ITeacher, ITeachersResponse, ITeacherForm, IGetTeacher, ITeacherMenu, ITeacherMenuResponse } from "../interfaces/Teacher";
class TeachersService {

    private baseUrl = process.env.REACT_APP_API_URL

    mapDataForTable = (teachers: IGetTeacher[]): ITeacher[] => {
        return teachers.map(
            teacher => {
                return {
                    id: teacher.id,
                    first_name: teacher.last_name + ' ' + (teacher.middle_name || '') + teacher.first_name,
                    activity: teacher.activity,
                    assessment_course: teacher.assessment_course,
                    hourly_fee: teacher.hourly_fee,
                    VAT_number: teacher.VAT_number,
                    qualification: teacher.qualification.name
                }
            }
        )
    }

    async getAllForTable(): Promise<ITeacher[]> {
        return await axios.get<ITeachersResponse>(this.baseUrl + '/teachers').then(response => this.mapDataForTable(response.data.data))
    }

    async getAllForMenu(): Promise<ITeacherMenu[]> {
        return await axios.get<ITeacherMenuResponse>(this.baseUrl + '/teachers/menu').then(response => response.data.data)
    }

    async getAll(): Promise<IGetTeacher[]> {
        return await axios.get<ITeachersResponse>(this.baseUrl + '/teachers').then(response => response.data.data)
    }

    async get(id: number): Promise<IGetTeacher> {
        return await axios.get(this.baseUrl + '/teacher/' + id).then(response => response.data.data)
    }

    async post(body: ITeacherForm): Promise<ITeachersResponse> {
        return await axios.post(this.baseUrl + '/teacher', body).then(response => response.data.data)
    }

    async put(id: number, body: ITeacherForm): Promise<ITeachersResponse> {
        return await axios.put(this.baseUrl + '/teacher/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/teacher/' + id).then(response => response.data.data)
    }
}

const teachersService = new TeachersService();

export default teachersService;