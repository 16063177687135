import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { toast, ToastOptions } from "react-toastify";
import { createContext, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import authService from "../../../services/AuthService";
import { IAuth } from "../../../interfaces/Auth";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import React from "react";

// SpinnerContext: creating spinner context, its consumer and its provider
type SpinnerContextType = {
    loading: boolean
}
const SpinnerContext = createContext<SpinnerContextType>({ loading: false });
const SpinnerProvider = SpinnerContext.Provider

// AuthContext: creating authentication context, its consumer and its provider
type AuthContextType = {
    auth: IAuth | null,
    setAuth: React.Dispatch<React.SetStateAction<IAuth | null>>
}
export const AuthContext = createContext<AuthContextType>({} as AuthContextType);
const AuthProvider = AuthContext.Provider

// toasts' props and messages
type messageType = 'success' | 'error'
const showToast = (message: string, messageType: messageType, options: ToastOptions<{}> | undefined = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored",
}) => {
    if (messageType === 'success')
        toast.success(message, options)
    else
        toast.error(message, options)
}

// handling all authorization controls, API interceptors and responses' messages
export const AuthHandler = (({ children }: { children: ReactElement }) => {

    // check initial auth value reading it from localStorage
    const [auth, setAuth] = useState(authService.getAuth())
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let isLoggingOut: boolean = false;
    let requests: InternalAxiosRequestConfig<any>[] = [];
    let responses: AxiosResponse<any, any>[] = [];

    const [mounted, setMounted] = useState(false)

    // setting mounted at the beginning
    useEffect(() => {
        setMounted(true)
    }, [])

    // This code is called only one time before intial render
    if (!mounted) {
        // setting the request interceptor (axios)
        axios.interceptors.request.use(
            (request) => {
                setLoading(true)
                // Do something before request is sent
                const auth = authService.getAuth()
                if (auth !== null) {
                    request.headers['X-Auth-Token'] = `Bearer ${auth.token}`
                    isLoggingOut = false;
                    if (request.method !== 'get' && !request.url?.includes('login')) {
                        requests.push(request)
                    }
                }

                return request;
            },
            (error) => {
                setLoading(false)
                // Do something with request error
                return Promise.reject(error)
            }
        )

        //setting the response interceptor (axios)
        axios.interceptors.response.use(
            (response) => {
                setLoading(false)
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                if (response.config.method !== 'get' && !response.config.url?.includes('login')) {
                    responses.push(response);
                    if (responses.length === requests.length) {
                        showToast('Operation completed!', 'success');
                        requests = []
                        responses = []
                    }
                }
                return response;
            },
            (error: AxiosError<{ error: { type: string, description?: string, message?: string | Object } | string, statusCode: number }, any>) => {
                const { response } = error
                setLoading(false)
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                if (response) {
                    if (response.status === 401 || response.status === 403) {
                        if (typeof response.data.error !== 'string' && typeof response.data.error.message === 'string' && !isLoggingOut) {
                            showToast(response.data.error.message, 'error');
                        }
                        authService.logout()
                        setAuth(null)
                        navigate('/login')
                        isLoggingOut = true; // disallow multiple calls
                    } else {
                        if (typeof response.data.error === 'string') {
                            showToast(response.data.error, 'error');
                        }

                        if (typeof response.data.error === 'object') {
                            const description = response.data.error.description
                            const message = response.data.error.message

                            if (description)
                                showToast(description, 'error');
                            if (message) {
                                if (typeof message === 'string') {
                                    showToast(message, 'error');
                                } else {
                                    showToast("Errors: " + Object.values(message).join(" - "), 'error')
                                }
                            }

                        }

                        const isBlob = (object: any): object is Blob => {
                            return 'size' in object;
                        }

                        if (isBlob(error?.response?.data)) {
                            showToast(error?.response?.statusText || '', 'error')
                        }
                    }
                }

                if (error && !response)
                    showToast(error.message, 'error');

                return Promise.reject(error);
            }
        );
    }

    // listen to navigation changes and checking JWT expiration
    useEffect(() => {
        let readAuth = authService.getAuth()
        if (readAuth !== null) {
            let token = readAuth.token
            let expire = readAuth.expire
            if (token !== null) {
                if (expire - Date.now() <= 0) {
                    authService.logout()
                    setAuth(null)
                } else {
                    if (auth === null) {
                        setAuth(readAuth)
                    }
                }
            }
        }
        setLoading(false)
    }, [navigate, auth]);

    // rendering AuthProvider with loading spinner's and toasts' listeners
    return <SpinnerProvider value={{ loading }}>
        {loading && <CustomSpinner />}
        <ToastContainer />
        <AuthProvider value={{ auth, setAuth }}>
            {children}
        </AuthProvider>
    </SpinnerProvider>
}) 