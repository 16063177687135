import axios from "axios";
import { IReport } from "../interfaces/Report";

class ReportsService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IReport[]> {
        return await axios.get(this.baseUrl + '/reports').then(response => response.data.data)
    }

    async download(reportId: string): Promise<void> {
        axios({
            method: 'GET',
            url: this.baseUrl + '/reports/' + reportId,
            responseType: 'blob'
        }).then(
            response => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', response.headers["content-description"]); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        )
    };
}

const reportsService = new ReportsService();

export default reportsService;