import axios, { AxiosResponse } from "axios"
import IUser from "../interfaces/User"

class UsersService {

    private baseUrl = process.env.REACT_APP_API_URL

    async getAll(): Promise<IUser[]> {
        return await axios.get(this.baseUrl + "/users").then((response: AxiosResponse<{ statusCode: number, data: IUser[] }>) => {
            return response.data.data.map(
                user => {
                    return {
                        ...user,
                        first_name: user.last_name + ' ' + user.first_name,
                        status: user.status === 'E' ? 'Abilitato' : (user.status === 'D' ? 'Disabilitato' : 'Sospeso')
                    }
                }
            )
        })
    }

    async get(id: number): Promise<IUser> {
        return await axios.get(this.baseUrl + "/user/" + id).then(response => response.data.data)
    }

    async post(body: IUser): Promise<IUser> {
        return await axios.post(this.baseUrl + '/user', body).then(response => response.data.data)
    }

    async put(id: number, body: IUser): Promise<IUser> {
        return await axios.put(this.baseUrl + '/user/' + id, body).then(response => response.data.data)
    }

    async delete(id: number) {
        return await axios.delete(this.baseUrl + '/user/' + id).then(response => response.data.data)
    }
}

const usersService = new UsersService();

export default usersService;