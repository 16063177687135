import * as Yup from "yup";
import CustomModal from "../../../../common/components/CustomModal/CustomModal";
import { FC } from "react";
import { IModalProps } from "../../../../common/interfaces/CustomModal";
import { ICustomFormProps } from "../../../../common/interfaces/CustomForm";
import { IClassroomTeacher, IClassroomTeacherBody } from "../../../../interfaces/ClassroomTeacher";
import classroomTeachersService from "../../../../services/ClassroomTeachersService";
import { classroomTeachersFields } from "../../../../config/formFields/ClassroomTeachersFields";

interface IClassroomDetailsModalTeacherProps {
    currentTeacher: IClassroomTeacher | undefined
    show: boolean;
    close: (action?: string) => void;
    action: string;
    id: number | undefined,
    classroomId: number
}

const ClassroomDetailsModalTeacher: FC<IClassroomDetailsModalTeacherProps> = (props) => {

    const { id, action, show, close, currentTeacher, classroomId } = props;

    const teacherProfile = {
        teacher_id: action === "add" ? id : currentTeacher?.teacher.id,
        note: currentTeacher ? currentTeacher.note : undefined,
        fee: currentTeacher ? currentTeacher.fee : undefined,
        payment_times: currentTeacher ? currentTeacher.payment_times : undefined,
        withholding_tax: currentTeacher ? Number(currentTeacher.withholding_tax) : undefined,
    }

    const validationSchema = Yup.object().shape({
        teacher_id: Yup.number().required("* Campo obbligatorio"),
        note: Yup.string().nullable(),
        fee: Yup.string().required("* Campo obbligatorio"),
        payment_times: Yup.string().required("* Campo obbligatorio"),
        withholding_tax: Yup.number().required("* Campo obbligatorio")
    })

    const handleSubmit = async (values: IClassroomTeacherBody) => {
        if (action === "add") {
            const body: IClassroomTeacherBody = { ...values, withholding_tax: Boolean(values.withholding_tax) }
            classroomTeachersService.post(classroomId, body).then(res => close("add-teacher"))

        }
        if (action === "edit" && id) {
            const body: IClassroomTeacherBody = { ...values, withholding_tax: Boolean(values.withholding_tax) }
            classroomTeachersService.put(classroomId, id, body).then(res => close("edit-teacher"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            classroomTeachersService.delete(classroomId, id).then(res => close("delete-teacher"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: action === 'add' ? "Inserisci informazioni aggiuntive" : "Modifica informazioni aggiuntive"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: classroomTeachersFields,
        initialValues: teacherProfile,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>
}

export default ClassroomDetailsModalTeacher;
