import { FC, Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IRealCourse } from "../../../../interfaces/RealCourse";
import { ICalendarEvent } from "../../../../interfaces/Calendar";
import { GanttProps, Task, ViewMode } from "gantt-task-react";
import realCoursesService from "../../../../services/RealCoursesService";
import calendarService from "../../../../services/CalendarService";
import { generateTasksFromEvents, groupEventsByDate } from "../../../../common/config/configEvents";
import { ICustomViewSwitchProps } from "../../../../common/interfaces/CustomViewSwitch";
import { ICustomCalendarProps } from "../../../../common/interfaces/CustomCalendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFileCsv, faFileExcel, faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomCalendar from "../../../../common/components/CustomCalendar/CustomCalendar";
import RealCourseCalendarModal from "../RealCourseCalendar/RealCourseCalendarModal";
import { ITeacherMenu } from "../../../../interfaces/Teacher";
import teachersService from "../../../../services/TeachersService";
import CustomPagination, { paginate } from "../../../../common/components/CustomPagination/CustomPagination";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

interface RealCourseCalendarProps { }

const RealCourseCalendar: FC<RealCourseCalendarProps> = () => {
    // url params
    const routeParams = useParams();

    // data
    const [realCourseId] = useState<number>(Number(routeParams.id))
    const [realCourse, setRealCourse] = useState<IRealCourse | undefined>(undefined)
    const [calendarEvents, setCalendarEvents] = useState<ICalendarEvent[]>([]);
    const [groupedEvents, setGroupedEvents] = useState<ICalendarEvent[]>([]);
    const [currentEvents, setCurrentEvents] = useState<ICalendarEvent[]>([]);
    const [teachers, setTeachers] = useState<ITeacherMenu[]>([]);
    const [refresh, setRefresh] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [currentIds, setCurrentIds] = useState<string[]>([]);
    const [action, setAction] = useState('');
    const [showError, setShowError] = useState(false)

    // gantt
    const [tasks, setTasks] = useState<Task[]>([]);
    const [view, setView] = useState(ViewMode.Month);
    const [columnWidth, setColumnWidth] = useState(200);
    const [isChecked, setIsChecked] = useState(true);

    // pagination
    const [active, setActive] = useState<number>(1)
    const [shownItems, setShownItems] = useState<number>(10)

    // getting data on initialization
    const getAllData = (realCourseId: number) => {
        realCoursesService.get(realCourseId).then(
            response => {
                teachersService.get(response.teacher.id).then(
                    teacher => {
                        setRealCourse({ ...response, teacher })
                        calendarService.getCalendarEventsByRealCourse(realCourseId).then(
                            res => {
                                setCalendarEvents(res)
                                setGroupedEvents(groupEventsByDate(res))
                            },
                            err => setShowError(true)
                        )
                    },
                    err => setShowError(true)
                )
            },
            err => setShowError(true)
        )
    }

    useEffect(() => {
        getAllData(realCourseId)
    }, [realCourseId])

    useEffect(() => {
        if (refresh === true) {
            getAllData(realCourseId)
            setRefresh(false)
        }
    }, [refresh, realCourseId]);

    useEffect(() => {
        let paginatedTasks = paginate(groupedEvents, shownItems, active)
        setTasks(generateTasksFromEvents(paginatedTasks, realCourse))
    }, [active, shownItems, groupedEvents, realCourse])

    const handleProgressChange = (task: Task, children: Task[]) => {
        return true
    }

    const handleSelect = (task: Task, isSelected: boolean) => { }

    const handleClick = (task: Task) => { }

    const handleDoubleClick = (task: Task) => {
        const splittedId = task.id.split('-')
        const eventIds = splittedId[splittedId.length - 1].split('+')
        if (task.type !== 'project')
            Promise.all(eventIds.map(eventId => calendarService.get(realCourseId, eventId))).then(
                res => {
                    teachersService.getAllForMenu().then(
                        teachers => {
                            setAction('edit')
                            setTeachers(teachers);
                            setCurrentEvents(res);
                            setCurrentIds(eventIds);
                            setShowModal(true);
                        }
                    )
                }
            )
    }

    const handleExpanderClick = (task: Task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    }

    const onAddClick = () => {
        teachersService.getAllForMenu().then(
            teachers => {
                setTeachers(teachers);
                setShowModal(true);
                setAction('add');
            }
        )
    }

    const onOptionClick = (action: string, taskId: string) => {
        const splittedId = taskId.split('-')
        const eventIds = splittedId[splittedId.length - 1].split('+')
        if (action === 'edit')
            Promise.all(eventIds.map(eventId => calendarService.get(realCourseId, eventId))).then(
                res => {
                    teachersService.getAllForMenu().then(
                        teachers => {
                            setAction('edit')
                            setTeachers(teachers);
                            setCurrentEvents(res);
                            setCurrentIds(eventIds);
                            setShowModal(true);
                        }
                    )
                }
            )
        else {
            setAction('delete')
            setCurrentIds(eventIds);
            setShowModal(true);
        }
    }

    // handling export cvs button click event
    const onExportCsv = (courseId: number) => {
        calendarService.exportCsv(courseId)
    }

    // handling export excel button click event
    const onExportXls = (courseId: number) => {
        calendarService.exportXls(courseId)
    }

    const onPaginationChange = (active: number, shownItems: number) => {
        setActive(active)
        setShownItems(shownItems)
    }

    const allViewSwitchProps: ICustomViewSwitchProps = {
        setIsChecked,
        setView,
        setColumnWidth,
        isChecked,
        showTasksLabel: 'Mostra i dettagli degli eventi',
        view
    }

    const allCalendarProps: ICustomCalendarProps & GanttProps = {
        realCourses: realCourse ? [realCourse] : [],
        calendarEvents,
        viewTasks: isChecked,
        onOptionClick,
        tasks,
        viewMode: view,
        columnWidth,
        onProgressChange: handleProgressChange,
        onSelect: handleSelect,
        onExpanderClick: handleExpanderClick,
        onClick: handleClick,
        onDoubleClick: handleDoubleClick
    }

    return <div className="container-fluid">
        <RealCourseCalendarModal
            events={currentEvents}
            realCourse={realCourse}
            ids={currentIds}
            teachers={teachers}
            show={showModal}
            close={(action?: SyntheticEvent | string) => {
                setShowModal(false);
                setAction('')
                setCurrentEvents([]);
                setCurrentIds([]);
                if (typeof action === 'string')
                    setRefresh(true);
            }}
            action={action}
        />
        {
            groupedEvents.length > 0 && realCourse ? <>
                <div className="d-flex justify-content-between mb-3">
                    <h4>Calendario del corso "{realCourse.name}"</h4>
                    <div>
                        <button className='btn btn-success rounded-pill me-2' onClick={onAddClick}>
                            <FontAwesomeIcon icon={faPlus} className="me-1" />Crea evento
                        </button>
                        <Dropdown as={ButtonGroup} className="shadow-sm ms-2 btn-primary">
                            <Button>
                                <FontAwesomeIcon icon={faFileExport} className="me-1" />Esporta calendario
                            </Button>
                            <Dropdown.Toggle split id="dropdown-split-basic" />
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => onExportCsv(realCourse.id)}><FontAwesomeIcon icon={faFileCsv} className="me-1" />Esporta csv</Dropdown.Item>
                                <Dropdown.Item onClick={() => onExportXls(realCourse.id)}><FontAwesomeIcon icon={faFileExcel} className="me-2" />Esporta excel</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex align-items-center me-5">
                        <FontAwesomeIcon icon={faClock} className="me-1" /><h5 className="mb-0">Durata prevista dal corso: {realCourse.course.duration} ore</h5>
                    </div>
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faClock} className="me-1" /><h5 className="mb-0">Durata generata dal calendario: {realCourse.calendar_duration ? realCourse.calendar_duration + ' ore' : '-'}</h5>
                    </div>
                </div>
                {
                    tasks.length !== 0 && <Fragment>
                        <CustomCalendar {...allCalendarProps} {...allViewSwitchProps}></CustomCalendar>
                        {groupedEvents.length >= shownItems && <CustomPagination
                            active={active}
                            shownItems={shownItems}
                            sourceItems={groupedEvents}
                            onPaginationChange={(event, active, shownItems) => onPaginationChange(active, shownItems)}
                        />}
                    </Fragment>
                }
            </> : (showError ? <p className="mt-2">Nessun dato disponibile</p> : <></>)
        }
    </div>
};

export default RealCourseCalendar;