import React, { FC } from 'react';
import IPlatform from '../../../interfaces/Platform';
import * as Yup from 'yup';
import CustomModal from '../../../common/components/CustomModal/CustomModal';
import platformsService from '../../../services/PlatformsService';
import { platformsFields } from '../../../config/formFields/PlatformsFields';
import { IModalProps } from '../../../common/interfaces/CustomModal';
import { ICustomFormProps } from '../../../common/interfaces/CustomForm';

interface IPlatformsModalProps {
    platform: IPlatform | undefined,
    id: number | undefined
    show: boolean,
    close: (action?: string) => void,
    action: string
}

const PlatformsModal: FC<IPlatformsModalProps> = (props) => {
    const { platform, id, show, close, action } = props

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("* Campo obbligatorio"),
        code: Yup.string().required("* Campo obbligatorio").length(3, "La lunghezza deve essere di 3"),
    })

    const handleSubmit = async (values: IPlatform) => {
        const body = { ...values, code: values.code.toUpperCase() }
        if (action === "add") {
            platformsService.post(body).then(() => close("add"))
        }
        if (action === "edit" && id) {
            platformsService.put(id, body).then(() => close("edit"))
        }
    }

    const onConfirm = () => {
        if (action === "delete" && id) {
            platformsService.delete(id).then(() => close("delete"))
        }
    }

    const modalProps: IModalProps = {
        close,
        confirm: onConfirm,
        show,
        action,
        label: platform ? 'Modifica i dati di "' + platform.name + '"' : "Aggiungi una nuova piattaforma"
    }

    const formProps: ICustomFormProps = {
        close,
        fields: platformsFields,
        initialValues: platform,
        onSubmit: handleSubmit,
        validationSchema
    }

    return <CustomModal modalProps={modalProps} formProps={formProps}></CustomModal>

}

export default PlatformsModal;