import { faImage, faFileText, faFileWord, faFileExcel, faFilePdf, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { IFile } from "../../../interfaces/File";

// rendering files' icon and name (customized)
const CustomFileView: FC<{ file: IFile | File }> = (props) => {
    const { file } = props;

    const isCustomFile = (object: any): object is IFile => {
        return 'client_name' in object;
    }

    switch (file.type) {
        case 'image/png':
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faImage} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
        case 'image/jpeg':
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faImage} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
        case 'image/jpg':
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faImage} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
        case 'text/plain':
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faFileText} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
        case 'application/vnd.ms-word.document.macroEnabled.12':
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faFileWord} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faFileExcel} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
        case 'application/pdf':
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faFilePdf} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
        default:
            return <div className='d-flex align-items-center paragraph-overflow me-2' style={{ maxWidth: '200px' }}>
                <FontAwesomeIcon icon={faFile} className="me-1 fs-4" />
                <span className='paragraph-overflow'>
                    {isCustomFile(file) ? file.client_name : file.name}
                </span>
            </div>
    }
}

export default CustomFileView;