
import React, { FC, useEffect, useState } from 'react';
import CustomTabs from '../../common/components/CustomTabs/CustomTabs';
import { ITab } from '../../common/interfaces/CustomTabs';
import { IClassroom } from '../../interfaces/Classroom';
import classroomsService from '../../services/ClassroomsService';
import Classrooms from './Classrooms';

const ClassroomsTabs: FC<{}> = () => {

    const [allClassrooms, setAllClassrooms] = useState<IClassroom[]>([])
    const [refresh, setRefresh] = useState<boolean>(true)

    useEffect(() => {
        if (refresh)
            classroomsService.getAll().then(
                classrooms => {
                    setAllClassrooms(classrooms)
                    setRefresh(false)
                },
                () => setRefresh(false)
            )
    }, [refresh])

    // all tabs render Classrooms component with different props
    const tabs: ITab[] = [
        {
            id: "aule-attive",
            label: "Aule attive",
            content: <Classrooms period='now' allClassrooms={allClassrooms} isUpdating={(value) => setRefresh(value)} />,
            isActive: true
        },
        {
            id: "aule-future",
            label: "Aule future",
            content: <Classrooms period='future' allClassrooms={allClassrooms} isUpdating={(value) => setRefresh(value)} />,
            isActive: false
        },
        {
            id: "aule-passate",
            label: "Aule passate",
            content: <Classrooms period='past' allClassrooms={allClassrooms} isUpdating={(value) => setRefresh(value)} />,
            isActive: false
        }
    ]

    return <div className="container-fluid">
        <h4 className='mb-3'>Aule</h4>
        <CustomTabs id="classrooms-tabs" tabs={tabs} useCustomState={false}></CustomTabs>
    </div>
};

export default ClassroomsTabs;
