import axios, { AxiosResponse } from "axios";
import { ILoginBody, ILoginResponse, IRequestAuthBody, IRequestAuthResponse } from "../interfaces/Login";
import { IAuth, IProfile } from "../interfaces/Auth";

class AuthService {
    private baseUrl = process.env.REACT_APP_API_URL
    private authUrl = process.env.REACT_APP_AUTH_URL

    setAuth(data: IAuth) {
        localStorage.setItem("auth", JSON.stringify(data))
    }

    getAuth(): IAuth | null {
        let readAuth = localStorage.getItem("auth")
        return readAuth ? JSON.parse(readAuth) : null
    }

    setProfile(data: IProfile) {
        localStorage.setItem("profile", JSON.stringify(data))
    }

    getProfile(): IProfile | null {
        let readProfile = localStorage.getItem("profile")
        return readProfile ? JSON.parse(readProfile) : null
    }

    async requestAuth(body: IRequestAuthBody): Promise<ILoginBody> {
        return await axios.post(this.authUrl + '/ssologin', body)
            .then(
                (res: AxiosResponse<IRequestAuthResponse>) => {
                    this.setProfile({ name: res.data.name, last_name: res.data.lastname })
                    return { token: res.data.token }
                }
            );
    }

    async login(body: ILoginBody): Promise<ILoginResponse> {
        return await axios.post(this.baseUrl + "/auth/login", body).then(res => res.data);
    }

    logout() {
        localStorage.clear()
    }
}

const authService = new AuthService();

export default authService;