import { FC } from 'react';
import { IRealCourse } from '../../../interfaces/RealCourse';
import CustomModal from '../../../common/components/CustomModal/CustomModal';
import { IModalProps } from '../../../common/interfaces/CustomModal';
import { usePDF } from 'react-to-pdf';
import { format } from 'date-fns';
import { Card } from 'react-bootstrap';


interface IRealCoursePDFModalProps {
    realCourse: IRealCourse | undefined,
    content: JSX.Element | undefined,
    show: boolean,
    close: (action?: string) => void,
}

const RealCoursePDFModal: FC<IRealCoursePDFModalProps> = (props) => {
    const { realCourse, show, close } = props
    const { toPDF, targetRef } = usePDF({ filename: realCourse?.name + '-preview.pdf' });

    const getCourseMode = () => {
        switch (realCourse?.mode) {
            case 'C':
                return "In presenza";
            case 'M':
                return "Ibrido";
            case 'D':
                return "A distanza";
            default:
                return "-";
        }
    }

    const pdfContent = realCourse && <div className='border'>
        <div className="container-fluid px-4 py-3" ref={targetRef}>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="my-3">{realCourse.name}</h5>
            </div>
            <Card className="mb-3 shadow-sm">
                <Card.Header>
                    <span className="fw-600">Informazioni corso reale</span>
                </Card.Header>
                <Card.Body className="d-flex flex-wrap">
                    <Card.Text className="col-4">Data di inizio: {realCourse.start_date.date ? format(new Date(realCourse.start_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                    <Card.Text className="col-4">Data di fine: {realCourse.end_date.date ? format(new Date(realCourse.end_date.date), "dd/MM/yyyy") : '-'}</Card.Text>
                    <Card.Text className="col-4">Docente principale: {realCourse.teacher.last_name + ' ' + (realCourse.teacher.middle_name || '') + realCourse.teacher.first_name || '-'}</Card.Text>
                    <Card.Text className="col-4">Tipologia: {realCourse.type.name || "-"}</Card.Text>
                    <Card.Text className="col-4">Modalità: {getCourseMode()}</Card.Text>
                    <Card.Text className="col-4">Categoria: {realCourse.is_internal ? 'Corso interno' : 'Corso esterno'}</Card.Text>
                    <Card.Text className="col-4">Capienza massima studenti: {realCourse.max_students || "-"}</Card.Text>
                </Card.Body>
            </Card>
            {
                (realCourse.classrooms && realCourse.classrooms?.length > 0) && <Card className="mb-3 shadow-sm">
                    <Card.Header>
                        <span className="fw-600">Aule</span>
                    </Card.Header>
                    <Card.Body className="d-flex flex-wrap">
                        {
                            realCourse.classrooms.map(
                                classroom => <div className="col-12 d-flex" key={classroom.id}>
                                    <Card.Text className="col-4">{classroom.name || "-"}</Card.Text>
                                    <Card.Text className="col-4 pe-2">{classroom.note || "-"}</Card.Text>
                                </div>
                            )
                        }
                    </Card.Body>
                </Card>
            }
            <Card className="mb-3 shadow-sm">
                <Card.Header>
                    <span className="fw-600">Informazioni corso generico</span>
                </Card.Header>
                <Card.Body className="d-flex flex-wrap">
                    <Card.Text className="col-4">Nome: {realCourse.course.name || "-"}</Card.Text>
                    <Card.Text className="col-4">Durata: {realCourse.course.duration || "-"} ore</Card.Text>
                    <Card.Text className="col-8">Note: {realCourse.course.note || "-"}</Card.Text>
                </Card.Body>
            </Card>
            <Card className="mb-3 shadow-sm">
                <Card.Header>
                    <span className="fw-600">Organizzazione</span>
                </Card.Header>
                <Card.Body className="d-flex flex-wrap">
                    <Card.Text className="col-4">Fondo formativo: {realCourse.fund.name || "-"}</Card.Text>
                    <Card.Text className="col-4">Codice del fondo: {realCourse.fund.code || "-"}</Card.Text>
                    <Card.Text className="col-4">Piattaforma: {realCourse.platform.name || "-"}</Card.Text>
                    <Card.Text className="col-4">Codice della piattaforma: {realCourse.platform.code || "-"}</Card.Text>
                </Card.Body>
            </Card>
        </div>
    </div>

    const modalProps: IModalProps = {
        action: 'preview',
        close,
        show,
        confirm: () => { toPDF(); close() },
        label: 'Anteprima PDF',
        content: pdfContent
    }

    return <CustomModal modalProps={modalProps}></CustomModal>
}

export default RealCoursePDFModal;