import { Field, useFormikContext } from 'formik';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import { FC, useEffect, useState } from 'react';
import CustomFileView from '../CustomFileView/CustomFileView';
import fileService from '../../../services/FileService';
import { faCircleInfo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IField } from '../../interfaces/CustomForm';
import it from 'date-fns/locale/it';
registerLocale('it', it)

// custom text/number input
export const CustomTextNumberField: FC<{ field: IField }> = ({ field }) => {
    const { getFieldProps, getFieldMeta } = useFormikContext();
    const fieldProps = { ...getFieldProps(field), ...field }
    const meta = getFieldMeta(fieldProps.name)

    return (
        <>
            <Field className="form-control" {...fieldProps} />
            {
                meta.error ? (
                    <div className="text-danger">{meta.error}</div>
                ) : null
            }
        </>
    );
};

// custom textarea input
export const CustomTextareaField: FC<{ field: IField }> = ({ field }) => {
    const { getFieldProps, getFieldMeta } = useFormikContext();
    const fieldProps = { ...getFieldProps(field), ...field }
    const meta = getFieldMeta(fieldProps.name)

    return (
        <>
            <Field {...fieldProps} type="text" as="textarea" className="form-control mh-25" />
            {
                meta.error ? (
                    <div className="text-danger">{meta.error}</div>
                ) : null
            }
        </>
    );
};

// custom select menu
export const CustomSelectField: FC<{ field: IField }> = ({ field }) => {
    const { onChange } = field
    const { setFieldValue, getFieldProps, getFieldMeta } = useFormikContext();
    const fieldProps = { ...getFieldProps(field), ...field }
    const options = field.options ? field.options : []
    const meta = getFieldMeta(fieldProps.name)
    const infoLabel = options.find(option => option.value === fieldProps.value)?.infoLabel || null
    return (
        <>
            <Select
                placeholder={fieldProps.placeholder}
                name={fieldProps.name}
                value={options.find(option => option.value === fieldProps.value) || ''}
                onChange={option => { setFieldValue(fieldProps.name, (option as any).value); if (onChange) onChange(option) }}
                options={options}
            />
            {
                meta.error ? (
                    <div className="text-danger">{meta.error}</div>
                ) : null
            }
            {
                infoLabel && <p className='d-flex align-items-center p-2'>
                    <FontAwesomeIcon icon={faCircleInfo} className='me-2' />
                    {infoLabel}
                </p>
            }
        </>
    );
}

// custom multi-select menu
export const CustomMultiSelectField: FC<{ field: IField }> = ({ field }) => {
    const { setFieldValue, getFieldProps, getFieldMeta } = useFormikContext();
    const fieldProps = { ...getFieldProps(field), ...field }
    const options = field.options ? field.options : []
    const meta = getFieldMeta(fieldProps.name)
    return (
        <>
            <Select
                isMulti={true}
                placeholder={fieldProps.placeholder}
                name={fieldProps.name}
                value={options.filter((option) => fieldProps.value.indexOf(option.value) >= 0)}
                onChange={option => setFieldValue(fieldProps.name, option ? option.map((item) => item.value) : [])}
                options={options}
            />
            {
                meta.error ? (
                    <div className="text-danger">{meta.error}</div>
                ) : null
            }
        </>
    );
}

// custom datepicker input
export const CustomDatePickerField: FC<{ field: IField }> = ({ field }) => {
    const { setFieldValue, getFieldProps, getFieldMeta } = useFormikContext();
    const fieldProps = { ...getFieldProps(field), ...field }
    const meta = getFieldMeta(fieldProps.name)

    return (
        <>
            <DatePicker
                {...fieldProps}
                locale={it}
                className='form-control'
                dateFormat={field.showTimeSelect ? ["dd/MM/yyyy HH:mm", "dd-MM-yyyy HH:mm"] : ["dd/MM/yyyy", "dd-MM-yyyy"]}
                selected={(fieldProps.value && new Date(fieldProps.value)) || null}
                showTimeSelect={field.showTimeSelect}
                timeFormat="HH:mm"
                timeIntervals={15}
                onChange={val => setFieldValue(field.name, val)}
            />
            {
                meta.error ? (
                    <div className="text-danger">{meta.error}</div>
                ) : null
            }
        </>

    );
};

// custom time-picker input
export const CustomTimePickerField: FC<{ field: IField }> = ({ field }) => {
    const { getFieldProps, getFieldMeta } = useFormikContext();
    const fieldProps = { ...getFieldProps(field), ...field }
    const meta = getFieldMeta(fieldProps.name)

    return <div className='col-4'>
        <Field className="form-control" {...fieldProps} />
        {
            meta.error ? (
                <div className="text-danger">{meta.error}</div>
            ) : null
        }
    </div>
}

// custom file input
export const CustomFileField: FC<{ field: IField, setFile?: ((file: File | undefined, id: number | undefined) => void) }> = ({ field, setFile }) => {
    const [chosenFile, setChosenFile] = useState<File | undefined>(undefined)
    const [fileId, setFileId] = useState<number | undefined>(undefined)

    const { setFieldValue, getFieldProps, getFieldMeta } = useFormikContext();
    const fieldProps = { ...getFieldProps(field), ...field }
    const meta = getFieldMeta(fieldProps.name)

    useEffect(() => {
        if (fieldProps.value !== undefined && Number(fieldProps.value)) {
            setFileId(Number(fieldProps.value))
        }
    }, [fieldProps.value])

    useEffect(() => {
        if (fileId)
            fileService.get(fileId).then(
                res => {
                    setChosenFile(new File([""], res.client_name))
                }
            )
    }, [fileId])

    return (
        <>
            <Field className="form-control d-none" name={fieldProps.name} type="text" />
            <Field className="form-control" type="file" name="schedule-input" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (setFile && e.target.files) {
                    setFieldValue(field.name, e.target.files[0].name)
                    setFile(e.target.files[0], fileId)
                    setChosenFile(e.target.files[0])
                }
            }} />
            {
                meta.error ? (
                    <div className="text-danger">{meta.error}</div>
                ) : null
            }
            {
                chosenFile && <div className='mt-3 mx-3 d-flex'>
                    <CustomFileView file={chosenFile} />
                    {setFile && <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => {
                        setFile(undefined, undefined);
                        setChosenFile(undefined);
                        setFieldValue(field.name, undefined)
                    }}> <FontAwesomeIcon icon={faTrash} /></Button>}
                </div>
            }
        </>
    );
};