import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faDownload, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import CustomTable from '../../common/components/CustomTable/CustomTable';
import { IGenericCourse, ITableGenericCourse } from '../../interfaces/GenericCourse';
import { IFile } from '../../interfaces/File';
import CustomFileView from '../../common/components/CustomFileView/CustomFileView';

const GenericCoursesColumns = (props: {
  onEditClick: (tableMeta: MUIDataTableMeta<IGenericCourse>) => void,
  onDeleteClick: (tableMeta: MUIDataTableMeta<IGenericCourse>) => void,
  onPreviewFile: (file: IFile) => void,
  onDownloadFile: (file: IFile) => void,
  genericCourses: ITableGenericCourse[],
  searchParam?: string
}) => {

  const { genericCourses, searchParam, onEditClick, onDeleteClick, onPreviewFile, onDownloadFile } = props

  const genericCoursesColumns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false
      }
    },
    {
      name: "name",
      label: "Nome",
      options: {
        filterList: []
      }
    },
    {
      name: "duration",
      label: "Durata",
    },
    {
      name: "schedule",
      label: "Programma",
      options: {
        customBodyRender: (value: IFile, tableMeta: MUIDataTableMeta<IGenericCourse>, updateValue: any) => {
          return <Dropdown as={ButtonGroup} className="shadow-sm">
            <Button className='btn btn-light' onClick={() => onPreviewFile(value)}>
              <CustomFileView file={value} />
            </Button>
            <Dropdown.Toggle split id="dropdown-split-basic" className='btn btn-light' />
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onPreviewFile(value)}><FontAwesomeIcon icon={faArrowRight} className="me-1" />Anteprima</Dropdown.Item>
              <Dropdown.Item onClick={() => onDownloadFile(value)}><FontAwesomeIcon icon={faDownload} className="me-1" />Download</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      }
    },
    {
      name: "note",
      label: "Note"
    },
    {
      name: "azioni",
      label: "Azioni",
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IGenericCourse>, updateValue: any) => (
          <>
            <Button className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
            <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}> <FontAwesomeIcon icon={faTrash} /></Button>
          </>
        )
      }
    },
  ]

  const filteredCols = searchParam ? [...genericCoursesColumns] : [];

  if (searchParam) {
    let filterList: string[] = [];
    if (searchParam !== "All") {
      filterList = [searchParam];
    }
    // Target the column to filter on.
    let colWithFilter = filteredCols.find(col => col.options && col.options.filterList)
    if (colWithFilter && colWithFilter.options && colWithFilter.options.filterList)
      colWithFilter.options.filterList = filterList;
  }

  return (
    <CustomTable
      title={"Elenco di tutti i corsi generici disponibili"}
      data={genericCourses}
      columns={filteredCols.length !== 0 ? filteredCols : genericCoursesColumns}
    />
  )
}

export default GenericCoursesColumns;