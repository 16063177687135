import { MUIDataTableMeta } from 'mui-datatables';
import { Button } from 'react-bootstrap';
import CustomTable from '../../common/components/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IStudent } from '../../interfaces/Student';
import { TableCell, TableRow } from '@mui/material';
import DetailsStudent from '../../components/Students/DetailsStudent';

const StudentsColumns = (props: {
  onEditClick: (tableMeta: MUIDataTableMeta<IStudent>) => void,
  onDeleteClick: (tableMeta: MUIDataTableMeta<IStudent>) => void,
  students: IStudent[],
  reload: (refresh: boolean) => void
}) => {
  const { students, onEditClick, onDeleteClick, reload } = props

  const studentsColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false
      }
    },
    {
      name: "first_name",
      label: "Nome"
    },
    {
      name: "status",
      label: "Stato"
    },
    {
      name: "email",
      label: "Email"
    },
    {
      name: "phone_cell",
      label: "N° di cellulare"
    },
    {
      name: "qualification",
      label: "Qualifica"
    },
    {
      name: "contract_type",
      label: "Tipologia contratto"
    },
    {
      name: "source",
      label: "Sorgente"
    },
    {
      name: "actions",
      label: "Azioni",
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta<IStudent>, updateValue: any) => (
          <div className='d-flex'>
            <Button id='btn-edit' className='btn btn-primary rounded-circle me-1 shadow-sm' onClick={() => onEditClick(tableMeta)}><FontAwesomeIcon icon={faPen} /></Button>
            <Button className='btn btn-danger rounded-circle shadow-sm' onClick={() => onDeleteClick(tableMeta)}><FontAwesomeIcon icon={faTrash} /></Button>
          </div>
        )
      }
    }
  ]

  const renderStudentDetails = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
    const colSpan = rowData.length + 1;

    return <TableRow>
      <TableCell colSpan={colSpan}>
        <DetailsStudent id={Number(rowData[0])} reload={reload} />
      </TableCell>
    </TableRow>
  }


  return (
    <CustomTable
      title={"Elenco di tutti i studenti disponibili"}
      data={students}
      columns={studentsColumns}
      options={{
        sortOrder: {
          name: "first_name",
          direction: "asc"
        },
        expandableRows: true,
        renderExpandableRow: renderStudentDetails
      }}
    />
  )
}

export default StudentsColumns;