export const classroomTeachersFields = [
    {
        name: 'note',
        type: 'textarea',
        placeholder: 'Inserisci eventuali note',
        label: 'Note',
    },
    {
        name: 'payment_times',
        type: 'text',
        placeholder: 'Inserisci i tempi di pagamento',
        label: 'Tempi di pagamento',
    },
    {
        name: 'withholding_tax',
        type: 'select',
        options: [
            { value: 0, label: "No" },
            { value: 1, label: "Sì" },
        ],
        placeholder: "Inserisci la ritenuta d'acconto",
        label: "Ritenuta d'acconto",
    },
    {
        name: 'fee',
        type: 'text',
        placeholder: 'Inserisci la tassa',
        label: 'Tassa',
    },
]