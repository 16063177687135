import { faClock, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Task } from "gantt-task-react";
import { FC } from "react";
import { Card } from "react-bootstrap";
import { ICalendarEvent } from "../../../interfaces/Calendar";
import { IRealCourse } from "../../../interfaces/RealCourse";
import { ITeacherMenu } from "../../../interfaces/Teacher";
import { checkIfAfternoon, checkIfMorning } from "../../config/configEvents";

interface ICustomTooltipProps {
    task: Task;
    fontSize: string;
    fontFamily: string;
    events: ICalendarEvent[],
    realCourses?: IRealCourse[],
    teachers?: ITeacherMenu[]
}

// rendering the event's data in a tooltip (customized)
const CustomTooltip: FC<ICustomTooltipProps> = (props) => {
    const { task, events, realCourses, teachers } = props

    // splitting ids to find morning and afternoon ids separately
    const splittedId = task.id.split('-')
    const eventIds = splittedId[splittedId.length - 1].split("+")
    // figuring out if the project is a teacher or a real course
    const currentTeacher = teachers?.find(teacher => task.type === 'project' && teacher.id.toString() === splittedId[splittedId.length - 1])
    const currentRealCourse = realCourses?.find(course => task.type === 'project' && course.id.toString() === splittedId[splittedId.length - 1])

    const currentEvents: ICalendarEvent[] = []
    let morning: ICalendarEvent | undefined = undefined
    let afternoon: ICalendarEvent | undefined = undefined
    // saving all the passed events...
    eventIds.forEach(id => {
        let findEvent = events.find(event => event.id.toString() === id)
        if (findEvent) {
            currentEvents.push(findEvent)
        }
    })

    // ...and trying to find out if morning or afternoon or both of them exists/not exists
    morning = checkIfMorning(currentEvents)
    afternoon = checkIfAfternoon(currentEvents)

    return <Card className="shadow-sm" style={{ zIndex: 2, maxWidth: '700px' }}>
        <Card.Header>
            <span style={{ whiteSpace: 'pre-wrap' }} className="fw-600">{task.name || '-'}</span>
        </Card.Header>
        {
            task.type === 'project' && currentRealCourse && <Card.Body className="d-flex flex-wrap">
                <Card.Text className="col-12">
                    Docente: <span className="fw-600">{currentRealCourse.teacher.last_name + ' ' + (currentRealCourse.teacher.middle_name || '') + currentRealCourse.teacher.first_name || '-'}</span>
                </Card.Text>
                <Card.Text className="col-12">
                    Lezioni da <span className="fw-600">{format(new Date(currentRealCourse.start_date.date), "EEEE dd/MM/yyyy", { locale: it }) || '-'} a <span className="fw-600">{format(new Date(currentRealCourse.end_date.date), "EEEE dd/MM/yyyy", { locale: it }) || '-'}</span></span>
                </Card.Text>
            </Card.Body>
        }
        {
            task.type === 'project' && currentTeacher && <Card.Body className="d-flex flex-wrap">
                <Card.Text className="col-12">
                    Lezioni da <span className="fw-600">{format(task.start, "EEEE dd/MM/yyyy", { locale: it }) || '-'}</span> al <span className="fw-600">{format(task.end, "EEEE dd/MM/yyyy", { locale: it }) || '-'}</span>
                </Card.Text>
            </Card.Body>
        }
        {
            task.type === 'task' && <Card.Body className="d-flex flex-wrap">
                {
                    morning && <>
                        <Card.Text className="col-12">
                            La lezione si svolgerà <span className="fw-600">{format(new Date(morning.start_date.date), "EEEE dd/MM/yyyy", { locale: it }) || '-'}</span>
                        </Card.Text>
                        <Card.Text className="col-12">
                            <span className="fw-600">Mattino</span>
                        </Card.Text>
                        <Card.Text className="col-6">
                            <FontAwesomeIcon icon={faClock} width="32px" />Dalle <span className="fw-600">{format(new Date(morning.start_date.date), "HH:mm") || '-'}</span> alle <span className="fw-600">{format(new Date(morning.end_date.date), "HH:mm") || '-'}</span>
                        </Card.Text>
                        <Card.Text className="col-6">
                            <FontAwesomeIcon icon={faUserTie} width="32px" color="primary" /><span className="fw-600">{morning.teacher?.last_name + ' ' + (morning.teacher?.middle_name || '') + morning.teacher?.first_name || '-'}</span>
                        </Card.Text>
                        {morning.description && <Card.Text className="col-12" style={{ marginLeft: "32px" }}>Descrizione: {morning.description}</Card.Text>}
                        {morning.note && <Card.Text className="col-12" style={{ marginLeft: "32px" }}>Note: {morning.note}</Card.Text>}
                    </>
                }
                {
                    afternoon && <>
                        <Card.Text className="col-12">
                            <span className="fw-600">Pomeriggio</span>
                        </Card.Text>
                        <Card.Text className="col-6">
                            <FontAwesomeIcon icon={faClock} width="32px" />Dalle <span className="fw-600">{format(new Date(afternoon.start_date.date), "HH:mm") || '-'}</span> alle <span className="fw-600">{format(new Date(afternoon.end_date.date), "HH:mm") || '-'}</span>
                        </Card.Text>
                        <Card.Text className="col-6">
                            <FontAwesomeIcon icon={faUserTie} width="32px" /><span className="fw-600">{afternoon.teacher?.last_name + ' ' + (afternoon.teacher?.middle_name || '') + afternoon.teacher?.first_name || '-'}</span>
                        </Card.Text>
                        {afternoon.description && <Card.Text className="col-12" style={{ marginLeft: "32px" }}>Descrizione: {afternoon.description}</Card.Text>}
                        {afternoon.note && <Card.Text className="col-12" style={{ marginLeft: "32px" }}>Note: {afternoon.note}</Card.Text>}
                    </>
                }
            </Card.Body>
        }
    </Card>
}

export default CustomTooltip;
