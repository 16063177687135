import { FC, useEffect, useState } from "react";
import { ClassroomTeacherColumns } from "../../../../config/tableColumns/ClassroomDetailsColumns";
import { IClassroomTeacher } from "../../../../interfaces/ClassroomTeacher";
import { ITeacher } from "../../../../interfaces/Teacher";
import classroomTeachersService from "../../../../services/ClassroomTeachersService";
import teachersService from "../../../../services/TeachersService";
import ClassroomDetailsModalTeacher from "./ClassroomDetailsModalTeacher";

interface IClassroomTeacherProps {
    classroomId: string | undefined
}

const ClassroomTeachers: FC<IClassroomTeacherProps> = (props) => {
    const { classroomId } = props

    const [showTeacherModal, setShowTeacherModal] = useState(false);
    const [actionTeacher, setTeacherAction] = useState('');
    const [currentTeacher, setCurrentTeacher] = useState<IClassroomTeacher | undefined>(undefined);
    const [currentTeacherId, setCurrentTeacherId] = useState<number | undefined>(undefined);
    const [internalTeachers, setInternalTeachers] = useState<IClassroomTeacher[]>([]);
    const [tableInternalTeachers, setTableInternalTeachers] = useState<ITeacher[]>([]);
    const [tableExternalTeachers, setTableExternalTeachers] = useState<ITeacher[]>([]);
    const [loadTeachers, setLoadTeachers] = useState<boolean>(false);
    const [addTeacher, setAddTeacher] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (classroomId)
            classroomTeachersService.getAll(classroomId).then(
                response => {
                    setInternalTeachers(response)
                    const intTeachers = teachersService.mapDataForTable(response.map(t => { return { ...t.teacher, id: t.id } }) || [])
                    setTableInternalTeachers(intTeachers)
                }
            )
    }, [classroomId])

    useEffect(() => {
        if (loadTeachers)
            teachersService.getAll().then(
                res => {
                    const intTeachers = internalTeachers.map(t => t.teacher)
                    const extTeachers = res.filter(teacher => !intTeachers.find(ext => (ext.id === teacher.id)))
                    setTableExternalTeachers(teachersService.mapDataForTable(extTeachers))
                    setLoadTeachers(false)
                },
                err => setLoadTeachers(false)
            )
    }, [loadTeachers, internalTeachers])

    useEffect(() => {
        if (refresh && classroomId) {
            classroomTeachersService.getAll(classroomId).then(
                res => {
                    setInternalTeachers(res)
                    setAddTeacher(false)
                    const intTeachers = teachersService.mapDataForTable(res.map(t => { return { ...t.teacher, id: t.id } }) || [])
                    setTableInternalTeachers(intTeachers)
                    setTableExternalTeachers([])
                    setRefresh(false)
                },
                err => setRefresh(false)
            )
        }
    }, [refresh, classroomId]);

    const onAddTeacherClick = (teacherId: number) => {
        teachersService.get(teacherId).then(
            res => {
                setCurrentTeacher({
                    id: res.id,
                    note: '',
                    teacher: res,
                    payment_times: '',
                    withholding_tax: false,
                    fee: '',
                    calendar: []
                })
                setCurrentTeacherId(res.id)
                setTeacherAction('add');
                setShowTeacherModal(true);
            }
        )
    }

    const onEditTeacherClick = (teacherId: number) => {
        classroomTeachersService.get(Number(classroomId), teacherId).then(
            res => {
                setCurrentTeacher(res)
                setCurrentTeacherId(res.id)
                setTeacherAction('edit');
                setShowTeacherModal(true);
            }
        )
    }

    const onDeleteTeacherClick = (teacherId: number) => {
        if (teacherId) {
            setTeacherAction('delete')
            setCurrentTeacherId(teacherId)
            setShowTeacherModal(true);
        }
    }


    return <>
        <ClassroomDetailsModalTeacher
            currentTeacher={currentTeacher}
            classroomId={Number(classroomId)}
            id={currentTeacherId}
            show={showTeacherModal}
            close={(action?: string) => {
                setShowTeacherModal(false);
                setTeacherAction('')
                setCurrentTeacher(undefined);
                setCurrentTeacherId(undefined);
                if (typeof action === 'string')
                    setRefresh(true);
            }}
            action={actionTeacher}
        />
        <div className="d-flex justify-content-end">
            <button className="btn btn-link" onClick={() => { setAddTeacher(!addTeacher); if (addTeacher === false) setLoadTeachers(true) }}>{addTeacher ? "Nascondi i docenti esterni" : "Aggiungi docente all'aula"}</button>
        </div>
        <ClassroomTeacherColumns classroomId={classroomId} title="Docenti di questa aula" data={internalTeachers} tableData={tableInternalTeachers} onEditClick={onEditTeacherClick} onDeleteClick={onDeleteTeacherClick} />
        {
            addTeacher && <>
                <hr />
                <ClassroomTeacherColumns classroomId={classroomId} title="Aggiungi docenti a questa aula" tableData={tableExternalTeachers} onAddClick={onAddTeacherClick} />
            </>
        }
    </>
}

export default ClassroomTeachers;